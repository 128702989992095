.wide_section {
  background-color: $grey_back;
  margin: 0 0 30px 0;
  padding: 0 calc((100vw - 100%) / 2);
  .container {
    width: 1240px;
    margin: 0 auto;
    padding: 35px 0;
    .social_reviews {
      display: grid;
      align-content: start;
      .header {
        display: flex;
        justify-content: space-between;
        h2 {
          font-family: $c45;
          font-size: 20px;
          font-weight: 400;
          color: #52575b;
          text-transform: uppercase;
          letter-spacing: 0;
          margin: 0;

          &:after {
            content: '';
            display: block;
            width: 30px;
            height: 3px;
            background: #f07b21;
            margin: 8px 0 25px;
          }
        }
        a {
          font-family: $c35;
          font-size: 14px;
          color: #1161b7;
          text-decoration: underline;
          white-space: nowrap;
          margin: 5px 0 0 0;
        }
      }
      .content_block {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-gap: 20px;
        .photo_cell {
          position: relative;
          &:hover .photo_overlay {
            display: flex;
          }
          .photo_img {
            display: block;
            width: 100%;
          }
          .photo_overlay {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            background: rgba(255, 255, 255, 0.7);
            font-family: $c35;
            font-size: 13px;
            color: $grey_font;
          }
        }
      }
    }
  }
}
@include small_desktop {
  .wide_section {
    .container {
      width: 1000px;
      padding: 25px 0;
      .social_reviews {
        .content_block {
          grid-template-columns: repeat(6, 1fr);
          .photo_cell:nth-child(n+7) {
            display: none;
          }
        }
      }
    }
  }
}
@include tablet {
  .wide_section {
    padding: 0;
    width: 100%;
    .container {
      width: 100%;
      padding: 25px 10px;
    }
  }
}
@include big_phone {
  .wide_section {
    .container {
      grid-template-columns: 1fr;
      .social_reviews {
        .content_block {
          grid-template-columns: repeat(4, 1fr);
          .photo_cell:nth-child(n+5) {
            display: none;
          }
        }
      }
    }
  }
}
@include phone {
  .wide_section {
    .container {
      .social_reviews {
        .header {
          h2 {
            margin: 0 5px 0 0;
            text-align: left;
          }
        }
        .content_block {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}