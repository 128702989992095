@mixin coupon_sizes($scale) {
  width: $scale * 295px;
  height: $scale * 160px;
  .number {
    padding: $scale * 3px $scale * 18px;
    font-size: $scale * 15px;
    margin-bottom: $scale * 15px;
  }
  .timer {
    padding: $scale * 5px $scale * 14px;
    font-size: $scale * 14px;
    margin-top: $scale * 80px;
    &:before {
      margin-right: $scale * 5px;
    }
  }
  .date {
    font-size: $scale * 15px;
    padding: $scale * 4px $scale * 12px;
    margin-top: $scale * 80px;
  }
}

.pages_profile_coupons {
  .description {
    font-family: $c35;
    font-size: 15px;
    color: $grey_font;
    margin-bottom: 30px;
  }
  .coupons {
    padding-bottom: 30px;
    border-bottom: 1px solid $grey_font_light;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @include big_phone() {
      grid-template-columns: 1fr 1fr;
    }
    @include phone() {
      grid-template-columns: 1fr;
    }
    .coupon {
      position: relative;
      margin-bottom: 10px;
      overflow: hidden;
      justify-self: center;
      img {
        width: 100%;
      }
      .properties {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 1;
        transition: opacity 0.2s;
        .number {
          color: white;
          background: $green;
          border-radius: 0 0 1px 1px;
          white-space: nowrap;
        }
        .date {
          font-family: $c35;
          background: white;
          color: $grey_font;
        }
        .timer {
          display: flex;
          align-items: center;
          background: $red;
          border-radius: 1px;
          font-family: $c45;
          color: white;
          &:before {
            content: '';
            display: inline-block;
            @extend .sprite-sprite_images-timer;
            margin-right: 5px;
          }
        }
      }
      .overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.4);
        opacity: 0;
        transition: opacity 0.2s;
      }
      form {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        input {
          border: none;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
      &:hover {
        .properties {
          opacity: 0;
        }
        .overlay {
          opacity: 1;
        }
      }
      @include coupon_sizes(1);
      @include small_desktop() {
        @include coupon_sizes(0.8);
      }
      @include tablet() {
        @include coupon_sizes(1);
        @media all and (max-width: 927px) {
          @include coupon_sizes(0.9);
        }
        @media all and (max-width: 841px) {
          @include coupon_sizes(0.8);
        }
      }
      @include big_phone() {
        @include coupon_sizes(1);
        @media all and (max-width: 631px) {
          @include coupon_sizes(0.8);
        }
        @media all and (max-width: 510px) {
          @include coupon_sizes(0.7);
        }
      }
      @include phone() {
        @include coupon_sizes(1);
      }
    }
  }
}

