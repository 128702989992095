.page_cart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 0 20px;
  @mixin input() {
    font-family: $c35;
    font-size: 14px;
    border: 1px solid #d5dbde;
    height: 35px;
    padding: 0 12px;
    margin: 0 8px 0 0;
    min-width: 168px;
    border-radius: 2px;
    &::placeholder {
      font-family: $c35;
      font-size: 14px;
      color: #a8b2ba;
    }
  }
  @mixin button_wrapper() {
    position: relative;
    width: calc(100% / 3);
    &:first-of-type {
      button {
        border-left: 3px solid #f0f4f6;
      }
    }
    &:last-of-type {
      button {
        border-right: 3px solid #f0f4f6;
      }
    }
    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;

      &:checked + button {
        border: 3px solid $blue;
        background: $blue;

        span {
          color: white;
        }
      }
    }
    button {
      border-top: 3px solid #f0f4f6;
      border-bottom: 3px solid #f0f4f6;
      border-left: 1.5px solid #f0f4f6;
      border-right: 1.5px solid #f0f4f6;
      background: none;
      outline: none;
      display: grid;
      padding: 8px;
      width: 100%;
      height: 100%;

      span {
        font-family: $c45;
        font-size: 15px;

        &:first-of-type {
          margin: 0 0 4px 0;
        }
      }
    }
  }
  h1 {
    width: auto;
    margin: 33px 0 34px 0;
    font-family: $c45;
    font-size: 16px;
    text-transform: uppercase;

    &:before {
      content: '';
      display: inline-block;
      margin: 0 8px -2px 0;
      opacity: 0.6;
      @extend .sprite-sprite_images-checkout_box;
    }
    &:after {
      all: unset;
    }
  }
  .papara_fast_notice {
    color: #f07b21;
    display: block;
  }
  #pjax_container {
    width: 100%;
    max-width: 1080px;
    .product_list {
      width: 100%;
      .product {
        width: 100%;
        display: grid;
        grid-template-columns: 100px 1fr 140px 140px 90px 30px;
        justify-items: center;
        align-items: center;
        margin: 15px 0;
        position: relative;
        &:after {
          content: '';
          display: block;
          grid-column: 2 / 7;
          width: calc(100% - 54px);
          height: 1px;
          background: lightgrey;
          margin: 9px 0 0 54px;
        }
        &:last-child:after {
          background: none;
        }
        .image {
          border: 1px solid lightgrey;
          img {
            width: 100%;
          }
        }
        .details {
          margin: 3px 0 0 54px;
          justify-self: left;
          align-self: start;
          .title {
            display: block;
            font-family: $c45;
            font-size: 21px;
            transition: color 0.1s linear;
            margin-bottom: 9px;

            &:hover {
              color: $blue;
            }
          }
          .info {
            font-family: $c35;
            font-size: 15px;
            color: $grey_font_light;

            .detail, .separator {
              font-family: inherit;
              font-size: inherit;
              color: inherit;
            }
          }
        }
        .price {
          text-align: center;
          display: grid;
          .old_price {
            text-decoration: line-through;
            color: $grey_font_light;
            white-space: nowrap;

            .icon-coupon {
              @extend .sprite-sprite_images-coupon_mini;
              display: inline-block;
              margin-right: 5px;
            }
          }
          .current_price {
            font-size: 18px;
            white-space: nowrap;
          }
          .discount {
            white-space: nowrap;
            color: $green;
          }
        }
        .quantity {
          form {
            display: flex;
            .quantity_change {
              position: relative;
              width: 20px;
              height: 20px;
              border: 1px solid $orange;
              border-radius: 50%;
              background: #fff;
              cursor: pointer;
              transition: all 0.2s linear;
              margin: 3px 0 0 0;
              &:hover {
                background: $orange;

                &::before,
                &::after {
                  background: #fff;
                }
              }
              &.plus {
                &::after {
                  transform: translate(-50%, -50%) rotate(90deg);
                }
              }
              &::before,
              &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 10px;
                height: 1px;
                background: $orange;
              }
            }
            .count_field {
              width: 76px;
              border: none;
              border-bottom: 1px solid $grey_back;
              background: #fff;
              background-blend-mode: color;
              font-size: 22px;
              text-align: center;
              -moz-appearance: textfield;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
              }
            }
          }
        }
        .amount {
          font-size: 18px;
          white-space: nowrap;
        }
        .delete {
          @include close(0, 0, $grey_font_light);
          position: relative;
          background: none;
          border: none;
          outline: none;
        }
        .delete_confirm {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: calc(100% - 1px);
          background: white;
          display: flex;
          flex-direction: column;
          .question {
            text-align: center;
            font-family: $c45;
            font-size: 14px;
            text-transform: uppercase;
            margin: 0 0 10px 0;
          }
          .answers {
            text-align: center;
          }
          .yes, .no {
            font-family: $c55;
            font-size: 14px;
            text-transform: uppercase;
            border: none;
            outline: none;
            border-radius: 2px;
            padding: 10px 25px;
            cursor: pointer;
            background: #e9e9e9;

            &:hover {
              background: $blue;
              color: white;
            }
          }
        }
      }
    }
    .product_final {
      padding: 31px 20px;
      background: #f0f4f6;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      align-items: center;
      margin: 0 0 53px 0;
      .promo_code {
        &.shown {
          .show {
            display: none;
          }
          form {
            display: block;
          }
        }
        &.activated {
          .message {
            display: block;
          }
        }
        .show {
          background: none;
          border: none;
          outline: none;
          border-bottom: 1px dashed #9fa7b0;
          font-family: $c35;
          font-size: 15.25px;
          color: #9fa7b0;
          cursor: pointer;
          white-space: nowrap;
        }
        form {
          display: none;
          position: relative;
          .text {
            @include input;
            font-family: $c45;
            font-size: 14.34px;
            width: 175px;
          }
          .submit {
            -webkit-appearance: none;
            border: none;
            height: 35px;
            background: #0f55a0;
            outline: none;
            padding: 0 20px;
            font-family: $c45;
            font-size: 14px;
            text-transform: uppercase;
            color: white;
            border-radius: 2px;
            cursor: pointer;
          }
          .cancel {
            position: absolute;
            width: 23px;
            height: 23px;
            left: 148px;
            top: 6px;
            border: none;
            background: none;
            outline: none;
            cursor: pointer;
            &::before, &::after {
              content: '';
              position: absolute;
              display: block;
              width: 10px;
              height: 2px;
              top: 11px;
              left: 6px;
              background: $grey_font_light;
            }
            &::before { transform: rotate(45deg); }
            &::after { transform: rotate(-45deg); }
          }
        }
        .message {
          display: none;
          font-family: $c45;
          font-size: 15.75px;
          border: none;
          margin: 5px 0 0 0;
          color: $green;
          p {
            font-family: $c45;
            font-size: 15.75px;
            color: $green;
          }
          &.red {
            display: block;
            color: red;
          }
        }
      }
      .amount {
        display: grid;
        .total {
          font-family: $c35;
          font-size: 21px;
          white-space: nowrap;
        }
        .saving {
          font-family: $c35;
          font-size: 15px;
          margin: 0 0 0 37px;
        }
      }
      .delivery_message {
        grid-column: 1 / 3;
        font-family: $c45;
        font-size: 15.75px;
        color: $green;
        margin: 22px 0 0 0;
        text-align: center;
        &:before {
          content: '';
          display: inline-block;
          margin: 0 10px -2px 0;
          @extend .sprite-sprite_images-free_delivery;
        }
      }
    }
  }
  .delivery_city {
    margin: 0 0 39px 0;
    .icon {
      @extend .sprite-sprite_images-header_city;
      display: inline-block;
      margin: 0 6px -1px 0;
    }
    .name {
      text-transform: uppercase;
      font-family: $c45;
      font-size: 15.75px;
      letter-spacing: 0.2px;
      color: $blue;
      border: none;
      border-bottom: 1px dotted $blue;
      background: none;
      outline: none;
      cursor: pointer;
    }
  }
  .buy {
    width: 100%;
    display: grid;
    justify-items: center;
    .delivery_method {
      display: flex;
      justify-content: center;
      margin: 0 0 26px 0;
      width: 100%;
      max-width: 800px;
      .button_wrapper {
        @include button_wrapper;
      }
    }
    .delivery_date {
      margin: 0 0 17px 0;
    }
    .user_info {
      margin: 0 auto 52px;
      display: grid;
      grid-gap: 15px;
      width: 100%;
      max-width: 500px;
      label {
        div {
          position: relative;
          width: 0;
          span {
            position: absolute;
            right: 10px;
            top: 6px;
            white-space: nowrap;
          }
        }
        input {
          @include input;
          height: 38px;
          width: 100%;
        }
      }
    }
    @include point_list();
    .point_list {
      width: 100%;
      max-width: 800px;
      margin: 0 0 30px 0;
      display: none;
      &.showed {
        display: block;
      }
    }
    .payment_method {
      display: flex;
      justify-content: center;
      margin: 0 0 43px 0;
      width: 100%;
      max-width: 800px;
      .button_wrapper {
        @include button_wrapper;
      }
    }
    .payment_message {
      width: 100%;
      max-width: 800px;
      font-family: $c35;
      text-align: justify;
      margin: -10px 0 20px;
      color: $grey_font_light;
    }
    .final {
      width: 100%;
      background: #f0f4f6;
      padding: 28px 15px 32px;
      display: grid;
      justify-items: center;
      margin: 0 0 20px 0;
      .payment {
        margin: 6px 0 25px 0;
        .text {
          font-family: $c45;
          font-size: 16px;
          text-transform: uppercase;
          margin: 0 5px 0 0;
        }
        .amount {
          font-family: $c45;
          font-size: 25px;
          color: $green;
        }
      }
      input {
        @include button($orange, $orange_hover);
      }
    }
  }
  .agreement {
    margin: 0 0 40px 0;
    font-family: $c35;
    font-size: 14.96px;
    color: #a8b2ba;
    a {
      color: #a8b2ba;
      text-decoration: underline;
    }
  }
}
.page_empty_cart {
  h1 { margin: 33px auto 34px; }
  @media all and (max-width: 1023px) {
    h1::after { margin: 10px 0 30px !important; }
  }
  .page_description {
    width: 100%;
    font-size: 20px;
    margin-bottom: 35px;
    text-align: center;
    &:first-of-type { text-transform: uppercase; }
    &.small {
      font-family: $c35;
      font-size: 15px;
      text-transform: initial;
    }
  }
  .page_empty_cart_menu {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto 50px;
    display: flex;
    flex-wrap: wrap;
    > a {
      display: block;
      width: 18%;
      margin: 0 1% 20px;
      img {
        width: 100%;
        border: 1px solid lightgrey;
      }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      a { width: 23%; }
    }
    @media (min-width: 600px) and (max-width: 767px) {
      a { width: 31%; }
    }
    @media (min-width: 320px) and (max-width: 599px) {
      a { width: 48%; }
    }
  }
}

@include tablet() {
  .page_cart {
    padding: 20px 20px 30px;
    #pjax_container {
      .product_final {
        .promo_code {
          .message {
            text-align: center;
          }
        }
      }
    }
    .buy {
      .user_info {
        max-width: 400px;
      }
    }
  }
}
@include big_phone() {
  .page_cart {
    #pjax_container {
      .product_list {
        .product {
          grid-template-columns: min-content 1fr 1fr 1fr min-content;
          grid-template-areas: 'b c c c g' 'r d e f g' 'a a a a a';
          grid-gap: 15px;
          margin: 0 0 20px 0;
          &:after {
            width: 100%;
            grid-area: a;
            margin: 0;
          }
          .image {
            width: 80px;
            grid-area: b;
          }
          .details {
            margin: 0;
            grid-area: c;
            .title {
              font-size: 18px;
            }
          }
          .price {
            grid-area: d;
          }
          .quantity {
            grid-area: e;
          }
          .amount {
            grid-area: f;
          }
          .delete {
            grid-area: g;
          }
        }
      }
      .product_final {
        grid-template-columns: 1fr;
        .promo_code {
          margin: 0 0 10px 0;
        }
        .amount {
          .saving {
            margin: 5px 0 0 0;
          }
        }
        .delivery_message {
          grid-column: unset;
        }
      }
    }
    .buy {
      .user_info {
        label {
          div {
            width: auto;
            span {
              position: static;
            }
          }
        }
      }
    }
  }
}
@include phone() {
  .page_cart {
    #pjax_container {
      .product_list {
        .product {
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-areas: 'b b b' 'c c c' 'd e f' 'a a a';
          .image {
            width: 100px;
          }
          .price {
            .old_price {
              font-size: 15px;
            }
            .current_price {
              font-size: 15px;
            }
            .discount {
              font-size: 12px;
            }
          }
          .quantity {
            form {
              .count_field {
                font-size: 15px;
                width: 52px;
              }
            }
          }
          .amount {
            font-size: 15px;
          }
          .delete {
            position: absolute;
          }
        }
      }
      .product_final {
        .promo_code.shown {
          form {
            .text {
              width: 130px;
              min-width: auto;
            }
            .submit {
              padding: 0 10px;
            }
            .cancel {
              left: 104px;
            }
          }
        }
      }
    }
    .buy {
      .delivery_method {
        display: grid;
        justify-content: unset;
        .button_wrapper {
          width: 100%;
        }
      }
      .payment_method {
        display: grid;
        justify-content: unset;
        .button_wrapper {
          width: 100%;
        }
      }
    }
  }
}
