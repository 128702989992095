#slide_filtr_button, .show_gift_filters {
  @include button($blue,$blue_hover);
  display: none;
  position: relative;
  margin-bottom: 20px;
  padding: 15px 0;
  &.in_profile::after {
    content: '';
    position: absolute;
    top: calc(50% - 6px);
    left: 10%;
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: rotate(-45deg);
  }
}
.catalog_page_container {
  justify-content: space-between;
  .filtr_container {
    .close {
      @include close(10px,10px,$grey_font);
      display: none;
    }
    @media all and (max-width: 1023px) {
      .close { display: block; }
    }
    overflow-y: visible;
    width: 295px;
    z-index: 98;
    position: absolute;
    display: flex;
    flex-direction: column;
    background: white;
    &::-webkit-scrollbar { display: none; }
    @include choice_form();
    .choice_form {
      width: 230px;
      margin: 0 auto 30px;
      transform: translateY(-18px);
      display: flex;
      flex-wrap: wrap;
      .sub_menu_cell {
        width: 100%;
        &.own_page_cell {
          &::before { display: none; }
          &:nth-of-type(2) { padding-left: 20px; }
          &:nth-of-type(3) { padding-left: 40px; }
          &:nth-of-type(4) { padding-left: 60px; }
        }
        h3 { padding: 20px 0; }
        .lbl {
          .pjax_update {
            font-family: $c35;
            font-size: 15px;
          }

          a {
            font-family: $c35;
            font-size: 15px;
          }
          font-size: 15px;
          padding-left: 25px;
          &::before { left: 0; }
        }
      }
      input[type="submit"] { display: none; }
      .find_and_close { display: none; }
    }
    .banner {
      position: relative;
      margin: auto;
      max-width: 100%;
      transition: opacity 0.2s;
      img {
        max-width: 100%;
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
  .catalog_container {
    padding-left: 310px;
    .page_description {
      font-family: $c35;
      font-size: 15px;
      line-height: 1.4;
      margin-bottom: 60px;
    }
    .catalog_text_wrapper {
      margin-bottom: 25px;
      span {
        display: block;
        font-family: $c35;
        font-size: 18px;
        color: $grey_font_light;
        margin-bottom: 20px;
      }
      h1 {
        margin-bottom: 20px;
        &:after {
          display: none;
        }
      }
      p {
        font-family: $c35;
        font-size: 15px;
        line-height: 1.5;
      }
    }
    .price {
      margin-top: -5px;
      margin-bottom: 25px;
      .piece {
        font-family: $c45;
        font-size: 21px;
      }
      .wholesale {
        font-family: $c35;
        font-size: 16px;
      }
      .old {
        color: $grey_font_light;
        text-decoration: line-through;
      }
      .current {
        color: $green;
      }
    }
    .catalog_block {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      .catalog_cell {
        border: 1px solid transparent;
        position: relative;
        .gallery_wrap {
          overflow: hidden;
          border-bottom: none;
          display: block;
          position: relative;
          .gallery_button {
            position: absolute;
            background: transparent;
            cursor: pointer;
            border: none;
            width: 40px;
            height: 100%;
            z-index: 100;
            display: flex;
            justify-content: center;
            &.prev {
              left: 0;
              top: 0;
              .icon {
                transform: rotate(180deg);
              }
            }
            &.next {
              right: 0;
              top: 0;
            }
            .icon {
              background: white;
              width: 21px;
              height: 21px;
              border-radius: 50%;
              box-shadow: 0 0 6px 2px rgba(100, 100, 100, 0.2);
              position: relative;
              display: block;
              &:before, &:after {
                content: '';
                display: block;
                width: 9px;
                height: 1px;
                background: $grey_font;
                position: absolute;
                transform-origin: right center;
                top: 9.5px;
                left: 5.5px;
              }
              &:before {
                transform: rotate(45deg);
              }
              &:after {
                transform: rotate(-45deg);
              }
            }
          }
          .gallery_container {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            overflow: hidden;
            .catalog_cell_img {
              min-width: 100%;
              width: 100%;
              height: 100%;
              background: $grey_back;
              border: none;
              &:nth-of-type(2) {
                position: absolute;
                opacity: 0;
              }
              &:nth-of-type(3) {
                position: absolute;
                opacity: 0;
              }
              &.active {
                opacity: 1;
              }
            }
          }
        }
        .product_colors {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          margin-bottom: 6px;
          .color {
            background: transparent;
            border: none;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .icon {
              display: block;
              width: 15px;
              height: 15px;
              border: 1px solid #ccc;
              border-radius: 50%;
              box-sizing: content-box;
            }
            &.active {
              .icon {
                border: 4px solid #bfbfbf;
              }
            }
          }
        }
        .catalog_cell_title {
          font-family: $c35;
          font-size: 16px;
          min-height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin-bottom: 26px;
        }
        .catalog_cell_price {
          display: block;
          font-size: 22px;
          margin-top: -20px;
          margin-bottom: 20px;
          text-align: center;
          .old_price {
            color: $grey_font_light;
            text-decoration: line-through;
            font-size: 18px;
          }
          .new_price {
            color: $green;
          }
        }
        .catalog_cell_button {
          font-family: $c35;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          color: $orange;
          background: none;
          outline: none;
          border: 1px solid $orange;
          border-radius: 2px;
          padding: 8px 21px;
          display: block;
          margin: auto;
          cursor: pointer;
          margin-bottom: 20px;
          transition: background 0.3s, color 0.3s;
          width: min-content;
        }
        &:hover {
          border-color: #e8e8e8;
          .catalog_cell_button {
            background: $orange;
            color: white;
          }
        }
      }
      .jscroll-added {
          grid-column: 1/4;
        }
      .view_more_block {
          grid-column: 1/4;
        }
      .paginations_block {
    grid-column: 1/4;
  }
      .jscroll-inner, .jscroll-added {
        @extend .catalog_block;
        grid-column: 1/4;
      }
    }
  }
}
.gift_form {
  display: flex;
  border-top: 1px solid $grey_back;
  margin-bottom: 23px;
  .sub_menu_cell {
    display: flex;
    padding: 18px 12px;
    padding-left: 0;
  }
  .gift_filter_label {
    display: flex;
    align-items: center;
    padding-right: 3%;
    white-space: nowrap;
    font-size: 15px;
    font-family: $c35;
  }
  .standart {
    height: 26px;
    border: none;
    color: $blue;
    width: 75px;
    background: none;
    font-size: 15px;
    font-family: $c35;
  }
}

@include small_desktop {
  .catalog_page_container {
    .filtr_container {
      width: 243px;
    }
    .catalog_container {
      padding-left: 258px;
      .catalog_block {
        .catalog_cell {
          .catalog_cell_description { font-size: 12px; }
        }
      }
    }
  }
}
@include tablet {
  #slide_filtr_button,
  .show_gift_filters {
    display: inline-flex;
    width: 32%;
    justify-content: center;
  }
  .gift_form {
    width: 64%;
    display: none;
    flex-direction: column;
    &.active {
      display: flex;
    }
    .sub_menu_cell {
      display: flex;
    }
    .gift_filter_label {
      flex-basis: 75px;
    }
  }
  .catalog_page_container {
    padding: 0 10px;
    .filtr_container {
      position: fixed;
      overflow-y: scroll;
      width: 320px;
      background: white;
      height: 100%;
      left: -330px;
      transition: left 0.15s ease-in;
      z-index: 99;
      &.active {
        display: block;
        box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.3);
        left: -10px
      }
      .choice_form {
        padding-bottom: 200px;
        transform: translateY(30px);
        .find_and_close { display: block; }
        input[type="reset"] {
          order: 0;
          margin: 0 auto 25px;
        }
      }
    }
    .catalog_container {
      padding: 0;
      .catalog_block {
        grid-gap: 10px;
        .catalog_cell {
          .catalog_cell_text {
            .catalog_cell_description { font-size: 12px; }
          }
        }
      }
    }
  }
}
@include big_phone {
  #slide_filtr_button, .show_gift_filters { width: 49%; }
  .gift_form {
    width: 100%;
  }
  .catalog_page_container {
    .catalog_container {
      .catalog_block {
        grid-template-columns: 1fr 1fr;
        .jscroll-added {
          grid-column: 1/3;
        }
        .view_more_block {
          grid-column: 1/3;
        }
        .paginations_block {
          grid-column: 1/3;
        }
        .catalog_cell {
          .catalog_cell_text {
            .catalog_cell_price { font-size: 20px; }
            .catalog_cell_description { font-size: 12px; }
          }
        }
      }
    }
  }
}
@include phone {
  #slide_filtr_button, .show_gift_filters { width: 100%; }
  .gift_form {
    width: 100%;
  }
  .catalog_page_container {
    .catalog_container {
      .catalog_block {
        .catalog_cell {
          .catalog_cell_text {
            .catalog_cell_description { font-size: 12px; }
          }
        }
      }
    }
  }
}
@media (hover: none) {
  .catalog_page_container {
    .catalog_container {
      .catalog_block {
        .catalog_cell {
          &:hover {
            border-color: transparent;
            .catalog_cell_button {
              background: white;
              color: $orange;
            }
          }
        }
      }
    }
  }
}