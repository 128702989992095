.page_profile_subscriptions_container {
  .catalog_container {
    form {
      label {
        display: flex;
        .checkbox {
          width: 18px;
          height: 18px;
          margin-left: 10px;
        }
      }
      .submit {
        @include button($orange, $orange_hover);
        display: block;
        margin-top: 20px;
      }
    }
  }
}