.popup_overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  position: fixed;
  &.active_popup {
    visibility: visible;
    opacity: 1;
    & + .popup_container {
      @keyframes ball {
        0% { top: 0; }
        50% { top: 48%; }
        75% { top: 46%; }
        100% { top: 48%; }
      }
      animation: ball ease-in-out 0.4s;
      top: 48%;
      transform: translateY(-48%);
      visibility: visible;
      opacity: 1;
      overflow-y: auto;
      will-change: transform;
      &.image_preview {
        transform: translate(-48%, -50%);
      }
      @media all and (max-width: 1023px) {
        animation: none;

        &.information_detail.user_return {
          transform: translateY(0);
        }
        &.image_preview {
          transform: translate(0);
          left: 0;
        }
      }
    }
  }
}

.close_popup {
  @include close(15px,15px,$grey_font);
}

.popup_container {
  top: 0;
  right: 0;
  left: 0;
  font-size: 14px;
  z-index: 10001;
  display: block;
  visibility: hidden;
  margin: 0 auto;
  position: fixed;
  width: 850px;
  background-color: $grey_back;
  border-radius: 2px;
  opacity: 0;
  transition: top linear 0.2s;
  a[href*='papara'] { color: $blue; }
  &.add_photo {
    width: 1240px;
    &::-webkit-scrollbar { display: none; }
    &::-moz-scrollbar { display: none; }
  }
  &.pre_order {
    width: 525px;
    .popup_content {
      form {
        max-width: 100%;
        input:not([type="checkbox"]):not([type="submit"]) {
          &.forgot_email { width: 80%; }
        }
      }
      .get_promocode {
        @include button($orange,$orange_hover);
        display: block;
        width: 240px;
        margin: auto;
      }
      .agreement {
        display: flex;
        justify-content: center;
        margin-top: 14px;
        font-family: $c35;
        font-size: 14px;
        color: #52575b;
        .icon {
          @extend .sprite-sprite_images-agreement;
          flex-shrink: 0;
          margin-right: 10px;
        }
      }
    }
  }
  &.autorization,
  &.registration,
  &.order_status,
  &.forgot_password,
  &.change_pass,
  &.change_profile,
  &.change_adress { width: 350px; }
  &.choose_city {
    width: 560px;
    .select2 { width: 100% !important; }
    .popup_content {
      ul {
        padding: 0;
        margin: 20px auto 0;
        width: 90%;
        column-count: 3;
        li a {
          font-family: $c35;
          color: $blue_hover;
          text-decoration: underline;
          &:hover { text-decoration: none; }
        }
      }
    }
  }
  &.order_status_table {
    width: 420px;
    background: #fff;
    .popup_content { padding: 0 40px 40px; }
  }
  &.books_popup {
    width: 1000px;
    .benefits_link_container, .product_detail_tabs {
      display: none;
    }
    .product_detail_container {
      display: flex;
      padding: 20px 10px 10px;
      background: #fff;
      width: 100%;
      .product_detail_gallery {
        margin: 0;
        padding: 0;
        .product_slider {
          width: 20%;
          max-height: 515px;
        }
        .product_fullview { width: 79%; }
      }
      .product_detail_info {
        width: 50%;
        padding: 0 20px;
        margin-bottom: 0;
        .info_main {
          .anchor_link { display: none; }
          .form_detail { margin-top: 10px; }
        }
      }
    }
    @media (min-width: 1024px) and (max-width: 1279px) {
      .product_detail_container,
      .main_information_container {
        .product_detail_gallery {
          flex-wrap: wrap;
          .product_slider {
            order: 2;
            width: 100%;
          }
          .product_fullview {
            order: 1;
            width: 100%;
            padding-bottom: 10px;
            img { width: 100%; }
          }
        }
      }
    }
  }
  &.thanks {
    max-width: 425px;
    padding: 60px 0;
    background: #fff;
    .thanks_title {
      font-size: 20px;
      text-transform: uppercase;
      text-align: center;
      line-height: 1.5;
      margin-bottom: 40px;
    }
    .thanks_logo {
      display: block;
      overflow: hidden;
      img { margin: 0 auto; }
    }
  }
  .title {
    display: block;
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    padding: 40px 0 30px;
    text-align: center;
    background: #fff;
  }
  .social_login {
    @include social_bar();
    .social_login_title {
      position: relative;
      width: 100%;
      text-align: center;
      font-family: $c35;
      font-size: 15px;
      margin-bottom: 15px;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 9px;
        display: block;
        width: 40px;
        height: 1px;
        background: lightgrey;
      }
      &::before { left: 40px; }
      &::after { right: 40px; }
    }
  }
  .popup_content {
    padding: 40px;
    p {
      width: 100%;
      font-family: $c35;
      font-size: 15px;
      margin-bottom: 20px;
      line-height: 1.4;
      &.description { text-align: center; }
    }
    ul {
      @extend p;
      padding-left: 20px;
      &.select_city_container li::before { display: none; }
      li {
        font-family: inherit;
        position: relative;
        margin-bottom: 5px;
        &::before {
          content: '';
          position: absolute;
          top: 3px;
          left: -15px;
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: $orange;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      position: relative;
      z-index: 2;
      tr {
        border-bottom: 1px solid $grey_back;
        th,
        td {
          text-align: center;
          padding: 10px 0;
        }
        th {
          text-transform: uppercase;
          font-family: $c55;
          background: $grey_back;
          border-right: 2px solid #fff;
        }
        td {
          font-family: $c35;
          font-size: 15px;
        }
        @media all and (max-width: 479px) {
          th,
          td {
            padding: 5px;
            font-size: 14px;
            text-transform: lowercase;
          }
        }
      }
    }
    .more_informations {
      display: block;
      width: 70px;
      font-size: 15px;
      text-align: center;
      margin: 0 auto;
      color: $blue_hover;
      border-bottom: 1px dashed;
      &:hover { border-color: transparent; }
      &.forgot_pass {
        width: 110px;
        margin-bottom: 5px;
        @include view_more_link($grey_font_light);
      }
      &.registration {
        width: 85px;
        @include view_more_link($orange);
        border-color: currentColor;
      }
    }
    form {
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 320px;
      .select2 {
        width: 100% !important;
        margin-bottom: 15px;
      }
      input:not([type="checkbox"]):not([type="submit"]) {
        display: block;
        width: 100%;
        border: 1px solid lightgrey;
        padding: 11px;
        font-size: 13px;
        border-radius: 2px;
        margin-bottom: 15px;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        &.forgot_email { width: 195px; }
      }
      input[type="checkbox"]:checked ~ .register { background: $orange; }
      @include chexbox_style();
      label {
        margin-bottom: 15px;
        .more_informations {
          font-family: $c35;
          font-size: 14px;
          width: auto;
        }
      }
      input[type="submit"] {
        @include button($orange,$orange_hover);
        padding: 10px 0;
        width: 100%;
        margin: 0 auto;
        &.ok { width: 60px; }
        &.enter {
          width: 160px !important;
          margin: 0 auto 15px;
        }
        &.register {
          width: 230px !important;
          background: $grey_font_light;
        }
        &.status_submit { width: 170px !important; }
        &.set_promo { width: 200px !important; }
      }
    }
  }
}

.add_photo_container {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .left_pannel {
    width: 295px;
    padding: 50px 0 30px 30px;
    .add_photo_title {
      font-size: 20px;
      text-transform: uppercase;
      @include after_line($orange,15px 0 60px);
    }
    .method_list {
      .method_item {
        margin-bottom: 10px;
        .method_link {
          display: flex;
          border-radius: 3px;
          width: 185px;
          align-items: center;
          .icon_container {
            position: relative;
            width: 50px;
            height: 50px;
            border-radius: 3px;
            background: #d8dde0;
            .icon {
              position: absolute;
              margin: auto;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
            }
          }
          .text_link {
            font-family: $c35;
            font-size: 15px;
            padding: 0 10px;
          }
          &.active {
            .icon_container { background: inherit; }
            .text_link { color: #fff; }
          }
          &.device {
            .icon { @extend .sprite-sprite_images-device_popup; }
            &.active { background: $green; }
            &.activated .icon_container { background: $green; }
          }
          &.vk {
            .icon { @extend .sprite-sprite_images-vk_popup; }
            &.active { background: #466a90; }
            &.activated .icon_container { background: #466a90; }
          }
          &.facebook {
            .icon { @extend .sprite-sprite_images-facebook_popup; }
            &.active { background: $blue; }
            &.activated .icon_container { background: $blue; }
          }
          &.odnoklasniki {
            .icon { @extend .sprite-sprite_images-odnoklasniki_popup; }
            &.active { background: $orange; }
            &.activated .icon_container { background: $orange; }
          }
          &.instagram {
            .icon { @extend .sprite-sprite_images-instagram_popup; }
            &.active { background: #000; }
            &.activated .icon_container { background: #000; }
          }
          &.archive {
            .icon { @extend .sprite-sprite_images-archive_popup; }
            &.active { background: #9ea5aa; }
            &.activated .icon_container { background: #9ea5aa; }
          }
        }
      }
    }
  }
  .right_pannel {
    width: 925px;
    background: #fff;
    padding: 50px 30px 30px 45px;
    display: flex;
    .subpanel {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      .jscroll-inner {
        width: auto;
      }
      .top-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        .select_all { @include chexbox_style(); }
        .link-text {
          font-family: $c35;
          font-size: 15px;
        }
        .ok {
          @include button($orange,$orange_hover);
          width: 180px;
          &.inactive {
            background: $grey_font_light;
            &:hover { background: $grey_font_light; }
          }
        }
      }
      .empty {
        width: 300px;
        margin: auto;
        text-align: center;
        .img { margin: 0 auto 20px; }
        .text {
          font-family: $c55;
          text-transform: uppercase;
          font-size: 16px;
          margin-bottom: 25px;
        }
        .upload {
          @include button($green,$green);
          padding: 15px 10px;
          &:hover { opacity: 0.9; }
          &.vk {
            background: #466a90;
            &:hover { background: #466a90; }
          }
          &.odk {
            background: $orange;
            &:hover { background: $orange; }
          }
          &.fb {
            background: $blue;
            &:hover { background: $blue; }
          }
          &.insta {
            background: #000;
            &:hover { background: #000; }
          }
        }
      }
      #device-dropzone,
      .fill.scroll .list {
        @extend .empty;
        width: 100%;
        margin: 0;
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        max-height: 380px;
        overflow-y: scroll;
        cursor: pointer;
        &::-webkit-scrollbar {
          width: 8px;
          background: $grey_back;
        }
        &::-webkit-scrollbar-arrow { display: none; }
        &::-webkit-scrollbar-thumb {
          display: block;
          background: $grey_font_light;
        }
        .download_more {
          &_container {
            width: 100%;
            order: 99;
            text-align: center;
            margin: 15px 0 20px;
            &:hover .download_more_link{
              border-bottom-color: transparent;
            }
          }
          &_link {
            user-select: none;
            font-size: 15px;
            color: $blue;
            border-bottom: 1px dashed $blue;
          }
        }
        .dz-message { margin: auto; }
        .dz-preview,
        .item {
          position: relative;
          width: 23%;
          margin: 0 1% 20px;
          padding: 5px;
          border-radius: 3px;
          border: 1px solid transparent;
          &.folder {
            margin-top: 15px;
            &::before {
              content: '';
              position: absolute;
              width: 100px;
              height: 10px;
              background: #f39f61;
              left: 0;
              top: -10px;
              border-radius: 5px 5px 0 0;
            }
            &:hover { border-color: transparent; }
          }
          &:hover { border-color: $grey_font; }
          &::before {
            content: '';
            position: absolute;
            top: 10px;
            left: 10px;
            display: block;
            width: 15px;
            height: 15px;
            background: #fff;
            z-index: 5;
            border: 1px solid lightgrey;
            border-radius: 2px;
          }
          &.active {
            &::after {
              content: '';
              display: block;
              position: absolute;
              top: 12px;
              left: 13px;
              width: 9px;
              height: 6px;
              border-left: 2px solid $grey_font;
              border-bottom: 2px solid $grey_font;
              transform: rotate(-45deg);
              z-index: 6;
            }
          }
          .dz-error-mark,
          .dz-error-message,
          .dz-size,
          .dz-success-mark { display: none; }
          .dz-filename,
          .name {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: $c35;
            font-size: 13px;
          }
          .dz-image,
          .img-wrapper {
            position: relative;
            width: 100%;
            margin-bottom: 5px;
            img {
              position: relative;
              width: 100%;
              max-height: 140px;
              object-fit: cover;
              margin: 0 auto 5px;
              border: 1px solid lightgrey;
              z-index: 2;
            }
            .folder-back {
              position: absolute;
              top: -5px;
              left: -5px;
              width: calc(100% + 10px);
              height: 100%;
              background: #f39f61;
              z-index: 1;
              border-radius: 0 5px 0 0;
            }
            .folder-front {
              position: absolute;
              bottom: 0;
              left: -5px;
              width: calc(100% + 10px);
              background: #ffc499;
              height: 70px;
              border-radius: 0 0 5px 5px;
              z-index: 3;
            }
          }
          canvas {
            display: none;
          }
          &.not-loaded,
          .dz-progress {
            canvas {
              display: block;
              z-index: 5;
              position: absolute;
              top: calc(50% - 41px);
              left: calc(50% - 41px);
            }
          }
          &.dz-complete {
            .dz-progress { display: none; }
          }
        }
        &.dz-started {
          .dz-message { display: none; }
        }
      }
      @media (min-width: 1024px) and (max-width: 1279px) {
        #device-dropzone,
        .fill.scroll .list {
          .dz-preview,
          .item {
            width: 31%;
            margin: 0 1% 20px;
          }
        }
      }
      @media (min-width: 480px) and (max-width: 767px) {
        #device-dropzone,
        .fill.scroll .list {
          .dz-preview,
          .item {
            width: 31%;
            margin: 0 1% 15px;
          }
        }
      }
      @media all and (max-width: 479px) {
        #device-dropzone,
        .fill.scroll .list {
          max-height: 280px;
          .dz-preview,
          .item {
            width: 48%;
            margin: 0 1% 10px;
          }
        }
      }
    }
  }
}

.popup_label {
  position: fixed;
  z-index: 998;
  bottom: 200px;
  cursor: pointer;
  transition: left 0.4s;
  @include big_phone {
    bottom: 15px;
  }
}

.information_detail.popup_container {
  width: 550px;
  background-color: #fff;

  @media all and (max-width: 1023px) {
    display: block;
    width: 100%;
    height: 100%;
    transform: translateY(0);
    padding-top: 30px;
  }

  .popup_content { padding: 1px; }

  .quality {
    &.active .name,
    .content_title { color: #d04479; }
  }
  .return {
    &.active .name,
    .content_title { color: #f2c24d; }
  }
  .delivery {
    &.active .name,
    .content_title { color: #2f72b1; }
  }
  .money {
    &.active .name,
    .content_title { color: #009d51; }
  }

  .tab_block {
    display: flex;

    .tab_cell {
      flex: 1 1 auto;
      width: 100%;
      height: 115px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 25px;
      cursor: pointer;
      background: $grey_back;

      @media all and (max-width: 479px) {
        height: 80px;
        padding-bottom: 5px;
      }

      &.active { background: #fff; }

      @each $i in quality, return, delivery, money {
        &.#{$i} .icon { @extend .sprite-sprite_images-#{$i}_default; }
        &.#{$i}.active .icon { @extend .sprite-sprite_images-#{$i}_active; }
      }
    }
  }

  .content_block {
    padding: 30px 40px;

    @media all and (max-width: 479px) {
      padding: 20px 10px;
    }
  }

  .content_title {
    font-size: 25px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 15px;
    letter-spacing: 0.5px;
  }

  .text {
    margin-bottom: 10px;
    line-height: 1.33;
  }

  .button {
    @include button($orange, $orange_hover);
    font-size: 13px;
    letter-spacing: 0.5px;
    padding: 12px 20px;
    margin-left: 50%;
    margin-top: 10px;
    transform: translateX(-50%);
  }

  .close_popup {
    right: -20px;
    top: -20px;

    &::before,
    &::after { background: #fff; }

    @media all and (max-width: 1023px) {
      right: 5px;
      top: 5px;

      &::before,
      &::after { background: $grey_font; }
    }
  }
}

.user_return.popup_container {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;

    @media all and (max-width: 479px) { padding: 0; }
  }

  .logo {
    @extend .sprite-sprite_images-balloon;
    margin-bottom: 30px;

    @media all and (max-width: 479px) { margin-bottom: 15px; }
  }

  .main_title {
    margin: 0;
    font-family: $germ;
    font-size: 38px;
    text-transform: uppercase;
    text-align: center;
    color: $orange;

    @include after_line($orange,15px auto 30px);

    @media all and (max-width: 479px) {
      font-size: 26px;

      &::after { margin: 15px auto; }
    }
  }

  .text {
    max-width: 410px;
    margin: 0 auto 10px;
    font-family: $c35;
    font-size: 16px;
    line-height: 1.4;
    text-align: center;

    span {
      font-family: inherit;
    }

    @media all and (max-width: 479px) { max-width: 290px; }
  }

  .city {
    display: inline-block;
    font-family: $c35;
    color: $blue;
    border-bottom: 1px dashed $blue;

    &:hover { border-color: transparent; }
  }

  .day { color: $orange; }

  .free {
    margin: 0 auto 45px;
    font-size: 25px;
    text-align: center;
    text-transform: uppercase;
    color: $blue;

    @media all and (max-width: 479px) {
      font-size: 20px;
      margin: 0 auto 30px;
    }
  }

  .to_finish {
    @include button($orange, $orange_hover);
    padding: 10px 20px;
    font-size: 16px;
  }
}

.size_popup.popup_container {
  max-width: 730px;

  @media all and (max-width: 1023px) {
    max-width: 100%;
  }

  .title {
    background: $grey_back;
    font-size: 25px;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 45px 0 70px;
    background: #fff;

    @media all and (max-width: 767px) {
      height: 100%;
      padding: 30px 0;
    }
  }

  .img_block {
    @media all and (max-width: 767px) {
      width: 120px;
    }
  }

  .subtitle {
    text-align: center;
    text-transform: uppercase;
    padding: 10px 0 25px;

    .male {
      font-family: inherit;
      font-weight: inherit;
    }

    @media all and (max-width: 767px) { font-size: 12px; }
  }

  .img {
    width: 100%;
    height: auto;
  }

  .table_block {
    margin-left: 20px;
    max-height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      background: $grey_back;
    }
    &::-webkit-scrollbar-arrow { display: none; }
    &::-webkit-scrollbar-thumb {
      display: block;
      background: $grey_font_light;
    }

    @media all and (max-width: 1023px) { max-height: 100%; }

    @media all and (max-width: 767px) {
      margin-left: 10px;
    }
  }

  .table{
    width: 395px;

    @media all and (max-width: 767px) {
      width: 185px;
    }

    th {
      font-size: 16px;
      padding: 8px 0;
      background: $grey_back;

      //&:nth-last-child(2) {
      //  background: $orange;
      //  color: #fff;
      //}

      &:last-child {
        background: $blue;
        color: #fff;
      }
    }

    td {
      font-family: $c35;
      font-size: 15px;
      text-transform: uppercase;
      padding: 15px 0 8px;
      border-bottom: 1px solid $grey_back;

      @media all and (max-width: 767px) { padding: 10px 0 5px; }

      //&:nth-last-child(2) { color: $orange; }

      &:last-child { color: $blue; }
    }

    th,
    td {
      width: 25%;
      text-align: center;

      @media all and (max-width: 767px) { font-size: 12px; }
    }

    span {
      color: inherit;

      @media all and (max-width: 767px) { display: none; }
    }

    .icon {
      display: none;
      vertical-align: middle;
      margin-right: 3px;

      @media all and (max-width: 767px) { display: inline-block; }

      @each $i in width, height {
        &.#{$i} {
          @extend .sprite-sprite_images-#{$i};
        }
      }
    }
  }
}

.product_review.popup_container {
  width: 900px;
  padding: 40px;
  background: #fff;
  .content_container {
    display: flex;
    justify-content: space-between;
    min-height: 300px;
    .slider {
      flex-grow: 1;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      position: relative;
      .video_container {
        width: calc(100% - 80px);
        height: 100%;
        video {
          background: black;
        }
      }
      .img_container {
        width: calc(100% - 80px);
        max-width: 480px;
        display: grid;
        align-items: center;
        justify-items: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .slide_button {
        background: transparent;
        border: none;
        cursor: pointer;
        align-items: center;
        width: 40px;
        height: 100%;
        display: flex;
        justify-content: center;
        &.prev {
          left: 0;
          .icon {
            transform: rotate(180deg);
          }
        }
        &.next {
          right: 0;
        }
        &.invisible {
          visibility: hidden;
        }
        .icon {
          background: white;
          width: 21px;
          height: 21px;
          border-radius: 50%;
          box-shadow: 0 0 6px 2px rgba(100, 100, 100, 0.2);
          position: relative;
          &:before, &:after {
            content: '';
            display: block;
            width: 9px;
            height: 1px;
            background: $grey_font;
            position: absolute;
            transform-origin: right center;
            top: 9.5px;
            left: 5.5px;
          }
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }
    .content_info {
      margin-left: 40px;
      width: 300px;
      min-width: 300px;
      .user_info {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .user_name {
          font-family: $c45;
          color: $grey_font;
          font-size: 15px;
          margin-bottom: 3px;
        }

        .user_city {
          font-family: $c35;
          font-size: 14px;
          color: $grey_font_light;
        }
      }
      .user_review {
        font-family: $c35;
        font-size: 15px;
        line-height: 1.33;
        margin-bottom: 35px;
        &::-webkit-scrollbar {
          width: 8px;
          background: $grey_back;
        }
        &::-webkit-scrollbar-arrow { display: none; }
        &::-webkit-scrollbar-thumb {
          display: block;
          background: $grey_font_light;
        }

        @media all and (min-width: 768px) {
          max-height: 250px;
          overflow-y: auto;
        }
      }
      .user_item {
        display: flex;
        margin-bottom: 45px;

        .user_item_img {
          border: 3px solid $grey_font_light;
          width: 70px;
          height: 70px;
        }

        .user_item_description {
          display: flex;
          flex-direction: column;
          margin-left: 15px;

          .user_item_title {
            text-transform: uppercase;
            font-size: 16px;
            margin-bottom: 2px;
          }

          .user_item_types {
            display: block;
            font-family: $c35;
            font-size: 15px;
            color: $grey_font_light;
          }

          .user_item_price {
            color: green;
            margin-top: auto;
          }
        }
      }
      .photo_review_button {
        @include button($orange, $orange_hover);
        padding: 13px 20px;
        font-size: 14px;
        line-height: 1;
        margin-left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
      }
    }
  }
}

.video_review.popup_container {
  width: 955px;
  padding: 40px 20px 40px 30px;
  background: #fff;
  animation: none !important;
  max-height: 420px;
  margin: auto;
  .content_container {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .video_container {
    width: 100%;
    max-width: 605px;
    height: 340px;

    iframe {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .products_container {
    width: 100%;
    max-width: 280px;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 30px;

    &::-webkit-scrollbar {
      width: 8px;
      background: $grey_back;
    }
    &::-webkit-scrollbar-arrow { display: none; }
    &::-webkit-scrollbar-thumb {
      display: block;
      background: $grey_font_light;
    }
  }
  .user_item {
    display: flex;
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .user_item_img {
      border: 3px solid $grey_font_light;
      width: 70px;
      height: 70px;
    }

    .user_item_description {
      display: flex;
      flex-direction: column;
      margin-left: 15px;

      .user_item_title {
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 2px;
      }

      .user_item_types {
        display: block;
        font-family: $c35;
        font-size: 15px;
        color: $grey_font_light;
      }

      .user_item_price {
        font-size: 16px;
        color: green;
        margin-top: auto;
      }
    }

    @media all and (max-width: 600px) {
      &:last-of-type {
        margin-bottom: 30px;
      }
    }
  }
  .close_popup_button {
    position: absolute;
    bottom: -44px;
    left: -10px;
    display: none;
    width: calc(100% + 20px);
    padding: 15px 0;
    text-align: center;
    font-size: 12px;
    font-family: $c35;
    color: #fff;
    background: $blue;

    &:hover { background: $blue_hover; }

    @media all and (max-width: 600px) { display: block; }
  }
  @media all and (max-width: 1023px) {
    padding: 30px 10px 30px;
    max-height: 100%;
    margin: 0;
    .video_container {
      max-width: 60%;
    }
  }
  @media all and (max-width: 767px) {
    .video_container {
      max-width: 50%;
    }
  }
  @media all and (max-width: 600px) {
    padding: 45px 10px 10px;
    .content_container {
      flex-wrap: wrap;
    }
    .video_container {
      max-width: 100%;
      max-height: 175px;
      margin-bottom: 25px;
    }
    .products_container {
      max-height: 100%;
      max-width: 100%;
      margin: 0;
    }
  }
}

.dont_buy.popup_container {
  width: 475px;
  background: #fff;
  padding: 60px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media all and (max-width: 1023px) {
    display: flex !important;
    padding-bottom: 90px;
  }

  .dont_by_logo {
    display: block;
    margin-bottom: 40px;
    min-height: 65px;
    @extend .sprite-sprite_images-dont_buy;
  }

  .dont_buy_title {
    font-family: $germ;
    font-size: 38px;
    text-transform: uppercase;
    font-weight: 400;
    color: $orange_hover;

    @include after_line($orange, 12px auto 30px);
  }

  .dont_buy_text {
    max-width: 290px;
    text-align: center;
    line-height: 1.3;
    font-family: $c35;
    margin-bottom: 32px;
    font-size: 16px;
  }

  .dont_buy_button {
    @include button($blue, $blue_hover);

    @media all and (max-width: 767px) {
      width: 100%;
    }
  }
}

.image_preview.popup_container {
  padding: 40px;
  width: auto;
  height: auto;
  max-width: 830px;
  max-height: 80vh;

  right: auto;
  left: 50%;

  img {
    max-height: 70vh;
    max-width: 750px;
    margin: auto;
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    width: auto !important;
  }

  @media all and (max-width: 1023px) {
    max-width: 100vw;
    max-height: 100vh;
  }
}

.popup_overlay.active_popup#present {
  & + .present.popup_container {
    width: 543px;
    background: white;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: center;
    img { width: 100%; }
    .popup_content {
      padding: 30px 30px 40px 30px;
      .title{
        color: $orange;
        font-family: $germ;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
        line-height: 25px;
        display: block;
        padding: 0;
        margin: 0 auto 26px;
        @include after_line($orange, 20px auto);
      }
      p {
        font-family: $c35;
        color: $grey_font;
        font-size: 16px;
        max-width: 428px;
        margin: auto;
      }
      form input[name=email] {
        margin: 34px auto 40px;
        width: 342px;
      }
      form .enter {
        margin-bottom: 0;
        padding-left: 36px;
        padding-right: 36px;
        width: auto !important;
      }
      a {
        @extend .sprite-sprite_images-close;
        &:after {
          content: none;
        }
        &:before {
          content: none;
        }
      }
      .agreement {
        display: flex;
        justify-content: center;
        margin-top: 14px;
        font-family: $c35;
        font-size: 14px;
        color: #52575b;
        .icon {
          @extend .sprite-sprite_images-agreement;
          flex-shrink: 0;
          margin-right: 10px;
        }
      }
    }
  }
  //Переназначаем проставленное где-то media max-width 1023px
  @media (max-width: 1023px) {
    & {
      display: block;
      z-index: 100000;
    }
    & + .present.popup_container {
      display: block;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 100001;
    }
  }
  //Устанавливаем необходимые media
  @media (max-width: 1023px) and (max-width: 768px) {
    & + .present.popup_container {
      width: 380px;
      .popup_content {
        padding: 20px 20px 28px 20px;
        h1 {
          font-size: 28px;
          margin-bottom: 10px;
        }
        .rectangle {
          width: 22px;
          margin-bottom: 12px;
        }
        form input:first-child {
          width: 239px;
          height: 24px;
          margin-bottom: 29px;
          font-size: 12px;
          color: $grey_font_light;
          font-family: $c35;
        }
        .close_popup {
          top: 10px;
          right: 10px;
        }
      }
    }
  }
  @media (max-width: 1023px) and (max-width: 380px){
    & + .present.popup_container {
      width: 291px;
      .popup_content {
        padding: 20px 20px 40px 20px;
        form input[name=email] {
          margin: 20px auto 28px;
        }
        form .enter {
          height: 40px;
          font-size: 16px;
        }
      }
    }
  }
}

.present.popup_label {
  $hidden: 25px;
  height: 129px;
  left: -$hidden;
  width: 47px + $hidden;
  background: $orange;
  border-radius: 0 4px 4px 0;
  text-align: right;
  .header {
    width: 100%;
    height: 51px;
    background: #d36a16;
    border-radius: 0 4px 0 0 ;
    .icon-present {
      display: inline-block;
      @extend .sprite-sprite_images-present;
      margin: 8px 8px 0 0;
    }
    .img-icon-present {
      display: inline-block;
      margin: 8px 8px 0 0;
      width: 32px;
      height: 30px;
    }
  }
  .text {
    color: white;
    font-family: $c45;
    font-size: 12px;
    text-align: center;
    position: absolute;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 15px;
    transform: rotate(-90deg);
    top: 74px;
    max-width: 50px;
    right: -2px;
  }
  &.active {
    left: 0;
  }
  @include big_phone {
    bottom: -4px;
    $scale: 0.765;
    & {
      transform-origin: $hidden;
      transform: scale($scale);
    }
    &.active {
      left: -$hidden + ($hidden * $scale);
    }
  }
}

// Стили для авторизации, регистрации и пароля на отдельной странице
.authorization_page {
  height: 100vh;
  .authorization_page_popup,
  .registration_page_popup,
  .forgot_password_page_popup {
    opacity: 1;
    position: static;
    margin: auto;
    visibility: visible;
    background: $grey_back;
    display: none;

    &.active { display: block; }

    .title,
    .social_login { background: transparent; }
    .close_popup { display: none; }
  }
  @media all and (max-width: 1023px) {
    .authorization_page_popup,
    .registration_page_popup,
    .forgot_password_page_popup {
      &.active { display: block; }
    }
  }
}

@include small_desktop {
  .popup_container {
    &.add_photo { width: 1000px; }
    &.change_pass,
    &.change_profile,
    &.change_adress { width: 350px; }
    &.pre_order { width: 525px; }
  }
  .add_photo_container {
    max-width: 1000px;
    .left_pannel {
      width: 243px;
      .add_photo_title { font-size: 16px; }
    }
    .right_pannel { width: 755px; }
  }
}
@include tablet {
  .popup_container {
    &.product_review {
      display: block;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }
    &.add_photo {
      display: block;
      overflow-y: scroll;
      background: #fff;
      width: calc(100% - 20px);
    }
    &.change_pass,
    &.change_profile,
    &.change_adress,
    &.pre_order,
    &.pay {
      padding-bottom: 100px;
      .popup_content { padding: 0 40px; }
      form {
        input:not([type="checkbox"]):not([type="submit"]) { width: 100%; }
        input[type="submit"] {
          width: 100%;
          &.ok { width: 100%; }
        }
      }
    }
    &.pre_order {
      .popup_content {
        form {
          max-width: 525px;
          input:not([type="checkbox"]):not([type="submit"]) {
            &.forgot_email { width: 78%; }
          }
          input[type="submit"] {
            width: 60px;
            &.ok { width: 60px; }
          }
        }
      }
    }
    &.video_review {
      display: block;
      margin: auto;
    }
  }
  .add_photo_container {
    padding: 15px 10px;
    .left_pannel {
      width: 100%;
      padding: 0;
      .add_photo_title::after { margin: 15px 0 25px; }
      .method_list {
        display: flex;
        .method_item {
          margin: 0 10px 0 0;
          .method_link {
            width: 50px;
            .icon_container { margin-right: 0; }
            .text_link { display: none; }
            &.active {
              width: auto;
              .text_link {
                display: block;
                padding: 0 10px 0 0;
              }
            }
          }
        }
      }
    }
    .right_pannel {
      width: 100%;
      padding: 25px 0 10px;
    }
  }
}
@include big_phone {
  .popup_container {
    &.product_review {
      padding: 0;
      .content_container {
        flex-direction: column;
        .slider {
          height: 40vh;
          margin: 46px 10px 0;
        }
        .content_info {
          margin: 20px auto 0;
          width: 100%;
          position: relative;
          padding: 0 20px 65px;
          .user_info {
            margin-bottom: 15px;
          }
          .photo_review_button {
            position: relative;
            left: 0;
            display: block;
            width: calc(100% + 40px);
            border-radius: 0;
          }
        }
      }
    }
    &.change_pass,
    &.change_profile,
    &.change_adress,
    &.pre_order {
      padding-bottom: 100px;
      .popup_content { padding: 0 10px; }
    }
    &.choose_city {
      width: 400px;
      .popup_content {
        .select_city_container {
          column-count: 2;
        }
      }
    }
  }
}
@include phone {
  .popup_container {
    &.autorization, &.registration, &.forgot_password {
      width: 300px;
      .title {
        padding: 15px 40px 10px;
      }
      .social_login_link {
        margin: 0 10px 10px;
      }
      .popup_content {
        padding: 15px 15px 20px;
      }
    }
    &.choose_city {
      width: 300px;
      .popup_content {
        padding: 30px 15px;
      }
    }
    &.thanks {
      width: 300px;
      height: auto;
    }
  }
  .add_photo_container {
    .left_pannel {
      .method_list {
        .method_item {
          .method_link {
            width: 38px;
            height: 38px;
            .icon_container {
              margin-right: 0;
              width: 40px;
              height: 40px;
            }
            .text_link { display: none; }
            &.active {
              width: 40px;
              .icon_container { margin-right: 0; }
              .text_link { display: none; }
            }
          }
        }
      }
    }
    .right_pannel {
      .subpanel {
        .top-block {
          .select_all {
            margin: 0 5px 0 0;
          }
          .ok {
            padding: 10px 0;
          }
        }
      }
      .dz-default {
        .img {
          width: 150px;
        }
      }
    }
  }
}
