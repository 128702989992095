/* glue: 0.13 hash: ad291374b4 */

  .sprite-delivery_points-boxberry {
    background-image: url('img/sprites/delivery_points.png');
    background-repeat: no-repeat;
    background-size: 193px 115px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/delivery_points.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/delivery_points@2x.png');
    }
    
    background-position: 0 0;
    width: 135px;
    height: 32px;
  }

  .sprite-delivery_points-ems {
    background-image: url('img/sprites/delivery_points.png');
    background-repeat: no-repeat;
    background-size: 193px 115px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/delivery_points.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/delivery_points@2x.png');
    }
    
    background-position: 0 -32px;
    width: 95px;
    height: 38px;
  }

  .sprite-delivery_points-cdek {
    background-image: url('img/sprites/delivery_points.png');
    background-repeat: no-repeat;
    background-size: 193px 115px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/delivery_points.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/delivery_points@2x.png');
    }
    
    background-position: 0 -70px;
    width: 89px;
    height: 22px;
  }

  .sprite-delivery_points-papara {
    background-image: url('img/sprites/delivery_points.png');
    background-repeat: no-repeat;
    background-size: 193px 115px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/delivery_points.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/delivery_points@2x.png');
    }
    
    background-position: 0 -92px;
    width: 69px;
    height: 23px;
  }

  .sprite-delivery_points-russian_post {
    background-image: url('img/sprites/delivery_points.png');
    background-repeat: no-repeat;
    background-size: 193px 115px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/delivery_points.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/delivery_points@2x.png');
    }
    
    background-position: -135px 0;
    width: 58px;
    height: 28px;
  }

  .sprite-delivery_points-papara_short {
    background-image: url('img/sprites/delivery_points.png');
    background-repeat: no-repeat;
    background-size: 193px 115px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/delivery_points.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/delivery_points@2x.png');
    }
    
    background-position: -135px -28px;
    width: 25px;
    height: 32px;
  }

  .sprite-delivery_points-cdek_short {
    background-image: url('img/sprites/delivery_points.png');
    background-repeat: no-repeat;
    background-size: 193px 115px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/delivery_points.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/delivery_points@2x.png');
    }
    
    background-position: -160px -28px;
    width: 25px;
    height: 32px;
  }

  .sprite-delivery_points-boxberry_short {
    background-image: url('img/sprites/delivery_points.png');
    background-repeat: no-repeat;
    background-size: 193px 115px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/delivery_points.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/delivery_points@2x.png');
    }
    
    background-position: -135px -60px;
    width: 25px;
    height: 32px;
  }
