.page_videoreviews {
  .pages_info_container .info_block .videoreview_form .select2 {
    width: 225px !important;
  }

  .videoreviews_container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    .jscroll-inner,
    .jscroll-added {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  .video_cell {
    width: calc((100% - (20px * 2)) / 3);
    margin-right: 20px;
    margin-bottom: 20px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    @media all and (max-width: 1023px) {
      width: calc((100% - 10px) / 2);
      margin-right: 10px;

      &:nth-child(3n) {
        margin-right: 10px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @media all and (max-width: 600px) {
      width: 100%;
      margin-right: 0;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .video_img {
    display: block;
    width: 100%;
    height: auto;
  }

  .video_description {
    font-family: $c45;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.3;
    margin-top: 7px;

    @media all and (max-width: 1023px) {
      margin-top: 15px;
    }
  }
}