.page_404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
  align-content: center;

  .text {
    font-family: $c35;
    margin-bottom: 50px;

    a {
      font-family: $c35;
      color: $blue;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .product {
    width: 100%;
    max-width: 480px;
    margin-bottom: 35px;

    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  .button {
    @include button($orange,$orange_hover);
    margin-bottom: 30px;
  }
}
