.main_page {
  width: 100%;
  max-width: 1240px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  h2 {
    font-family: $germ;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.08em;
    margin: 0 0 38px 0;
    text-align: center;
  }
  .slider {
    width: 100%;
    max-height: 360px;
    margin: 0 0 38px;
    overflow: hidden;
    display: flex;
    position: relative;
    a:not(:first-of-type) {
      position: absolute;
      &.loading {
        display: none;
      }
    }
    a {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .top_products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 0 0 54px 0;
    .product {
      .img_wrapper {
        img {
          margin: 0 0 10px 0;
          width: 100%;
        }
      }
      span {
        font-family: $c45;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.02em;
      }
      p {
        font-family: $c35;
        font-size: 15px;
        margin: 5px 0 0 0;
        letter-spacing: 0.02em;
      }
    }
  }
  .products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 0 0 45px 0;
    .product {
      position: relative;
      &.w2 {
        grid-column: 1/3;
      }
      &.w2c3 {
        grid-column: 3/5;
      }
      .img_wrapper {
        overflow: hidden;
        border-radius: 12px;
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
        img {
          transition: transform 0.3s;
          width: 100%;
        }
      }
      .title {
        position: absolute;
        left: 12px;
        bottom: 12px;
        background: rgba(255, 255, 255, 0.9);
        padding: 8px 17px;
        font-size: 16px;
        .type {
          display: block;
          font-family: $c45;
          font-size: inherit;
          text-transform: uppercase;
        }
        .old_price {
          font-family: $c45;
          font-size: inherit;
          color: $grey_font_light;
          text-decoration: line-through;
          white-space: nowrap;
        }
        .price {
          font-family: $c45;
          font-size: inherit;
          color: $green;
          white-space: nowrap;

        }
      }
      .label {
        position: absolute;
        font-family: $c35;
        background: $orange;
        font-size: 9px;
        color: white;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        padding: 4px 8px;
        left: 0;
        top: 20px;
        &:after {
          content: '';
          display: block;
          position: absolute;
          border: 5px solid transparent;
          border-top: 9px solid $orange;
          border-bottom: 9px solid $orange;
          right: -5px;
          top: 0;
        }
        &.red {
          background: $red;
          &:after {
            border-top-color: $red;
            border-bottom-color: $red;
          }
        }
        &.green {
          background: $green;
          &:after {
            border-top-color: $green;
            border-bottom-color: $green;
          }
        }
      }
    }
    .list {
      grid-column: 1/5;
      display: flex;
      flex-wrap: wrap;
      a {
        font-family: $c35;
        font-size: 18px;
        color: $blue;
        background: white;
        padding: 6px 12px;
        border-radius: 3px;
        border: 1px solid $blue;
        margin: 0 20px 20px 0;
        &:hover {
          background: $blue;
          color: white;
        }
      }
    }
    &.special_grid {
      .product:nth-of-type(3) {
        grid-column: 3/5;
      }
      .product:nth-of-type(4) {
        grid-column: 1/4;
      }
    }
  }
  .celebrations {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    width: 100%;
    margin: 0 0 38px 0;
    .celebration {
      padding: 0 0 0 20px;
      display: grid;
      grid-template-columns: 1fr min-content;
      grid-template-rows: min-content min-content;
      position: relative;
      &:after {
        content: '';
        display: inline-block;
        width: 2px;
        height: 100%;
        background: #f0f4f6;
        position: absolute;
        right: -12px;
        top: 0;
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
      .date {
        font-family: $c45;
        font-size: 16px;
        text-transform: uppercase;
      }
      .title {
        font-family: $c35;
        font-size: 15px;
        grid-row: 2;
        padding: 0 10px 0 0;
      }
      .wait {
        font-family: $c45;
        font-size: 12px;
        color: $grey_font_light;
        white-space: nowrap;
        grid-row: 1/ span 2;
        margin-top: 12px;
      }
    }
  }
  .reviews {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    margin-bottom: 40px;
    .view_all {
      grid-column: 1/5;
      justify-self: end;
      font-family: $c35;
      font-size: 14px;
      color: $blue;
      text-decoration: underline;
      margin-bottom: 30px;
      margin-top: 10px;
    }
    .review {
      display: grid;
      .comment {
        align-self: start;
        margin-bottom: 20px;
        .rate_star {
          margin-bottom: 16px;
        }
        .text {
          font-family: $c35;
          font-weight: normal;
          font-size: 15px;
          line-height: 21px;
          p {
            font-family: $c35;
            font-weight: normal;
            font-size: 15px;
            line-height: 21px;
          }
        }
      }
      .info {
        align-self: end;
        .name {
          font-size: 16px;
        }
      }
    }
  }
}

@include small_desktop() {
  .main_page {
    max-width: 1000px;
  }
}
@include tablet() {
  .main_page {
    padding: 0 10px;
    max-width: none;
    h2 {
      font-size: 26px;
      margin: 0 0 26px 0;
    }
    .slider {
      width: 100vw;
    }
    .top_products {
      grid-gap: 10px;
      margin: 0 0 40px 0;
    }
    .products {
      grid-gap: 10px;
      margin: 0 0 40px 0;
      .product {
        .title {
          padding: 8px 10px;
          margin: 0 10px 0 0;
          font-size: 14px;
        }
      }
      .list {
        a {
          font-size: 14px;
          margin: 0 10px 10px 0;
        }
      }
    }
    .celebrations {
      .celebration {
        padding: 0 10px 0 10px;
      }
    }
  }
}
@include big_phone() {
  .main_page {
    h2 {
      font-size: 24px;
    }
    .top_products {
      grid-template-columns: repeat(2, 1fr);
      a {
        max-width: 295px;
      }
    }
    .products {
      grid-template-columns: repeat(2, 1fr);
      &.special_grid {
        .product:nth-of-type(3) {
          grid-column: unset;
        }
        .product:nth-of-type(4) {
          grid-column: unset;
        }
      }
      .product {
        max-width: 295px;
        &.w2 {
          max-width: 600px;
        }
        &.w2c3 {
          max-width: 600px;
          grid-column: 1/3;
        }
        .img_wrapper {
          border-radius: 8px;
        }
      }
      .list {
        grid-column: 1/3;
        max-width: 600px;
        a {
          margin: 0 6px 6px 0;
        }
      }
    }
    .celebrations {
      grid-template-columns: repeat(2, 1fr);
    }
    .reviews {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 20px;
      .view_all {
        grid-column: 1/3;
      }
      .review {
        margin-bottom: 30px;
        .comment {
          margin-bottom: 10px;
          .rate_star {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
@include phone() {
  .main_page {
    h2 {
      font-size: 17px;
      margin: 0 auto 16px;
    }
    .slider {
      width: 140vw;
    }
    .top_products {
      margin: 0 0 24px 0;
      p {
        font-size: 10px;
      }
    }
    .products {
      margin: 0 0 24px 0;
      .product {
        .title {
          font-size: 10px;
        }
        .label {
          top: 10px;
        }
      }
    }
    .celebrations {
      grid-template-columns: 1fr;
    }
    .reviews {
      grid-template-columns: 1fr;
      .view_all {
        grid-column: auto;
      }
    }
  }
}
