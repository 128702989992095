.page.order-check {
  margin-bottom: 40px;
  h2 {
    @include h2
  }
  p {
    @include p
    margin-bottom: 22px;
  }
  form {
    display: grid;
    max-width: 700px;
    grid-gap: 12px;
    .data-text {
      display: grid;
      max-width: 300px;
      margin-bottom: 4px;
      span {
        margin-bottom: 4px;
      }
      input { @include input }
    }
    .error-message {
      display: none;
      color: $red;
      font-size: 12px;
    }
    input[type=submit] {
      width: 187px;
      margin-top: 9px;
      @include button($orange, $blue);
    }
    &.error {
      .error-message {
        display: block;
      }
      .data-text {
        input {
          border-color: $red;
        }
      }
    }
  }
}
