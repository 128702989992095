/* glue: 0.13 hash: 7316fb7eaa */

  .sprite-page_order-place_default {
    background-image: url('img/sprites/page_order.png');
    background-repeat: no-repeat;
    background-size: 220px 167px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_order.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_order@2x.png');
    }
    
    background-position: 0 0;
    width: 55px;
    height: 56px;
  }

  .sprite-page_order-place_active {
    background-image: url('img/sprites/page_order.png');
    background-repeat: no-repeat;
    background-size: 220px 167px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_order.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_order@2x.png');
    }
    
    background-position: -55px 0;
    width: 55px;
    height: 56px;
  }

  .sprite-page_order-pay_default {
    background-image: url('img/sprites/page_order.png');
    background-repeat: no-repeat;
    background-size: 220px 167px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_order.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_order@2x.png');
    }
    
    background-position: -110px 0;
    width: 55px;
    height: 56px;
  }

  .sprite-page_order-pay_active {
    background-image: url('img/sprites/page_order.png');
    background-repeat: no-repeat;
    background-size: 220px 167px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_order.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_order@2x.png');
    }
    
    background-position: 0 -56px;
    width: 55px;
    height: 56px;
  }

  .sprite-page_order-method_default {
    background-image: url('img/sprites/page_order.png');
    background-repeat: no-repeat;
    background-size: 220px 167px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_order.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_order@2x.png');
    }
    
    background-position: -55px -56px;
    width: 55px;
    height: 56px;
  }

  .sprite-page_order-method_active {
    background-image: url('img/sprites/page_order.png');
    background-repeat: no-repeat;
    background-size: 220px 167px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_order.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_order@2x.png');
    }
    
    background-position: -110px -56px;
    width: 55px;
    height: 56px;
  }

  .sprite-page_order-appearance_default {
    background-image: url('img/sprites/page_order.png');
    background-repeat: no-repeat;
    background-size: 220px 167px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_order.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_order@2x.png');
    }
    
    background-position: -165px 0;
    width: 55px;
    height: 56px;
  }

  .sprite-page_order-appearance_active {
    background-image: url('img/sprites/page_order.png');
    background-repeat: no-repeat;
    background-size: 220px 167px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_order.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_order@2x.png');
    }
    
    background-position: -165px -56px;
    width: 55px;
    height: 56px;
  }

  .sprite-page_order-contact_default {
    background-image: url('img/sprites/page_order.png');
    background-repeat: no-repeat;
    background-size: 220px 167px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_order.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_order@2x.png');
    }
    
    background-position: 0 -112px;
    width: 55px;
    height: 55px;
  }

  .sprite-page_order-contact_active {
    background-image: url('img/sprites/page_order.png');
    background-repeat: no-repeat;
    background-size: 220px 167px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_order.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_order@2x.png');
    }
    
    background-position: -55px -112px;
    width: 55px;
    height: 55px;
  }
