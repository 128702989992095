.page.product_template_change {
  ul {
    padding: 10px 0 10px 40px;
  }
  .spoiler {
    color: $blue;
    border-bottom: 1px dashed $blue;
    cursor: pointer;
  }
  @include chexbox_style();
}