.page_add_photo {
  padding-bottom: 50px;
  .status_download_pannel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    li {
      margin: 0 10px;
      font-family: $c55;
      text-transform: uppercase;
      color: $grey_font_light;
      &.active { color: $orange; }
      &.status_icon {
        @extend .sprite-sprite_images-status_download_default;
        &.active { @extend .sprite-sprite_images-status_download_active; }
      }
    }
  }
  @media all and (max-width: 555px) {
    .status_download_pannel { display: none; }
  }
  .editor_panel {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16px;
    .tabs_parameters {
      width: 1025px;
      .tabs {
        display: flex;
        .tab {
          position: relative;
          font-size: 16px;
          padding: 12px 0;
          border: 4px solid transparent;
          border-bottom: none;
          border-radius: 3px;
          margin-bottom: -4px;
          cursor: pointer;
          flex-grow: 1;
          text-align: center;
          .close {
            @include close(5px,5px,$grey_font_light);
            width: 13px;
            height: 13px;
            &::before,
            &::after {
              width: 13px;
              top: 6px;
            }
          }
          &.active {
            border-color: $grey_back;
            &::before {
              content: '';
              position: absolute;
              display: block;
              width: 100%;
              height: 4px;
              background: #fff;
              bottom: 0;
              left: 0;
            }
            .close {
              &::before,
              &::after { background: $grey_font; }
            }
            .format_title {
              color: $grey_font;
              border-bottom-color: transparent;
            }
            .plus {
              &::before,
              &::after { background: $grey_font; }
            }
          }
          .format_title {
            display: inline-block;
            font-size: 16px;
            color: $grey_font_light;
            border-bottom: 1px dashed $grey_font_light;
          }
          &.plus {
            text-align: left;
            display: flex;
            justify-content: center;
            .format_title { text-transform: uppercase; }
            @media all and (max-width: 767px) {
              .format_title { display: none; }
            }
            .plus {
              display: block;
              width: 15px;
              height: 15px;
              position: relative;
              margin-right: 10px;
              &::before,
              &::after {
                content: '';
                position: absolute;
                display: block;
                width: 15px;
                height: 2px;
                background: $grey_font_light;
                top: 8px;
              }
              &::after { transform: rotate(90deg); }
            }
          }
        }
      }
      .all_parameters {
        width: 100%;
        .parameters {
          width: 100%;
          border: 4px solid $grey_back;
          padding: 29px 8px;
          overflow: hidden;
          form {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            .select_all {
              display: flex;
              justify-content: center;
              @include chexbox_style();
              label { font-family: $c45; }
            }
            .select_all,
            .select2-container { width: 19% !important; }
            .apply_changes {
              @include button($grey_font_light, $grey_font_light);
              padding: 10px 0;
              width: 19%;
              font-size: 14px;
              font-family: $c55;
              &.active { background: $blue; }
            }
          }
          .tabs_photo_size_block {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .photo_size_cell {
              position: relative;
              width: 10.5%;
              border-radius: 50%;
              background: $grey_back;
              margin: 0 1% 23px;
              &:hover .order { opacity: 1; }
              .photo_size_cell_text {
                box-sizing: content-box;
                height: 0;
                padding: 50% 0;
                display: flex;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                p {
                  width: 100%;
                  font-family: $c35;
                  font-size: 15px;
                  text-align: center;
                }
                h4 {
                  width: 100%;
                  font-size: 20px;
                  color: $green;
                  text-align: center;
                }
              }
              .order {
                position: absolute;
                top: 0;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 100%;
                height: 100%;
                background: $orange;
                font-size: 15px;
                text-transform: lowercase;
                color: #fff;
                opacity: 0;
                transition: opacity linear 0.1s;
              }
            }
            @media (min-width: 480px) and (max-width: 767px) {
              .photo_size_cell { width: 23%; }
            }
            @media all and (max-width: 479px) {
              .photo_size_cell { width: 31%; }
            }
          }
        }
      }
    }
    .info_and_action {
      width: 195px;
      .price_block {
        background: $grey_back;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 0;
        font-size: 16px;
        .price {
          display: inline-block;
          margin-left: 5px;
          color: $green;
          .cost { color: inherit; }
        }
      }
      .to_cart {
        @include button($orange,$orange_hover);
        &.inactive {
          background: $grey_font_light;
          &:hover { background: $grey_font_light; }
        }
        width: 100%;
        border-radius: 0;
        font-family: $c55;
        font-size: 20px;
      }
    }
  }
  .coupons {
    display: flex;
    align-items: center;
    color: $orange;
    font-family: $c35;
    font-size: 15px;
    margin-bottom: 16px;
    .icon-coupon {
      @extend .sprite-sprite_images-coupon;
      display: inline-block;
      margin-right: 10px;
    }
  }
  .photo_list_pannel {
    width: 100%;
    .photo_list_block {
      width: 100%;
      display: grid;
      grid-gap: 5px;
      grid-template-columns: repeat(6, 1fr);
      .photo_list_item {
        position: relative;
        margin-bottom: 15px;
        padding: 0 5px 5px 5px;
        border-radius: 3px;
        border: 1px solid transparent;
        display: grid;
        cursor: pointer;
        &:hover {
          border: 1px solid $grey_font;
        }
        &.selected {
          border: 1px solid $grey_font;
          .instruments {
            visibility: hidden;
          }
          .img_wrapper {
            position: relative;
            &:before {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              @extend .sprite-sprite_images-photo_print_select;
            }
            img {
              opacity: 0.3;
            }
            .icon {
              display: none;
            }
          }
        }
        &.confirm_delete {
          .instruments {
            visibility: hidden;
          }
          .img_wrapper {
            img {
              opacity: 0.3;
            }
            .icon {
              visibility: hidden;
            }
          }
          .confirm-delete {
            display: flex;
          }
        }
        &.correction-off {
          .photo-info {
            .correction {
              display: block;
            }
          }
        }
        &.drop_area {
          flex-grow: 1;
          border: 2px dashed $blue;
          margin-top: 6px;
          cursor: pointer;
          &:hover { background: $grey_back; }
          &.have_items { flex-grow: 0; }
          &.active {
            &::before,
            &::after { display: none; }
          }
          a {
            display: flex;
            width: 100%;
            min-height: 140px;
            height: 100%;
          }
          p {
            display: block;
            width: 220px;
            margin: auto;
            text-align: center;
            font-size: 15px;
            color: $blue;
            &.with_item_text { width: 110px; }
            .link {
              color: $blue_hover;
              display: inline-block;
              text-decoration: underline;
              &:hover { text-decoration: none; }
            }
            .icon {
              display: block;
              @extend .sprite-sprite_images-drop_area;
              margin: 0 auto 10px;
            }
          }
        }
        .instruments {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .edit_photo, .delete_photo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            &:before {
              content: '';
              display: block;
            }
          }
          .edit_photo {
            &:before {
              @extend .sprite-sprite_images-photo_print_edit;
            }
          }
          .delete_photo {
            background: none;
            border: none;
            outline: none;
            cursor: pointer;
            &:before {
              @extend .sprite-sprite_images-photo_print_delete;
            }
          }
        }
        .img_wrapper {
          height: 128px;
          width: 180px;
          margin: 0 auto 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          img {
            max-width: 100%;
            max-height: 100%;
            border: 1px solid $grey_font;
          }
          .icon {
            cursor: pointer;
            position: absolute;
            left: 8px;
            bottom: 14px;
            z-index: 30;
            &.icon_edited { @extend .sprite-sprite_images-quality-warning; }
            &.icon_error { @extend .sprite-sprite_images-warning; }
            &.hidden { display: none; }
            &.shown {
              top: 0;
              left: 0;
              background: transparent;
              width: 100%;
              height: 100%;
              .description {
                display: block;
              }
            }
          }
          .description {
            display: none;
            padding: 10px;
            font-size: 12px;
            height: 100%;
            &.edited {
              background: $green;
              color: #fff;
            }
            &.quality-warning {
              background: $yellow;
            }
          }
        }
        .close {
          position: absolute;
          top: 12px;
          right: 13px;
          width: 17px;
          height: 17px;
          background: #fff;
          z-index: 5;
          border: 1px solid lightgrey;
          border-radius: 2px;
          cursor: pointer;
          display: none;
          &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            width: 15px;
            height: 2px;
            background: $grey_font;
            top: 6px;
            left: 0;
          }
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
        }
        .confirm-delete {
          display: none;
          @include confirm_delete(50px,160px,75px);
          .delete {
            background: $blue;
            color: white;
          }
        }
        .photo-info {
          display: flex;
          justify-content: space-between;
          p {
            font-family: $c35;
            font-size: 13px;
          }
          .correction {
            display: none;
          }
          .count {
            label {
              font-family: $c35;
              display: grid;
              font-size: 13px;
              input {
                height: 24px;
                font-size: 14px;
                background: #fff;
                background-blend-mode: color;
                width: 54px;
                border: 1px solid $grey_font;
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .page_add_photo {
    .editor_panel {
      .tabs_parameters { width: 795px; }
    }
  }
}
@media (min-width: 767px) and (max-width: 1023px) {
  .page_add_photo {
    .editor_panel {
      .tabs_parameters {
        width: 100%;
        margin-bottom: 20px;
        .all_parameters {
          .parameters {
            padding: 29px 8px;
            form {
              .select_all,
              .select2-container {
                width: 23% !important;
                margin-bottom: 15px;
              }
              .apply_changes { width: 100%; }
            }
          }
        }
      }
      .info_and_action {
        width: 100%;
        .price_block { font-size: 20px; }
      }
    }
  }
}
@media all and (max-width: 767px) {
  .page_add_photo {
    .editor_panel {
      .tabs_parameters {
        width: 100%;
        margin-bottom: 20px;
        .all_parameters {
          .parameters {
            padding: 29px 8px;
            form {
              .select_all,
              .select2-container {
                width: 100% !important;
                margin-bottom: 15px;
                justify-content: flex-start;
              }
              .apply_changes { width: 100%; }
            }
          }
        }
      }
      .info_and_action {
        width: 100%;
        .price_block { font-size: 20px; }
      }
    }
    .photo_list_pannel {
      .photo_list_block {
        grid-template-columns: repeat(3, 1fr);
        .photo_list_item {
          .img_wrapper {
            width: 134px;
            .icon {
              bottom: 23px;
              .description {
                padding: 5px 10px;
                font-size: 11px;
              }
            }
          }
          .photo-info {
            .count {
              label {
                display: grid;
              }
            }
          }
        }
      }
    }
  }
}
@include small_desktop {
  .page_add_photo {
    .photo_list_pannel {
      .photo_list_block {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}
@include tablet {
  .page_add_photo {
    .photo_list_pannel {
      .photo_list_block {
        grid-template-columns: repeat(4, 1fr);
        .photo_list_item {
          &:hover {
            border: 1px solid transparent;
          }
          &.selected {
            &:hover {
              border: 1px solid $grey_font;
            }
          }
          .img_wrapper {
            width: 168px;
          }
        }
      }
    }
  }
}
@include big_phone {
  .page_add_photo {
    .photo_list_pannel {
      .photo_list_block {
        grid-template-columns: repeat(3, 1fr);
        .photo_list_item {
          .img_wrapper {
            width: 134px;
            .icon {
              bottom: 23px;
              .description {
                padding: 5px 10px;
                font-size: 11px;
              }
            }
          }
          .photo-info {
            .count {
              label {
                display: grid;
              }
            }
          }
        }
      }
    }
  }
}
@include phone {
  .page_add_photo {
    .photo_list_pannel {
      .photo_list_block {
        grid-template-columns: repeat(2, 1fr);
        .photo_list_item.drop_area {
          grid-column: 1/3;
        }
      }
    }
  }
}