.comparison_table {
  margin-top: 40px;
  width: 100%;
  position: relative;
  .table_wrapper {
    width: 100%;
    overflow: hidden;
  }
  .slide_table {
    position: relative;
    top: 0;
    left: 0;
    transition: left 0.3s;
    border-collapse: collapse;
    .product {
      text-align: center;
      vertical-align: baseline;
      .product_image {
        margin: auto;
      }
      .product_name {
        display: block;
        margin-top: 10px;
        font-family: $c45;
        font-size: 12px;
        color: $grey_font;
        text-transform: uppercase;
      }
      .product_price {
        margin-top: 12px;
        display: block;
        font-family: $c45;
        font-size: 14.4px;
        color: $green;
        &:after {
          content: ' руб.';
        }
        &.min {
          &:before {
            content: 'от ';
          }
        }
      }
      .create_product {
        margin-top: 10px;
        margin-bottom: 21px;
        @include button($orange,$orange_hover);
        font-size: 13px;
        padding: 6px 12px;
        letter-spacing: 0.5px
      }
    }
    .property {
      border-top: 1px solid #d5dbde;
      font-family: $c35;
      font-size: 15px;
      color: $grey_font;
      padding: 42px 16px 24px;
      min-width: 190px;
      @include big_phone {
        min-width: 150px;
      }
    }
  }
  .slide_button {
    position: absolute;
    top: 40px;
    background: $blue;
    color: white;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.4s;
    &:before, &:after {
      content: '';
      display: block;
      width: 7px;
      height: 1px;
      background: white;
      position: absolute;
      left: 12px
    }
    &.left {
      left: 0;
      &:before {
        transform-origin: 0 50%;
        transform: rotate(45deg);
      }
      &:after {
        transform-origin: 0 50%;
        transform: rotate(-45deg);
      }
    }
    &.right {
      right: 0;
      &:before {
        transform-origin: 100% 50%;
        transform: rotate(45deg);
      }
      &:after {
        transform-origin: 100% 50%;
        transform: rotate(-45deg);
      }
    }
    &:hover {
      filter: brightness(120%);
    }
  }
  .row_hint_set {
    .row_hint {
      position: absolute;
      left: 16px;
      font-family: $c45;
      font-size: 12px;
      color: $grey_font_light;
      text-transform: uppercase;
      margin-top: 18px;
      transition: opacity 0.4s;
      display: flex;
      align-items: center;
      .popup_show_button {
        background: #f0f4f6;
        border: none;
        margin-left: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 0.3s;
        &.active {
          background: $blue;
          color: white;
        }
      }
      .popup {
        background: #e8f3ff;
        z-index: 100;
        position: absolute;
        width: 470px;
        right: -500px;
        top: -50px;
        padding: 20px 35px 0 20px;
        border-radius: 2px;
        box-shadow: 0 0 20px rgba(50,50,50, 0.2);
        transition: opacity 0.3s;
        &.invisible {
          transition: opacity 0.3s, visibility 0s ease 0.3s;
        }
        .arrow {
          width: 34px;
          height: 60px;
          position: absolute;
          left: -34px;
          top: 30px;
          overflow: hidden;
          &:after {
            content: '';
            display: block;
            width: 34px;
            height: 34px;
            background: #e8f3ff;
            margin-top: 14px;
            margin-left: 17px;
            transform: rotate(45deg);
            box-shadow: 0 0 20px rgba(50,50,50, 0.2);
          }
        }
        .close {
          @include close(8px, 8px, $grey_font);
          &:before, &:after {
            height: 1px;
            width: 15px;
          }
          &:hover {
            &:before, &:after {
              background: $grey_font_light;
            }
          }
        }
        p {
          font-family: $c35;
          font-size: 15px;
          color: $grey_font;
          text-transform: none;
          margin: 0 0 18px 0;
          line-height: 15px;
          b {
            font-family: $c55;
          }
        }
        @include big_phone() {
          position: fixed;
          top: 142px;
          left: 0;
          width: calc(100% - 40px);
          max-height: calc(100% - 142px - 20px);
          overflow-y: scroll;
          margin-left: 20px;
          border-radius: 0;
          box-shadow: 0 0 20px 142px rgba(50,50,50, 0.2);
          &::-webkit-scrollbar {
            width: 3px;
            background: $grey_back;
          }
          &::-webkit-scrollbar-thumb {
            display: block;
            background: $grey_font_light;
          }
        }
      }
    }
  }
  .invisible {
    opacity: 0;
  }
}