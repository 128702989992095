.page_promo {
  &.page_books {
    @for $i from 1 through 3 {
      .slide_cell:nth-of-type(#{$i}) {
        background: url(/static/img/page_books/page_book_slide_#{$i}.jpg) no-repeat;
      }
    }
  }
  &.page_calendars {
    @for $i from 1 through 3 {
      .slide_cell:nth-of-type(#{$i}) {
        background: url(/static/img/page_books/page_calendars_slide_#{$i}.jpg) no-repeat;
      }
    }
  }
  &.page_canvas {
    @for $i from 1 through 3 {
      .slide_cell:nth-of-type(#{$i}) {
        background: url(/static/img/page_books/page_canvas_slide_#{$i}.jpg) no-repeat;
      }
    }
  }
  .header_slider {
    width: 100%;
    background: #e9edf0;
    margin-bottom: 80px;
    max-height: 250px;
    overflow-y: hidden;
    .slick-dots {
      right: 10px;
      left: auto;
      bottom: 10px;
    }
    .slide_cell {
      position: relative;
      height: 250px;
      width: 33%;
      outline: none;
      .slide_title {
        position: absolute;
        font-family: $germ;
        font-size: 36px;
        text-transform: uppercase;
        left: 20px;
        bottom: 40px;
        &::before {
          content: '';
          display: block;
          width: 30px;
          height: 3px;
          background: $orange;
        }
        span {
          font-family: $c45;
          font-size: 18px;
        }
      }
    }
    @media all and (max-width: 767px) {
      .slide_cell {
        //height: 170px;
        &:nth-of-type(1) { background-position: left !important; }
        &:nth-of-type(2) { background-position: center !important; }
        &:nth-of-type(3) { background-position: right !important; }
        .slide_title {
          left: 30px;
          bottom: 30px;
          font-size: 30px;
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    .header_slider { margin-bottom: 30px; }
  }
  .page_promo_tabs_container {
    width: 100%;
    // margin-bottom: 70px;
    .tabs_label_block {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      // max-width: 750px;
      margin: 0 auto 35px;
      .tab {
        flex-grow: 1;
        text-align: center;
        text-transform: uppercase;
        border: 4px solid transparent;
        color: $grey_font_light;
        padding: 10px 0;
        cursor: pointer;
        font-size: 14px;
        font-family: $c55;
        &.choice_tab {
          color: $orange;
          &.active { color: $orange; }
        }
        &.active {
          border-color: lightgrey;
          color: $grey_font;
        }
      }
    }
    @media all and (max-width: 600px) {
      .tabs_label_block .tab { width: 100%; }
    }
    .tabs_content_container {
      .tab_content_block {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;

        &.more_elements {
          .tab_content_cell:nth-child(n+5) {
            display: none;
          }

          &.show {
            .tab_content_cell:nth-child(n+5) {
              display: block;
            }
          }
        }

        &.row_3 {
          .tab_content_cell {
            width: 33.3%;
          }
        }

        .tab_content_cell {
          display: block;
          width: 25%;
          text-align: center;
          font-family: $c35;
          font-size: 14px;
          margin-bottom: 20px;

          &.active {
            > span { color: $orange; }
            .icon::before { display: block; }
          }
          &:hover {
            > span { color: $orange; }
            .icon::before { display: block; }
          }
          .icon {
            margin: 0 auto 10px;
            &::before { content: ''; }
          }
          > span {
            display: block;
            color: $grey_font_light;
            margin-bottom: 3px;
            &.price {
              color: $green;
              font-size: 14px;
              border: none;
            }
          }
        }
        .view_more {
          display: none;
          width: 100%;
          order: 10;
          font-family: $c35;
          font-size: 14px;
          color: $blue_hover;
          text-decoration: underline;
          text-align: center;
          margin-bottom: 10px;
        }
        .button_create { display: none; }
        &.choice_tab_content_block {
          .choice_form {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            .choice_cell {
              flex-grow: 1;
              margin: 0 10px 20px;
              input { display: none; }
              input:checked + label {
                background: #fbe6d6;
                box-shadow: 0 0 0 3px #fbe6d6;
                text-decoration: none;
                &::before,
                &::after { display: block; }
              }
              label {
                position: relative;
                display: inline-block;
                font-family: $c35;
                font-size: 15px;
                margin-bottom: 8px;
                text-decoration: underline;
                padding-right: 20px;
                cursor: pointer;
                &:hover { text-decoration: none; }
                &::before,
                &::after {
                  content: '';
                  position: absolute;
                  display: none;
                  width: 10px;
                  height: 2px;
                  top: 9px;
                  right: 0;
                  background: $grey_font;
                }
                &::before { transform: rotate(45deg); }
                &::after { transform: rotate(-45deg); }
              }
            }
          }
        }
      }
    }
    @media all and (max-width: 1023px) {
      .tabs_content_container {
        .tab_content_block {
          .view_more { display: block; }
          .button_create { display: block; }
        }
      }
    }
    @media all and (max-width: 600px) {
      .tabs_content_container {
        margin-bottom: 20px;
        border-bottom: 1px solid $grey_font_light;
        padding-bottom: 20px;
        .tab_content_block {
          .tab_content_cell { width: 50%; }

          &.row_3 {
            .tab_content_cell {
              width: 50%;
            }
          }
        }
      }
    }
  }
  .main_information_container {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto 40px;
    .product_detail_gallery {
      max-width: 580px;
      padding-right: 0;
    }
    .product_detail_info {
      width: 50%;
      max-width: 625px;
      padding: 0 0 0 20px;
      .info_header {
         max-width: 100%;
        .product_title {
          @include after_line($orange,10px 0 15px);
        }
      }
      .info_main {
        .price {
          padding-bottom: 15px;
          margin-bottom: 15px;
        }
      }
      .description_text {
        font-size: 15px;
        line-height: 1.5;
        margin: 15px 0 25px;

        p { margin-bottom: 10px; }
        ul {
          padding-left: 15px;

          li {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: 5px;
              left: -15px;
              width: 5px;
              height: 5px;
              background: $orange;
              border-radius: 50%;
            }
          }
        }
      }
      .promo_info {
        display: flex;
        margin-bottom: 25px;
        .promo_info_cell {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          &.quantity_spread .icon {
            @extend .sprite-sprite_images-quantity_spread;
          }
          &.spread .icon {
            @extend .sprite-sprite_images-spread;
          }
          &.paper .icon {
            @extend .sprite-sprite_images-paper;
          }
          .icon {
            display: inline-block;
            margin-right: 10px;
          }
          .text {
            font-family: $c55;
            font-size: 14px;
          }
        }
      }
    }
  }
  @media all and (max-width: 1279px) {
    .main_information_container {
      .info_header {
        .product_title {
          @include after_line($orange,10px 0 15px);
        }
      }
      .product_detail_info {
        // .info_header { position: static; }
        .info_main {
          .promo_info {
            flex-direction: column;
            .promo_info_cell { justify-content: flex-start; }
          }
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    .main_information_container {
      // padding: 60px 0 0;
      .product_detail_info {
        max-width: 100%;
        width: 100%;
        padding: 0;
      }
    }
  }
  .button_create {
    @include button($orange,$orange_hover);
    width: 100%;
    display: block;
    max-width: 360px;
    margin: 0 auto 30px;
  }
  @media all and (max-width: 767px) {
    .button_create {
      margin: 0 auto 30px;
      width: 100%;
    }
  }
  .next {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto 85px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: calc(100% - 300px);
      left: 13%;
      top: 130px;
      height: 5px;
      background: url(/static/img/page_books/dots.png) repeat center;
    }
    .next_title {
      width: 100%;
      font-family: $germ;
      font-size: 25px;
      text-transform: uppercase;
      text-align: center;
      @include after_line($orange,15px auto 55px);
    }
    .next_cell {
      flex-basis: 250px;
      text-align: center;
      margin: 0 auto 30px;
      .next_cell_icon {
        margin: 0 auto 35px;
        position: relative;
        box-shadow: -20px 0 0 0 #fff, 20px 0 0 0 #fff;
      }
      .next_cell_title {
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      .next_cell_text {
        font-family: $c35;
        font-size: 15px;
        line-height: 1.5;
      }
    }
    @for $i from 1 through 3 {
      .next_cell:nth-of-type(#{$i}) .next_cell_icon {
        @extend .sprite-sprite_images-next_cell_#{$i};
      }
    }
  }
  @media all and (max-width: 786px) {
    .next::before { display: none; }
  }
  @media all and (max-width: 480px) {
    .next {
      margin: 0 auto 30px;
      .next_title::after { margin: 15px auto 25px; }
      .next_cell .next_cell_icon { margin: 0 auto 10px; }
    }
  }
  .reasons {
    width: 100%;
    margin: 0 auto 60px;
    padding-bottom: 90px;
    overflow: hidden;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 40px;
      display: block;
      max-width: calc(100% - 240px);
      margin: 0 auto;
      border: 15px solid #e8ecee;
      height: calc(100% - 105px);
    }
    .reasons_title {
      position: relative;
      z-index: 2;
      display: block;
      max-width: 320px;
      margin: 0 auto;
      background: #fff;
      font-family: $germ;
      font-size: 25px;
      text-transform: uppercase;
      text-align: center;
      @include after_line($orange,15px auto 55px);
      span {
        font-family: $c55;
        font-size: 20px;
      }
    }
    .reasons_slide_block {
      position: relative;
      padding: 0 20px;
      .slick-arrow {
        display: block;
        width: 15px;
        height: 15px;
        background: transparent;
        text-indent: -9999px;
        border: none;
        border-top: 3px solid $grey_font_light;
        border-left: 3px solid $grey_font_light;
        transform: rotate(-45deg);
        cursor: pointer;
        position: absolute;
        left: 4px;
        top: calc(50% - 15px);
        &:hover { border-color: $blue; }
        &.slick-next {
          transform: rotate(135deg);
          right: 4px;
          left: auto;
        }
      }
      @for $i from 1 through 5 {
        .reasons_slide_cell_#{$i} {
          background-image: url(/static/img/page_books/reasons_#{$i}.png);
        }
      }
      .reasons_slide_cell {
        background-position: top left;
        background-repeat: no-repeat;
        padding: 10px 30px;
        min-height: 120px;
        outline: none;
        .reasons_slide_title {
          font-size: 16px;
          margin-bottom: 10px;
          text-transform: uppercase;
        }
        .reasons_slide_text {
          font-family: $c35;
          font-size: 15px;
          line-height: 1.5;
        }
      }
      @media all and (max-width: 767px) {
        .reasons_slide_cell {
          background-position: top 0 left 10%;
          .reasons_slide_title { margin: 0 15% 10px; }
          .reasons_slide_text { margin: 0 15%; }
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    .reasons::before { max-width: 100%; }
  }
  @media all and (max-width: 480px) {
    .reasons {
      margin: 0 auto;
      padding-bottom: 75px;
      &::before {
        top: 90px;
        height: calc(100% - 180px);
      }
      .reasons_slide_block .reasons_slide_cell { padding: 10px 0 25px; }
    }
  }
  .create_container {
    width: 100%;
    margin: 0 auto 60px;
    .create_title {
      background: #fff;
      font-family: $germ;
      font-size: 25px;
      text-transform: uppercase;
      text-align: center;
      @include after_line($orange,15px auto 55px);
    }
    .create_block {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 auto 40px;
      .create_cell {
        display: block;
        width: 23%;
        border: 1px solid lightgrey;
        img {
          width: 100%;
          height: auto;
        }
        .create_cell_text {
          width: 100%;
          padding: 20px 15px;
          .create_cell_title {
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          .create_cell_description {
            font-size: 22px;
            color: $green;
            span { color: inherit; }
          }
        }
      }
      @media all and (max-width: 1023px) {
        .create_cell {
          &:last-of-type { display: none; }
          width: 32%;
        }
      }
      @media all and (max-width: 570px) {
        .create_cell {
          &:last-of-type { display: block; }
          margin-bottom: 5px;
          width: 100%;
          img {
            width: 30%;
            min-width: 105px;
            margin-right: 15px;
            float: left;
          }
          .create_cell_text { padding: 15px; }
        }
      }
    }
    .view_all_button {
      @include button($blue,$blue_hover);
      display: block;
      max-width: 360px;
      margin: 0 auto;
    }
  }
  @media all and (max-width: 480px) {
    .create_container {
      .create_container_title {
        font-size: 20px;
        &::after { margin: 0 auto 20px; }
      }
    }
  }

  .more_items_block {
    width: 100%;
    display: flex;
  }

  .more_link {
    text-align: center;
    margin: 0 auto 30px;
    cursor: pointer;
    font-family: $c35;
    color: $blue;
    font-size: 14px;
    border-bottom: 1px dashed $blue;
    display: none;

    &:hover {
      border-color: transparent;
    }
  }

  .more_elements .more_link { display: block; }

  .mobile_show {
    display: none;
    @media all and (max-width: 600px) {
      display: block;
    }
  }
}
