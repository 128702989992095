#site_theme {
  background: url(/static/img/site_theme/01_june_pattern.png);
  header {
    .top_header_container { background: transparent; }
  }
  main {
    background: transparent;
    .subscription_container { background: #fff; }
    .main_text_wrapper { background: #fff; }
    .product_detail_container { background: #fff; }
    .photoprint_block { background: #fff; }
    .page {
      padding-top: 15px;
      background: #fff;
    }
  }
  .order.labs { background: #fff; }
}

@media all and (max-width: 1023px) {
  #site_theme {
    background: #fff;
    header {
      .top_header_container { background: #fff; }
    }
  }
}
