#popup {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 10000;
  background: rgba(0,0,0,0.3);
  &.hidden {
    display: none;
  }
  .title_popup {
    font-family: $germ;
    font-weight: bold;
    font-size: 22.27px;
    margin-bottom: 20px;
  }
  .content_popup {
    margin: auto;
    background: white;
    border-radius: 2px;
    position: relative;
    &.hidden {
      display: none;
    }
    .close {
      background: none;
      outline: none;
      border: none;
      padding: 0;
      width: 24px;
      height: 24px;
      position: absolute;
      z-index: 100;
      right: 10px;
      top: 10px;
      cursor: pointer;
      &:before, &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: $grey_font;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
  .content_popup.location {
    display: grid;
    align-content: start;
    padding: 40px 50px;
    width: 564px;
    .input {
      font-family: $germ;
      font-size: 16px;
      border: 1px solid grey;
      padding: 10px;
      margin-bottom: 20px;
      &::placeholder {
        color: $grey_font_light;
      }
      &:focus {
        border: 1px solid $blue;
      }
    }
    .city_form {
      display: none;
    }
    .city_list {
      height: 392px;
      overflow-y: auto;
      display: grid;
      align-content: start;
      grid-gap: 4px;
      &::-webkit-scrollbar {
        width: 3px;
        background: $grey_back;
      }
      &::-webkit-scrollbar-thumb {
        display: block;
        background: $grey_font_light;
      }
      .city {
        font-family: $germ;
        font-size: 16.04px;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        margin-bottom: 10px;
        text-align: left;
        &.invisible {
          visibility: hidden;
        }
        &:hover {
          color: $blue;
        }
      }
    }
  }
  .content_popup.add_review {
    padding: 40px;
    .rating_star {
      @include rating_star;
    }
    .text {
      resize: none;
      width: 500px;
      height: 120px;
      padding: 5px;
      font-family: $c35;
      font-size: 15px;
      border-color: lightgrey;
      margin-bottom: 5px;
      &::placeholder {
        color: $grey_font_light;
      }
      &:focus {
        border-color: $blue;
      }
    }
    .photo {
      display: flex;
      margin-bottom: 15px;
      .file_wrapper {
        position: relative;
        width: 70px;
        height: 70px;
        border: 1px solid lightgrey;
        margin-right: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-of-type {
          margin-right: 0;
        }
        &.loaded {
          border-color: transparent;
          .icon {
            display: none;
          }
        }
        input {
          display: none;
        }
        .icon {
          display: block;
          position: absolute;
          @extend .sprite-sprite_images-add_photo;
        }
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .add {
      @include button($blue, $blue_hover);
    }
    .thanks {
      position: absolute;
      display: grid;
      grid-gap: 20px;
      justify-items: center;
      align-items: center;
      align-content: center;
      background: white;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      .icon {
        @extend .sprite-sprite_images-balloon;
      }
      .caption {
        font-family: $germ;
        font-size: 38px;
        text-transform: uppercase;
        color: $orange;
      }
      .text {
        font-family: $c35;
        font-size: 15px;
        text-align: center;
      }
    }
  }

  @media (hover: none) {
    .content_popup.location {
      .city_list {
        .city {
          &:hover {
            color: unset;
          }
        }
      }
    }
  }
  @include big_phone() {
    .content_popup.location {
      width: 460px;
    }
    .content_popup.add_review {
      .text {
        width: 360px;
      }
    }
  }
  @include phone() {
    .content_popup.location {
      padding: 20px 20px;
      width: 310px;
      .title {
        font-size: 20px;
        margin-bottom: 15px;
      }
      .input {
        padding: 5px;
        margin-bottom: 15px;
      }
      .city_list {
        height: 340px;
        .city {
          margin-bottom: 5px;
        }
      }
    }
    .content_popup.add_review {
      padding: 30px 20px;
      .text {
        width: 236px;
      }
    }
  }
}
