.cart_popup {
  position: absolute;
  right: 0;
  top: 60px;
  z-index: 50;
  padding: 25px 25px 15px;
  background: #fff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -12px;
    right: 20px;
    z-index: -1;
    width: 25px;
    height: 25px;
    background: #fff;
    transform: rotate(45deg);
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 25px;
    background: #fff;
  }
  &.empty {
    display: none;
    width: 295px;
    left: -272px;
    top: 36px;
  }
  &.full {
    display: none;
    top: 36px;
    right: 0;
    left: - 276px;
    width: 420px;
    &::before { right: 140px; }
  }
  .h {
    text-transform: uppercase;
    &.h_empty {
      display: block;
      font-size: 16px;
      text-align: center;
      font-weight: 700;
      span {
        font-weight: 400;
        font-size: 15px;
        text-transform: lowercase;
      }
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: lightgrey;
        margin: 15px 0;
      }
    }
    &.h_full {
      display: block;
      font-size: 20px;
      margin-bottom: 30px;
      font-weight: 700;
    }
  }
  .p_empty {
    font-family: $c35;
    font-size: 15px;
    color: $grey_font_light;
    text-align: center;
    margin-bottom: 15px;
  }
  .items_block {
    width: 100%;
    display: flex;
    .item_cell {
      position: relative;
      display: block;
      &:hover .item_delete { display: block; }
      .img_cart {
        border: 3px solid $grey_font_light;
        width: 70px;
        height: 70px;
        margin-bottom: 10px;
      }
      .item_delete {
        @include close(30%,10px,$grey_font_light);
        display: none;
      }
      .confirm-delete {
        @include confirm_delete(0,160px,75px);
        display: flex;
        .delete { background: $grey_back; }
      }
    }
    &_empty {
      justify-content: space-between;
      .item_cell {
        width: 70px;
        .price {
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
    &_full {
      max-height: 180px;
      flex-wrap: wrap;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
        background: $grey_back;
      }
      &::-webkit-scrollbar-arrow { display: none; }
      &::-webkit-scrollbar-thumb {
        display: block;
        background: $grey_font_light;
      }
      .item_cell {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
        .description {
          margin-left: 15px;
          .title {
            display: block;
            text-transform: uppercase;
            font-size: 16px;
            margin-bottom: 3px;
            font-weight: 700;
          }
          .types {
            display: block;
            padding-right: 30px;
            font-family: $c35;
            font-size: 15px;
            color: $grey_font_light;
            margin-bottom: 5px;
          }
          .item_total {
            @extend .title;
            .item_count {
              &::after {
                content: 'x';
                color: $grey_font_light;
                padding: 0 5px;
                text-transform: lowercase;
              }
            }
            .item_price { color: green; }
          }
        }
      }
    }
  }
  .total_cost {
    display: block;
    width: 100%;
    margin: 30px 0;
    font-family: $c35;
    font-size: 15px;
    text-transform: lowercase;
    span {
      font-size: 16px;
      text-transform: uppercase;
      padding-right: 3px;
      &.cost { color: green; }
    }
  }
  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    a {
      @include button($orange,$orange_hover);
      padding: 10px 30px;
      transition: all linear 0.2s;
      &.view {
        background: $grey_back;
        color: $grey_font;
        &:hover {
          background: $blue;
          color: #fff;
        }
      }
    }
  }
}
.cart_popup {
  @media all and (max-width: 1023px) {
    display: none !important;
  }
}
