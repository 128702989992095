.page_order,
.page_order_complete,
.page_order_fail {
  max-width: 1100px;
  width: 100%;
  padding: 0 10px;

  @media all and (max-width: 1279px) {
    max-width: 1000px;
  }
  @media all and (max-width: 1023px) {
    max-width: 100%;
  }

  .errorlist {
    width: auto;
    margin: 0 !important;
    padding: 0 !important;
  }

  #order-form-block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    @media all and (max-width: 1023px) {
      justify-content: center;
    }
  }
  h1 {
    font-size: 25px;
    &::after {
      margin: 15px 0 45px;
    }

    @media all and (max-width: 479px) {
      &::after {
        margin: 15px 0;
      }
    }
  }

  .page_order_form {
    width: 735px;

    @media (min-width: 1024px) and (max-width: 1279px) {
      width: 645px;
    }
    @media all and (max-width: 1023px) {
      width: 100%;
      padding-bottom: 20px;
    }
  }

  .order_page_h2 {
    position: relative;
    font-size: 20px;
    padding-left: 105px;
    text-transform: uppercase;
    width: 100%;
    max-width: 640px;
    @include after_line($orange, 15px 0 25px);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
    }

    &.appearance::after {
      display: none;
    }

    @each $i in contact, place, method, pay, appearance {
      &.#{$i} {
        &::before {
          @extend .sprite-page_order-#{$i}_default;
        }
      }
    }

    @media all and (max-width: 1023px) {
      max-width: 665px;
    }

    @media all and (max-width: 767px) {
      padding-left: 0;
      max-width: 100%;

      &::before {
        display: none;
      }
    }
  }

  .select2 {
    display: block;
    width: 100% !important;
    max-width: 400px;
    margin: 0 auto 40px;
  }

  .order-contact,
  .order-delivery {
    margin: 0 auto 10px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @media all and (max-width: 1023px) {
      align-items: center;
    }

    &.active {
      .order-form {
        border-color: #95e8c0;
      }

      @each $i in contact, place, method, pay, appearance {
        .order_page_h2.#{$i} {
          &::before {
            @extend .sprite-page_order-#{$i}_active;
          }
        }
      }
    }

    .order-form {
      width: 100%;
      max-width: 615px;
      border-left: 4px dotted lightgrey;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-bottom: 40px;

      @media all and (max-width: 767px) {
        border-left: none;
        max-width: 100%;
        padding-bottom: 20px;
      }
    }

    .order-field-row {
      width: 100%;
      max-width: 580px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 15px;
      font-family: $c35;
      color: $grey_font_light;

      @media all and (max-width: 479px) {
        justify-content: flex-start;
      }
    }

    input:not([type="radio"]) {
      display: block;
      width: 100%;
      max-width: 410px;
      border: 1px solid lightgrey;
      padding: 10px;
      font-family: $c35;
      font-size: 14px;
      margin-left: 20px;
      @include placeholder($grey_font_light);

      @media all and (max-width: 767px) {
        margin-left: 10px;
      }

      @media all and (max-width: 479px) {
        margin: 5px 0;
      }
    }

    .select2 {
      max-width: 410px;
      margin: 0;
      margin-left: 20px;
      @media all and (max-width: 767px) {
        margin-left: 10px;
      }

      @media all and (max-width: 479px) {
        margin: 5px 0;
      }
    }

    .js-delivery {
      max-width: 535px;
      margin: 0;

      @media all and (max-width: 767px) {
        max-width: 100%;
      }

      input {
        margin-left: 0;
      }

      .select2 {
        max-width: 100%;
        margin-left: 0;
      }
    }

    .payment_block {
      width: 100%;
      padding-left: 110px;

      @media all and (max-width: 1023px) {
        padding-left: 90px;
      }

      @media all and (max-width: 767px) {
        padding-left: 14px;
      }

      .row {
        margin-bottom: 10px;
      }

      .radio {
        display: none;
      }

      .label {
        position: relative;
        font-family: $c35;
        color: $grey_font_light;
        padding-left: 35px;
        cursor: pointer;

        &::before {
          content: '';
          position: absolute;
          top: 6px;
          left: 0;
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 2px;
          background: $grey_font_light;
        }
      }

      .radio:checked + .label {
        color: $orange;

        &::before {
          background: $orange;
        }
      }

      .error_text {
        font-family: $c35;
        font-size: 14px;
        text-align: center;
        margin-top: 15px;
      }
    }
  }

  .order-payment {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @media all and (max-width: 1023px) {
      max-width: 665px;
      margin: 0 auto;
    }

    .payment_buttons {
      width: 100%;
      max-width: 535px;

      @media all and (max-width: 1023px) {
        max-width: 100%;
      }
    }

    input:not([type="radio"]) {
      @include button($orange, $orange_hover);
      width: auto;
      padding: 15px 50px;
      margin: 0 auto 40px;
      font-family: $c45;

      @media all and (max-width: 1023px) {
        margin: 0 auto 20px;
      }

      @media all and (max-width: 767px) {
        max-width: 100%;
        width: 100%;
      }

      @media all and (max-width: 479px) {
        font-size: 14px;
        padding: 15px;
      }
    }

    .description {
      text-align: center;
      font-size: 14px;
      margin: auto;

      a {
        color: $blue_hover;
      }

      @media all and (max-width: 767px) {
        br {
          display: none;
        }
      }
    }
  }

  .total_payment {
    margin-top: 10px;

    @media all and (max-width: 1023px) {
      margin-bottom: 30px;
      order: 3;
    }

    @media all and (max-width: 767px) {
      width: 100%;
    }

    article {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .sticky {
      margin-top: 10px;
      position: fixed;
    }
    .stop {
      margin-top: 10px;
      position: relative;
    }

    .total_payment_title {
      width: 100%;
      font-size: 20px;
      text-transform: uppercase;
      @include after_line($orange, 10px 0 30px);
    }

    .product_block {
      width: 100%;
      max-height: 190px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 10px;
        background: #fff;
      }
      &::-webkit-scrollbar-arrow {
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        display: block;
        background: $grey_font_light;
      }

      .rub {
        padding-left: 3px;
      }
    }

    .product_cell {
      margin-bottom: 25px;
      padding-right: 20px;

      @media all and (max-width: 479px) {
        padding-right: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        font-size: 15px;
        text-decoration: underline;
      }

      .description {
        font-size: 15px;
        color: $grey_font_light;
        margin-bottom: 5px;

        span {
          color: inherit;
        }
      }
    }

    .total_detail {
      &::before {
        content: '';
        display: block;
        margin: 25px 0;
        width: 100%;
        height: 1px;
        background: lightgrey;
      }
    }

    .final_price {
      margin: 35px auto 40px;
      text-align: center;

      .title {
        font-size: 16px;
        text-transform: uppercase;
      }

      .coast {
        font-size: 40px;
        color: $green;

        span {
          color: inherit;
        }
      }
    }
  }

  .submit:disabled {
    opacity: 0.4;
    &:hover {
      background: $orange !important;
    }
  }
  .order_disable {
    font-family: $c45;
    font-size: 15px;
    color: red;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    .order_min_sum {
      font-family: inherit;
      font-size: inherit;
      color: inherit;
    }
  }
}

.order-delivery {
  max-width: 550px;
  margin: 0 auto 80px;
  .payment-total {
    .payment-sum {
      font-size: 30px;
      color: $green;
      text-align: center;
      span {
        color: inherit;
      }
    }
  }
  .js-delivery {
    width: 100%;
    margin-bottom: 40px;
    .js-tabs {
      width: 100%;
      display: flex;
      li {
        position: relative;
        flex-grow: 1;
        padding: 10px 0;
        font-size: 16px;
        text-align: center;
        text-transform: uppercase;
        border-bottom: 3px solid lightgrey;
        color: lightgrey;
        cursor: pointer;
        &.active {
          color: $grey_font;
          border: 3px solid lightgrey;
          border-bottom: none;
        }
      }
    }
    .js-content {
      width: 100%;
      border: 3px solid lightgrey;
      border-top: none;
      padding: 10px 60px 35px 30px;
      font-size: 18px;
      .point-info {
        font-size: 15px;
        padding-top: 15px;
        div {
          width: 100%;
        }
      }
      .select2 {
        margin-bottom: 0 !important;
        width: 100% !important;
      }
      .pickup,
      .courier {
        table,
        tbody {
          display: block;
          width: 100%;
        }
        .delivery-table {
          width: 100%;
          input[type="radio"] {
            display: none;
          }
          .provider-tr {
            position: relative;
            display: flex;
            align-items: center;
            border-top: 1px solid lightgrey;
            padding: 35px 0;
            margin-left: 30px;
            cursor: pointer;
            &:first-of-type {
              border-top: none;
            }
            &.active {
              td {
                //display: none;
                &:first-of-type {
                  background: $blue;
                  display: block;
                }
                &:nth-of-type(2) {
                  display: block;
                }
              }
            }
            td {
              width: 25%;
              text-align: center;
              &:nth-of-type(2) {
                width: 50%;
                text-align: left;
              }
              &:first-of-type {
                position: absolute;
                left: -30px;
                display: block;
                width: 10px;
                height: 10px;
                margin-right: 15px;
                border-radius: 2px;
                background: lightgrey;
              }
            }
          }
          .delivery-data-fields {
            max-width: 400px;
            display: block;
            margin: 0 auto;
            padding-bottom: 15px;
            > td {
              display: block;
              width: 100%;
            }
            .prepayment-table {
              width: 100%;
              overflow: hidden;
              tr {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                border-bottom: 1px solid lightgrey;
                padding: 35px 0;
                margin-left: 30px;
                &:last-of-type {
                  border-bottom: none;
                }
                &.active {
                  td {
                    color: $blue;
                    &:first-of-type {
                      background: $blue;
                    }
                  }
                }
                td {
                  width: 25%;
                  text-align: center;
                  &:nth-of-type(2) {
                    width: 50%;
                    text-align: left;
                  }
                  &:first-of-type {
                    position: absolute;
                    left: -30px;
                    display: block;
                    width: 10px;
                    height: 10px;
                    margin-right: 15px;
                    border-radius: 2px;
                    background: lightgrey;
                  }
                  strong {
                    color: inherit;
                    span {
                      color: inherit;
                    }
                  }
                }
              }
            }
          }
          .address-block {
            label {
              text-align: left;
              font-size: 14px;
              font-family: $c35;
              padding-bottom: 5px;
              br {
                display: none;
              }
            }
            input:not([type="radio"]),
            textarea {
              display: block;
              margin-bottom: 15px;
              width: 100%;
              border: 1px solid lightgrey;
              padding: 10px;
              font-family: $c35;
              font-size: 14px;
              @include placeholder($grey_font_light);
            }
            textarea {
              height: 90px;
              resize: none;
            }
          }
        }
      }
    }
    .rub {
      padding-left: 3px;
    }
  }
}

.page_order .order-delivery {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media all and (max-width: 1023px) {
    align-items: center;
  }
}

.payment_buttons {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 15px;
    margin-bottom: 5px;
  }

  button {
    font-family: $c35;
    background: transparent;
    font-size: 16px;
    border: none;
    outline: none;
    color: $orange;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 70px;
  }
}

.payment_img_block {
  width: 100%;
  padding-top: 60px;
  border-top: 1px solid lightgrey;
  margin-top: 60px;

  @media all and (max-width: 1023px) {
    padding-top: 20px;
    margin-top: 20px;
    order: 4;
  }

  img {
    margin: 0 auto 70px;

    @media all and (max-width: 600px) {
      width: 100%;
      margin: 0 auto 30px;
    }
  }
}

@media all and (max-width: 1023px) {
  .page_order,
  .page_order_complete {
    h1 {
      margin: 30px 0;
    }
    .cart_container {
      padding: 0;
      border: none;
      .total_price {
        width: 100%;
        h2 {
          width: 66%;
          text-align: center;
        }
        > span {
          width: 33%;
          text-align: center;
        }
      }
    }
  }
  .order-delivery {
    .js-delivery {
      .js-content {
        padding: 10px 10px 35px 30px;
        font-size: 15px;
        .pickup,
        .courier {
          .delivery-table {
            .provider-tr {
              margin-left: 10px;
            }
            .delivery-data-fields {
              .prepayment-table {
                tr {
                  padding: 20px 0 20px 20px;
                  margin-left: 0;
                  td {
                    &:first-of-type {
                      left: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.page_order_complete {
  max-width: 500px;
  .border {
    width: 100%;
    height: 11px;
    background: grey;
    border: 3px solid #fff;
    border-radius: 10px;
    box-shadow: 0 0 0 3px lightgrey;
  }
  .order_complete_container {
    border-bottom: 10px solid transparent;
    background-image: linear-gradient($grey_back, $grey_back),
    linear-gradient(to bottom right, transparent 50.5%, $grey_back 50.5%),
    linear-gradient(to bottom left, transparent 50.5%, $grey_back 50.5%),
    linear-gradient(to top right, transparent 50.5%, $grey_back 50.5%),
    linear-gradient(to top left, transparent 50.5%, $grey_back 50.5%);
    background-repeat: repeat, repeat-x, repeat-x, repeat-x, repeat-x;
    background-position: 0 0, 10px 0, 10px 0, 10px 100%, 10px 100%;
    background-size: auto auto, 20px 20px, 20px 20px, 20px 20px, 20px 20px;
    background-clip: padding-box, border-box, border-box, border-box, border-box;
    background-origin: padding-box, border-box, border-box, border-box, border-box;
    margin: -6px 5px 60px;
    padding: 35px 35px 0;
    .order_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 25px;
      border-bottom: 1px solid lightgrey;
      margin-bottom: 30px;
      .order_number_title {
        font-family: $c55;
        font-size: 20px;
        text-transform: uppercase;
      }
      .order_number {
        font-family: $c55;
        font-size: 30px;
      }
    }
    .order_main {
      border-bottom: 1px solid lightgrey;
      margin-bottom: 20px;
      .total_sum,
      .total_items {
        margin-bottom: 5px;
        span {
          font-family: $c35;
          font-size: 15px;
        }
      }
      .thanks_text {
        display: block;
        margin: 25px 0;
        font-family: $c35;
        font-size: 15px;
        line-height: 1.5;
      }
    }
    .order_footer {
      .order_complete_logo {
        @extend .sprite-sprite_images-loader_picture;
        margin: 0 auto 20px;
      }
    }
  }
  .present {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 54px;
    span:nth-of-type(1) {
      font-family: $c55;
      font-size: 20px;
      margin-bottom: 7px;
    }
    span:nth-of-type(2) {
      font-family: $c35;
      font-size: 15px;
      letter-spacing: 0.1px;
      margin-bottom: 35px;
    }
    .coupons {
      margin-bottom: 28px;
      width: 100%;
      display: grid;
      .coupon {
        @mixin coupon_sizes($scale) {
          width: $scale * 295px;
          height: $scale * 160px;
          .number {
            padding: $scale * 3px $scale * 18px;
            font-size: $scale * 15px;
            margin-bottom: $scale * 15px;
          }
          .timer {
            padding: $scale * 5px $scale * 14px;
            font-size: $scale * 14px;
            margin-top: $scale * 80px;
            &:before {
              margin-right: $scale * 5px;
            }
          }
          .date {
            font-size: $scale * 15px;
            padding: $scale * 4px $scale * 12px;
            margin-top: $scale * 80px;
          }
        }
        position: relative;
        margin-bottom: 20px;
        overflow: hidden;
        justify-self: center;
        img {
          width: 100%;
        }
        .properties {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          opacity: 1;
          transition: opacity 0.2s;
          .number {
            color: white;
            background: $green;
            border-radius: 0 0 1px 1px;
          }
          .date {
            font-family: $c35;
            background: white;
            color: $grey_font;
          }
          .timer {
            display: flex;
            align-items: center;
            background: $red;
            border-radius: 1px;
            font-family: $c45;
            color: white;
            &:before {
              content: '';
              display: inline-block;
              @extend .sprite-sprite_images-timer;
              margin-right: 5px;
            }
          }
        }
        .overlay {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(255, 255, 255, 0.4);
          opacity: 0;
          transition: opacity 0.2s;
          .submit_input {
            -webkit-appearance: none;
          }
        }
        form {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          input {
            border: none;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
        &:hover {
          .properties {
            opacity: 0;
          }
          .overlay {
            opacity: 1;
          }
        }
        @include coupon_sizes(1);
      }
    }
    span:nth-of-type(3) {
      font-family: $c35;
      font-size: 15px;
      margin-bottom: 35px;
      a {
        text-decoration: underline;
      }
    }
  }
  .review_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .review_title {
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 10px;
    }
    .review_description {
      font-family: $c35;
      font-size: 15px;
      text-align: center;
      margin-bottom: 20px;
    }
    .review_button {
      @include button($blue, $blue_hover);
      margin-bottom: 25px;
      white-space: nowrap;
    }
    .to_index {
      @extend .review_description;
      display: inline-block;
      border-bottom: 1px solid lightgrey;
      white-space: nowrap;
      &:hover {
        border-bottom-color: transparent;
      }
    }
  }
  @media all and (max-width: 480px) {
    padding: 35px 15px 0;
  }
}

.page_order_fail {
  .button {
    margin: 0 auto;

    @include button($orange, $orange_hover);
  }
}

.page_summary_report {
  align-items: flex-start;
  h1 {
    font-size: 25px;
    text-align: center;
    margin-bottom: 40px;
    &::after {
      display: none;
    }
  }

  .select2 {
    display: block;
    width: 100% !important;
    margin: 5px auto 15px;
  }

  .summary_report_block {
    flex: 1 1 auto;
    background: $grey_back;
    margin: 0 10px 30px;
    padding: 15px;
  }

  .order-field-row {
    font-size: 16px;
    text-transform: uppercase;
  }

  .order-contact {
    display: block;
    max-width: 300px;
    margin: auto;

    input {
      display: block;
      width: 100%;
      border: 1px solid lightgrey;
      padding: 5px;
      font-family: $c35;
      font-size: 14px;
      margin-top: 5px;
      @include placeholder($grey_font_light);
    }
  }
  .order-payment {
    input {
      @include button($orange, $orange_hover);
      display: block;
      padding: 10px 30px;
    }
    .description {
      text-align: center;
      font-size: 15px;
      margin-bottom: 40px;
      a {
        color: $blue_hover;
      }
    }
    img {
      margin: 0 auto 70px;
    }
  }

  .order_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .order_number_title,
    .order_number {
      font-family: $c55;
      font-size: 18px;
      text-transform: uppercase;
    }
  }
  .order_main {
    .total_sum,
    .total_items {
      margin-bottom: 15px;
      .title {
        font-size: 16px;
        text-transform: uppercase;
        padding-bottom: 5px;
      }
      .items {
        font-family: $c35;
        font-size: 15px;
      }

    }
    .thanks_text {
      display: block;
      margin: 25px 0;
      font-family: $c35;
      font-size: 15px;
      line-height: 1.5;
    }
  }
}