.img-viewer {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  align-items: start;
  // overflow: hidden;
  position: relative;
  user-select: none;
  .button {
    position: absolute;
    height: 100%;
    z-index: 1;
    cursor: pointer;
    display: none;
    background: none;
    border: none;
    outline: none;
    align-items: center;
    justify-content: center;
    &.prev {
      left: 0;
    }
    &.next {
      right: 0;
      .icon {
        transform: rotate(180deg);
      }
    }
    &.visible {
      display: flex;
    }
    .icon {
      margin: 10px;
      @include slider_button();
    }
  }
  .item {
    display: none;
    position: relative;
    transition: opacity 0.3s;
    overflow: hidden;
    &.visible {
      display: block;
      opacity: 0;
    }
    &.loaded {
      opacity: 1;
    }
    &.zoom {
      cursor: zoom-in;
    }
    img {
      width: 100%;
      &.zoomed {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        cursor: zoom-out;
      }
    }
  }
}
