.pages_info_menu_container {
  .pages_info_menu {
    padding-left: 20px;
    > li {
      margin-bottom: 15px;
      .pages_profile_link {
        font-size: 14px;
        text-transform: uppercase;
        color: $grey_font_light;
        &.active { color: $orange; }
      }
      .sub_pages_info_menu {
        padding-left: 25px;
        li a {
          position: relative;
          font-size: 15px;
          text-transform: capitalize;
          &::before {
            content: '';
            position: absolute;
            left: -10px;
            top: 45%;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: inherit;
          }
        }
      }
    }
  }
}
@media all and (max-width: 1023px) {
  .pages_info_menu_container {
    .pages_profile_menu {
      width: 230px;
      margin: 50px auto 200px;
      overflow: hidden;
      -webkit-overflow-scrolling: touch;
      li {
        position: relative;
        padding-bottom: 15px;
        @include after_row(lightgrey);
        &:last-of-type::after { display: none; }
        .pages_profile_link {
          font-family: $c55;
          color: $grey_font;
          padding-left: 10px;
        }
      }
    }
  }
}

.pages_info_container {
  margin-bottom: 50px;
  h3 { margin-bottom: 10px; }
  .select2 {
    width: 100% !important;
    max-width: 400px;
    margin-bottom: 40px;
  }
  &.page_delivery_container {
    .info_block {
      border-bottom: none;
    }
    .current_location {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      text-transform: uppercase;
      border-bottom: 1px solid #d5dbde;
      padding: 0 0 12px 0;
      margin: 0 0 32px 0;
      letter-spacing: 0.2px;
      .icon {
        margin: 0 10px 0 0;
        @extend .sprite-sprite_images-header_city;
      }
      .title {
        margin: 0 5px 0 0;
      }
      .city {
        font-family: $c45;
        font-size: 16px;
        color: $blue;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        border: none;
        border-bottom: 1px dotted $blue;
        background: none;
        outline: none;
        cursor: pointer;
      }
    }
    .delivery_methods {
      margin: 0 0 43px 0;
      .method {
        display: grid;
        justify-content: left;
        justify-items: left;
        margin: 0 0 20px 0;
        .title {
          font-family: $c45;
          font-size: 16px;
          letter-spacing: 0.2px;
          text-transform: uppercase;
          margin: 0 0 10px 0;
        }
        .description {
          font-family: $c35;
          font-size: 15px;
          margin: 0 0 9px 0;
        }
        .info {
          font-family: $c35;
          font-size: 15px;
          margin: 0 0 7px 44px;
          position: relative;
          &:before {
            content: '';
            display: block;
            position: absolute;
            left: -20px;
          }
          &.location {
            color: #0077e7;
            border-bottom: 1px dotted #0077e7;
            &:before {
              @extend .sprite-sprite_images-header_city;
            }
          }
          &.box {
            &:before {
              left: -22px;
              @extend .sprite-sprite_images-delivery_box;
            }
          }
          &.clock {
            &:before {
              top: 1px;
              left: -22px;
              @extend .sprite-sprite_images-clock;
            }
          }
        }
      }
    }
    .delivery_points {
      h1 {
        margin: 0 0 34px 0;
      }
      .letter {
        display: block;
        font-family: $c45;
        font-size: 20px;
        margin: 0 0 8px 0;
      }
      .cities {
        margin: 0 0 25px 0;
        a {
          display: inline-block;
          font-family: $c35;
          font-size: 15px;
          color: #0077e7;
          border-bottom: 1px dotted #0077e7;
          margin: 0 8px 4px 0;
          white-space: nowrap;
        }
      }
    }
    @include point_list();
  }
  &.page_contacts_container,
  &.page_popular_container,
  &.page_popular_career {
    .info_block { border-bottom: none; }
  }
  .info_block {
    border-bottom: 1px solid lightgrey;
    margin-bottom: 35px;
    &:last-of-type { border: none; }
    > h1,
    h2 {
      font-size: 20px;
      text-transform: uppercase;
      @include after_line($orange,10px 0 25px);
    }
    h2 {
      font-size: 17px;
    }
    > h3 {
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    > h4 {
      @extend h3;
      margin-bottom: 5px;
    }
    > p {
      font-family: $c35;
      font-size: 15px;
      margin-bottom: 30px;
      line-height: 1.5;
      b { font-family: $c55; }
    }
    ul {
      @extend p;
      li {
        font-family: $c35;
        margin-bottom: 5px;
      }
    }
    .vacancy_block {
      padding-left: 0;
      &.exchange_block { display: block !important; }
    }
    a { color: $blue_hover; }
    .reviews_block {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .jscroll-inner, .jscroll-added {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .rewiew {
        width: 48%;
        display: grid;
        align-content: start;
        align-items: start;
        padding: 20px;
        .comment {
          position: relative;
          width: 100%;
          background: $grey_back;
          border-radius: 3px;
          margin: 0 0 15px 0;
          padding: 20px;
          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            background: $grey_back;
            left: 30px;
            bottom: -10px;
            transform: rotate(45deg);
          }
          .rate_star { @include rate_star(); }
          .text {
            font-family: $c35;
            font-size: 15px;
            line-height: 1.5;
            p {
              font-family: $c35;
              font-size: 15px;
              line-height: 1.5;
            }
          }
        }
        .info {
          align-self: end;
          padding: 0 20px;
          .name {
            font-size: 15px;
            .date {
              font-family: $c35;
              font-size: 13px;
            }
          }
        }
      }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      .reviews_block {
        .rewiew { width: 31%; }
      }
    }
    @media (min-width: 480px) and (max-width: 767px) {
      .reviews_block {
        .rewiew { width: 48%; }
      }
    }
    @media (min-width: 320px) and (max-width: 479px) {
      .reviews_block {
        .rewiew { width: 100%; }
      }
    }
    > form .select2 {
      width: 185px !important;
      margin-bottom: 30px;
    }
  }
  .payment_page_img { margin-bottom: 30px; }
  @media all and (max-width: 575px) {
    .payment_page_img { width: 100%; }
  }
  .review_form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .review_counter {
      display: block;
      font-family: $c35;
      font-size: 15px;
    }
    @media all and (max-width: 767px) {
      flex-wrap: wrap;
      .review_counter {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  @include comments_form();
  @include reviews_form_container();
  .vacancy_title {
    display: inline-block;
    cursor: pointer;
    border-bottom: 1px dashed;
    margin-right: 100%;
    white-space: pre;
    &.active { border-color: transparent; }
    &::after {
      content: '';
      display: table;
      width: 100%;
      height: 1px;
    }
    &:hover { border-color: transparent; }
  }
  .facts_block {
    ul {
      margin-bottom: 25px;
      padding-left: 30px;
      li {
        position: relative;
        font-size: 15px;
        margin-bottom: 5px;
        &::before {
          content: '';
          position: absolute;
          top: 5px;
          left: -15px;
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: $orange;
        }
      }
    }
  }
  .print_info_block {
    img {
      width: 100%;
      height: auto;
      max-width: 725px;
      margin: 0 auto 50px;
    }
  }
  .vacancy_block {
    display: none;
    width: 100%;
    padding-left: 20px;
    margin-bottom: 50px;
    h4 {
      font-size: 15px;
      margin-bottom: 15px;
    }
    ul {
      margin-bottom: 25px;
      padding-left: 30px;
      li {
        position: relative;
        font-size: 15px;
        margin-bottom: 5px;
        &::before {
          content: '';
          position: absolute;
          top: 5px;
          left: -15px;
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: $orange;
        }
      }
    }
    .close_block {
      width: 100%;
    }
    .close_link {
      @extend .vacancy_title;
      font-size: 15px;
      color: $blue_hover;
      margin-bottom: 10px;
    }
  }
  .how_order_block {
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0;
    border-bottom: 1px solid lightgrey;
    &.active { display: flex !important; }
    .how_order_cell {
      width: 30%;
      margin: 0 1.5% 45px;
      &:last-of-type { margin-right: auto; }
      img {
        width: 100%;
        height: auto;
        margin-bottom: 15px;
        border: 1px solid lightgrey;
      }
      h5 {
        font-size: 15px;
        margin-bottom: 5px;
      }
      p {
        font-family: $c35;
        font-size: 15px;
        line-height: 1.5;
      }
    }
    @media (min-width: 480px) and (max-width: 767px) {
      .how_order_cell {
        width: 48%;
        margin: 0 1% 30px;
      }
    }
    @media all and (max-width: 479px) {
      .how_order_cell {
        width: 100%;
        margin: 0 0 30px;
      }
    }
  }
  // БЛОК ОТВЕТА НА ОТЗЫВ
  .answer_container {
    .show_answer,
    .hide_answer {
      font-family: $c35;
      font-size: 14px;
      color: $blue;
      border-bottom: 1px dashed $blue;
      cursor: pointer;
      &:hover { border-color: transparent; }
    }

    .hide_answer { margin-left: 20px; }

    .answer_block {
      padding: 10px 0 0 40px;
    }

    .answer_text {
      font-family: $c35;
      font-size: 15px;
      margin-bottom: 10px;
    }

    .name { font-size: 15px; }
    .date {
      font-family: $c35;
      font-size: 13px;
    }
  }
}
@include phone() {
  .pages_info_container {
    &.page_delivery_container {
      .current_location {
        justify-content: center;
      }
    }
  }
}

.page_cooperation .pages_info_container .info_block { border-color: transparent; }

.page_career {
  .pages_info_container {
    .info_block {
      &:last-of-type { border-bottom: 1px solid lightgrey; }
    }
    .vacancy_form_container {
      border: none;
      max-width: 350px;
      padding: 5px 0;
      .open_vacancy { border: 4px solid $grey_back; }
      .vacancy_form_block { display: none; }
    }
    @media all and (max-width: 767px) {
      .vacancy_form_container { max-width: 100%; }
    }
    .comments_form {
      padding: 0;
      .text_input,
      textarea {
        width: 100%;
        margin-right: 0;
      }
      label {
        display: flex;
        width: 100%;
        margin-bottom: 30px;
        font-family: $c35;
        font-size: 15px;
        .icon {
          @extend .sprite-sprite_images-upload;
          margin-right: 10px;
        }
        #file { display: none; }
      }
    }
  }
}
