footer {
  padding: 26px calc((100% - 1240px) / 2) 26px;
  display: grid;
  grid-template-columns: repeat(3, min-content) 1fr;
  background: $grey_back;
  nav {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    padding-right: 30px;
    &:nth-of-type(2), &:nth-of-type(3) {
      padding-left: 30px;
    }
    &:nth-of-type(1), &:nth-of-type(2) {
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 1px;
        height: 30px;
        background: #c2c7ca;
        position: absolute;
        right: 0;
        top: 10px;
      }
    }
    div {
      display: grid;
      justify-items: start;
      align-content: start;
    }
    a {
      white-space: nowrap;
      font-family: $c35;
      font-size: 15px;
      border-bottom: 1px solid #d0d4d6;
      margin: 0 0 2px;
    }
  }
  .site_info {
      justify-self: right;
      display: grid;
      justify-items: center;
      align-self: flex-start;
      .social {
        .icon {
        &.vk {
          display: inline-block;
          @extend .sprite-sprite_images-vk_hover;
        }
        &.facebook {
          display: inline-block;
          @extend .sprite-sprite_images-facebook_hover;
        }
        &.instagram {
          display: inline-block;
          @extend .sprite-sprite_images-instagram_hover;
        }
      }
      }
      .copyright {
        white-space: nowrap;
        font-family: $c45;
        font-size: 13px;
      }
    }
}

@include small_desktop() {
  footer {
    padding: 26px calc((100% - 1000px) / 2) 26px;
    nav {
        grid-gap: 20px;
        &:nth-of-type(2), &:nth-of-type(3) {
          padding: 0 20px 0 20px;
        }
      }
  }
}
@include tablet() {
  footer {
    padding: 20px 10px;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    nav {
      &:nth-of-type(1) {
        padding: 0;
      }
      &:nth-of-type(2) {
        padding: 0;
        &:after {
          display: none;
        }
      }
      &:nth-of-type(3) {
        padding: 0;
        position: relative;
        &:after {
          content: '';
          display: block;
          width: 1px;
          height: 30px;
          background: #c2c7ca;
          position: absolute;
          right: 0;
          top: 10px;
        }
      }
      div {
        justify-items: center;
      }
    }
    .site_info {
        margin: 20px 0 0 0;
        justify-self: center;
      }
  }
}
@include big_phone() {
  footer {
    grid-template-columns: 1fr;
    nav {
      grid-template-columns: 1fr 1fr;
      &:nth-of-type(3), &:nth-of-type(1) {
        &:after {
          display: none;
        }
      }
      div {
        justify-items: center;
      }
    }
  }
}
