#email_rss {
  width: 605px;
  height: 290px;
  background: url(/static/img/main/rss.jpg) no-repeat center;
  background-size: cover;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .icon {
    @extend .sprite-sprite_images-email_rss;
    margin: 0 auto 15px;
  }
  h2 {
    font-size: 20px;
    text-transform: uppercase;
    padding: 0 65px 10px;
    margin: 0 0 20px 0;
  }
  p {
    font-family: $c35;
    font-size: 15px;
    margin-bottom: 25px;
  }
  form {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    input[type="email"] {
      display: block;
      width: 200px;
      border: 1px solid lightgrey;
      background-color: $grey_back;
      padding: 10px;
      font-size: 13px;
      border-radius: 2px;
      margin-right: 10px;
    }
    input[type="submit"] {
      @include button($orange,$orange_hover);
      width: 60px;
      padding: 0;
    }
    .errorlist {
      margin: 5px;
      display: block;
      li {
        font-family: $c35;
        font-size: 15px;
        line-height: normal;
        color: red;
        &::before { display: none }
      }
    }
  }
  .agreement {
    display: flex;
    justify-content: center;
    margin-top: 14px;
    font-family: $c35;
    font-size: 14px;
    color: #52575b;
    .icon {
      @extend .sprite-sprite_images-agreement;
      flex-shrink: 0;
      margin: 0 10px 0 0;
    }
  }
}