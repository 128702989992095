.news{
  @media (max-width: 479px){
    margin-top: 75px;
  }
  
  @media (max-width: 1023px) {
    margin-top: 85px; 
  }
  .invisible {
    height: 0;
    margin-bottom: 12px;
    padding: 0;
    border: 0;
  }
  .delivery_banner {
    border: 2px solid $grey_back;
    padding: 7px 108px 7px 30px;
    position: relative;
    width: 1240px;
    margin: 0 auto 12px;
    text-align: center;
  
    &.invisible {
      height: 0;
      margin-bottom: 0;
      padding: 0;
      border: 0;
    }
    .close {
      border: none;
      border-left: 2px solid #f0f4f6;
      background: none;
      outline: none;
      font-family: $c45;
      font-size: 13px;
      color: $grey_font_light;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 7px 13px;
      cursor: pointer;
    }
    a {
      font-family: $c45;
      font-size: 13px;
      color: $grey_font_light;
      text-transform: uppercase;
      letter-spacing: 0.45px;
    }
  
    @include small_desktop {
      width: 1000px;
    }
    @include tablet {
      width: calc(100vw - 20px);
    }
 
  }
  .news-container {
    justify-items: center;
    display: flow-root;
    justify-content: center;
    align-content: center;
    .sub_menu_link {
      background: transparent;
      border: none;
      display: inline-block;
      font-family: $c35;
      font-size: 16px;
      margin: 0 5em 5px 0;
      cursor: pointer;
      &:hover { color: $blue; }
    }
  }
  
}

