.help-block {
    position: fixed;
    bottom: 20px;
    right: 35px;
    background-color: white;
    padding: 10px 12px;
    border-radius: 12px;
    box-shadow: 0 0 3px rgba(0,0,0,0.1),0 0 10px rgba(0,0,0,0.3);
    cursor: pointer; 
    transition: box-shadow 0.3s ease; 
    z-index: 9999;
    font-family: core_sans_d_35_regularregular;
    font-size: 16px;
    width: 80px;
    height: 40px;
}

.help-block:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}