/********************
Select2 Override style
*********************/
.select2-container {
  //  min-width: 17;
  // font-family: Roboto, Helvetica;
}

/*********************************
Author : Techhysahil
Link : http://techhysahil.com
*********************************/
.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above {
  background: #fff;
}

.select2-container--flat .select2-container--focus .select2-selection--multiple {
  border: 1px solid #ccc;
}
.select2-container--flat .select2-results__option--highlighted[aria-selected] {
  background: #ccc !important;
  color: #000;
}
.select2-container--flat .select2-results__option[aria-selected=true] {
  background: #ccc;
  color: #000;
  opacity: 0.8;
}
.select2-container--flat .select2-selection--single {
  border-radius: 0;
}
.select2-container--flat.select2-container--open .select2-selection__arrow b {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  margin-top: 5px !important;
}
.select2-container--flat.select2-container--open .select2-selection--single {
  border-bottom: 0;
}
.select2-container--flat span.select2-search input {
  height: 3 !important;
}

.select2-container .select2-choice {
  border: 2px solid #dce4ec;
  height: 36px;
  border-radius: 0;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  text-indent: 1px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-image: none;
}
.select2-container .select2-choice div {
  border-left: 2px solid #dce4ec;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.select2-container .select2-choice .select2-arrow {
  border: 0;
  border-radius: 0;
  background: transparent;
  background-image: none;
}
.select2-container *:focus {
  outline: 0;
}
.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: #dce4ec;
  border-radius: 0;
}

.select2-drop {
  margin-top: -2px;
  border: 2px solid #dce4ec;
  border-top: 0;
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
  box-shadow: none;
}
.select2-drop.select2-drop-above {
  margin-top: 2px;
  border-top: 2px solid #dce4ec;
  border-bottom: 0;
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

//.select2-search {
//  margin-top: 3px;
//}
.select2-search input {
  height: 36px;
  border: 1px solid #dce4ec;
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  border: 2px solid #dce4ec;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.select2-dropdown-open .select2-choice {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
}
.select2-dropdown-open .select2-choice .select2-choice div {
  background: transparent;
  border-left: none;
  filter: none;
}

.select2-results {
  padding: 0;
  //  margin: 4px   0;
}
.select2-results .select2-highlighted {
  background: #ccc;
  color: #000;
  border-radius: 0;
}

.select2-container-multi .select2-choices {
  height: auto !important;
  height: 1%;
  border: 2px solid #dce4ec;
}
.select2-container-multi.select2-container-active .select2-choices {
  border: 2px solid #dce4ec;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/****** Single SCSS *******/
.select2-container--flat .select2-selection--single {
  background: #f0f4f6;
  border: 1px solid #aaa;
  height: 34px;
}
.select2-container--flat .select2-selection--single .select2-selection__rendered {
  color: #000;
  line-height: 34px;
}
.select2-container--flat .select2-selection--single .select2-selection__arrow {
  height: 32px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 2;
}
.select2-container--flat .select2-selection--single .select2-selection__arrow b {
  position: absolute;
  background: #666;
  height: 0;
  width: 0;
  top: 50%;
  left: 30%;
  margin-top: -2px;
}
.select2-container--flat .select2-selection--single .select2-selection__arrow b::after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(102, 102, 102, 0);
  border-top-color: #666;
  border-width: 7px;
  margin-left: -7px;
}
.select2-container--flat .select2-selection--single .select2-selection__placeholder {
  color: $grey_font;
}
.select2-container--flat .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

/****** Multiple SCSS *******/
.select2-container--flat .select2-selection--multiple {
  border: 1px solid #ccc;
}
.select2-container--flat .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #ccc;
  cursor: pointer;
}
.select2-container--flat .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--flat .select2-selection--multiple .select2-selection__choice {
  background-color: #f0f4f6;
  color: #000;
  border: 1px solid #f0f4f6;
  border-radius: 0;
  padding: 3px 5px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}
.select2-container--flat .select2-selection--multiple .select2-selection__choice__remove {
  color: #ccc;
  margin-right: 6px;
  margin-left: 6px;
  float: right;
}
.select2-container--flat .select2-results > .select2-results__options {
  max-height: 20;
}
.select2-container--flat .select2-results > .select2-results__options {
  max-height: 19;
  overflow-y: auto;
}
