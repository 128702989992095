#sidebar {
  position: fixed;
  top: 70px;
  z-index: 999;
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.2s ease, opacity 0.2s ease-in;
  &.active {
    display: flex;
    opacity: 1;
    visibility: visible;
    transition: visibility 0s ease-in, opacity 0.2s ease-in;
    .content {
      left: 0;
    }
  }
  .content {
    position: absolute;
    top: 0;
    left: -320px;
    z-index: 1000;
    background: white;
    width: 320px;
    height: 100%;
    box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.1);
    padding: 0 0 40px 0;
    transition: left 0.2s ease-in;
    overflow-y: scroll;
    &::-webkit-scrollbar { display: none; }
    .head {
    background: #f0f4f6;
    padding: 22px 20px 22px 20px;
    display: grid;
    justify-items: left;
    .location {
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      margin: 0 0 20px 0;
      .icon {
        @extend .sprite-sprite_images-header_city;
        content: '';
        display: inline-block;
        margin: 0 10px -2px 0;
      }
      .text {
        font-family: $c45;
        font-size: 16px;
        color: $blue;
        text-transform: uppercase;
        border-bottom: 1px dotted $blue;
      }
    }
    .delivery_points {
      margin: 0 0 20px 0;
      .icon {
        @extend .sprite-sprite_images-delivery_box;
        content: '';
        display: inline-block;
        margin: 0 6px -2px 0;
      }
      .text {
        font-family: $c45;
        font-size: 16px;
        color: $blue;
      }
    }
    .auth {
      .icon {
        @extend .sprite-sprite_images-header_acc;
        content: '';
        display: inline-block;
        margin: 0 10px -2px 0;
      }
      .text {
        font-family: $c45;
        font-size: 16px;
        color: $blue;
      }
    }
  }
    .nav_sidebar_container {
    margin: 0 auto 22px;
    width: 100%;
    .choice,
    .nav_menu_item {
      position: relative;
      width: 265px;
      margin: 0 auto;
      height: 52px;
      overflow: hidden;
      @include after_row(lightgrey);
      &::before {
        content: '';
        @include arrow();
        top: 16px;
      }
      &::after { bottom: 1px; }
      &.active {
        height: auto;
        overflow: visible;
        &:before {
          transform: rotate(270deg);
        }
      }
      h2,
      .nav_menu_link {
        font-family: $c45;
        position: relative;
        display: block;
        font-size: 16px;
        text-transform: uppercase;
        padding: 15px 0 16px 17px;
        cursor: pointer;
        @include after_row(lightgrey);
      }
      .sub_menu_container {
        @include choice_form();
        .choice_form {
          input[type="reset"] { display: none; }
          .find_and_close { display: none; }
        }
      }
    }
  }
    .sidebar_tel {
    padding: 16px 0 0 0;
    margin: 20px 28px 10px;
    h3 {
      display: inline-block;
      margin-bottom: 3px;
      a {
        font-size: 16px;
        color: $grey_font_light;
      }
    }
  }
    .consultant {
    margin: 10px 28px;
    cursor: pointer;
    .icon {
      @extend .sprite-sprite_images-consultant;
      display: inline-block;
      margin: 0 0 -2px 0;
    }
    .text {
      color: $blue;
      border-bottom: 1px solid $blue;
    }
  }
  }
  .overlay {
    background: white;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }
}
#sidebar-toggle {
  display: none;
  padding: 15px 15px 15px 25px;
  cursor: pointer;
  margin-left: -20px;
  .bar {
    display: block;
    width: 27px;
    margin-bottom: 4px;
    height: 3px;
    background-color: $grey_font_light;
    transition: transform 0.15s linear;
    &:last-child { margin-bottom: 0; }
  }
  .text {
    position: absolute;
    top: 19px;
    padding-left: 40px;
    color: $grey_font_light;
    font-size: 24px;
    text-transform: uppercase;
  }
  &.active {
    .text { color: $blue; }
    .bar { background-color: $blue; }
    .bar_1 {
      transform: translate(-4px, 3px) rotate(-45deg);
      width: 13px;
    }
    .bar_3 {
      transform: translate(-4px, -3px) rotate(45deg);
      width: 13px;
    }
  }
}

@media all and (max-width: 1023px) {
  #sidebar-toggle { display: block; }
}
@media all and (max-width: 767px) {
  #sidebar-toggle {
    .bar { background-color: $blue; }
    .text { color: $blue; }
  }
}
@media all and (max-width: 479px) {
  #sidebar {
    top: 60px;
    height: calc(100vh - 60px);
  }
  #sidebar-toggle .text { display: none; }
}
