.page_projects_container {
  @include product();
  .project {
    justify-content: space-around;
    .image_wrapper {
      width: 70px;
      height: 70px;
    }
    .product_detail .product_title {
      font-size: 16px;
      &:hover { color: $grey_font; }
    }
    .option_block {
      position: relative;
      &.toogle_arrow {
        position: absolute;
        right: 0;
        h3 { display: none; }
        .arrow {
          position: absolute;
          top: 20px;
          right: 5px;
          display: block;
          width: 10px;
          height: 10px;
          border-top: 2px solid $grey_font_light;
          border-left: 2px solid $grey_font_light;
          transform: rotate(45deg);
          transition: transform linear 0.2s;
        }
      }
      > h3 {
        span { color: inherit; }
        a {
          @include view_more_link($blue_hover);
          border-bottom-style: solid;
          border-color: $blue_hover;
          font-size: 14px;
        }
      }
      > p {
        font-size: 16px;
        span {
          display: inline-block;
          margin-right: 3px;
        }
      }
    }
    &::after { margin: 20px 0; }
    &.history_container {
      .header_history {
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media screen and (max-device-width: 1024px) {
          cursor: pointer;
        }
        .option_block { width: 20%; }
      }
      .main_history {
        display: none;
        width: 100%;
        margin-top: 40px;
        .product {
          position: relative;
          justify-content: flex-start;

          .add_review {
            width: 20%;
            position: absolute;
            right: 0;
            bottom: 30px;
            display: inline-block;
            font-family: $c35;
            font-size: 16px;
            text-decoration: underline;
            color: $blue;

            &:hover {
              text-decoration: none;
            }

            @media all and (max-width: 767px) {
              width: auto;
              right: auto;
              left: 50%;
              bottom: 25px;
              font-size: 15px;
              transform: translateX(-50%);
            }
          }
          .image_wrapper { margin: 0 auto 10px; }
          .product_detail {
            width: 40%;
            padding-right: 10%;
            margin-left: auto;
            @media all and (max-width: 1023px) {
              padding-right: 0;
            }
          }
          .option_block { width: 20%; }
          &::after {
            margin: 10px auto;
            max-width: 635px;
          }
          &.repeat {
            &::after { display: none; }
            .repeat_order {
              @include button($blue,$blue_hover);
              padding: 10px 30px;
            }
          }
        }
        .close_block {
          display: none;
          @media screen and (max-device-width: 1024px) {
            cursor: pointer;
          }
          .arrow {
            display: block;
            margin: 0 auto;
            width: 10px;
            height: 10px;
            border-top: 2px solid $blue_hover;
            border-left: 2px solid $blue_hover;
            transform: rotate(45deg);
          }
          h4 {
            font-family: $c35;
            font-size: 15px;
            color: $blue_hover;
            text-align: center;
          }
        }
      }
    }
    @media all and (max-width: 767px) {
      &.history_container {
        .header_history {
          .option_block {
            width: 50%;
            margin-bottom: 10px;
            padding-left: 30px;
            &.toogle_arrow {
              position: relative;
              h3 { display: block; }
              .arrow {
                left: 45px;
                top: 30px;
              }
            }
            h3 { text-align: left; }
          }
        }
        .main_history {
          .product {
            &::after { margin: 20px 0; }
            .product_detail { width: 65%; }
            .option_block {
              width: 50%;
              p {
                width: 100%;
                text-align: center;
              }
            }
            &.repeat {
              .product_detail {
                order: 3;
                width: 100%;
              }
              .option_block {
                &.text { order: 1; }
                &.value { order: 2; }
              }
              .repeat_order {
                width: 100%;
                margin: 20px auto;
              }
            }
          }
          .close_block { display: block; }
        }
      }
    }
    &.active {
      .header_history .option_block {
        h3 { color: $blue_hover; }
        p { color: $blue; }
        .arrow {
          transform: rotate(225deg);
          border-color: $blue;
        }
      }
    }
  }
}

.page_profile_container {
  .profile_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    h1::after { margin: 10px 0 40px !important; }
    .profile_info_container {
      padding-left: 25px;
      margin-bottom: 20px;
      .profile_info_block {
        width: 510px;
        border-bottom: 1px solid lightgrey;
        padding-bottom: 15px;
        margin-bottom: 15px;
        .profile_info_cell {
          display: flex;
          margin-bottom: 15px;
          .border {
            border-bottom: 1px dashed lightgrey;
            flex-grow: 1;
            margin: 0 10px 3px;
          }
          span {
            display: block;
            font-family: $c35;
            font-size: 15px;
            color: $grey_font_light;
            &.value {
              width: 30%;
              color: $grey_font;
            }
          }
        }
      }
      > p a {
        @include view_more_link($blue_hover);
        display: inline-block;
        font-family: $c35;
        font-size: 14px;
        border-bottom-style: dashed;
        margin-bottom: 10px;
      }
    }
    .social_bar_container {
      margin-bottom: 35px;
      .social_title {
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      .social_description {
        font-size: 15px;
        color: $grey_font_light;
        margin-bottom: 25px;
      }
      .social_autorization {
        @include social_bar();
        width: 180px;
        justify-content: space-between;
        .social_login_link {
          margin: 0;
          &.noauthorized { background: $grey_font_light; }
          &.instagram { display: none; }
        }
      }
    }
    .adresses_container {
      width: 100%;
      border-top: 1px solid lightgrey;
      padding: 55px 0;
      .adresses_title {
        font-size: 20px;
        text-transform: uppercase;
        @include after_line($orange,10px 0 55px);
      }
      a {
        font-family: $c35;
        font-size: 14px;
        &.change_adress,
        &.add_adress {
          @include view_more_link($blue_hover);
          border-bottom-style: dashed;
        }
      }
      .adress_block {
        padding-left: 143px;
        overflow: visible;
        &::after { margin: 15px 0; }
        input { display: none; }
        label {
          position: relative;
          display: block;
          width: 12px;
          height: 12px;
          background: $grey_back;
          border-radius: 2px;
          cursor: pointer;
          .for_delivery {
            display: none;
            position: absolute;
            right: 45px;
            top: calc(50% - 9px);
            font-family: $c35;
            font-size: 10px;
            text-transform: uppercase;
            color: #fff;
            background: $blue;
            text-align: center;
            width: 110px;
            padding: 3px;
            &::after {
              content: '';
              display: block;
              position: absolute;
              top: calc(50% - 6px);
              right: -6px;
              width: 12px;
              height: 12px;
              background: $blue;
              transform: rotate(-45deg);
            }
          }
        }
        input:checked + label {
          background: $blue;
          .for_delivery { display: inline-block; }
        }
        .adress {
          font-family: $c35;
          font-size: 15px;
          max-width: 420px;
          flex-grow: 1;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page_profile_container {
    .profile_container {
      .profile_info_container {
        margin-top: 30px;
        width: 100%;
        .profile_info_block {
          border: none;
          padding: 0;
        }
      }
      .social_bar_container {
        width: 100%;
        padding: 35px 0 0 25px;
        border-top: 1px solid lightgrey;
      }
    }
  }
}

@media all and (max-width: 767px) {
  .page_profile_container {
    .profile_container {
      .profile_info_container {
        margin-top: 30px;
        width: 100%;
        padding: 0;
        .profile_info_block {
          width: 100%;
          border: none;
          padding: 0;
          .profile_info_cell {
            span.value {
              width: 55%;
              overflow: hidden;
              word-spacing: elipsis;
              word-break: none;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .social_bar_container {
        width: 100%;
        padding: 35px 0 0;
        border-top: 1px solid lightgrey;
      }
      .adresses_container {
        padding: 35px 0;
        .adresses_title {
          @include after_line($orange,10px 0);
        }
        a {
          display: inline-block;
          width: 50%;
          &.change_adress,
          &.add_adress {
            @include view_more_link($blue_hover);
            border: none;
          }
        }
        .adress_block {
          padding: 45px 0 0;
          // justify-content: flex-start;
          &::after { margin: 15px 0 10; }
          label {
            margin-right: 25px;
            .for_delivery {
              left: 0;
              top: -40px;
            }
          }
          .adress {
            width: calc(100% - 37px);
            max-width: 100%;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
