$mint: #5dc0c5;

.promo_page_gifts {
  background: url('/static/img/page_gifts/bg_top.png') no-repeat top center;
  background-size: contain;

  .top_header_container,
  main { background: transparent;}

  @media all and (max-width: 1023px) {
    background-position-y: 80px;

    .top_header_container { background: #fff; }
  }
  @media all and (max-width: 479px) { background-position-y: 66px; }
}

.page_promo_gifts {
  padding-top: 65px;

  @media all and (max-width: 1023px) { padding-top: 30px; }
  @media all and (max-width: 767px) { padding-top: 40px; }

  .mint { color: $mint; }
  .orange { color: $orange; }
  .button { @include button($orange, $orange_hover); }

  .top_block {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-family: $germ;
      font-size: 60px;
      font-weight: 700;
      max-width: 576px;
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 8px;
      line-height: 1.4;
      color: $mint;

      @include after_line($orange, 25px auto 20px);
      &::after {
        width: 40px;
        height: 6px;
      }
    }

    .text {
      font-family: $c35;
      font-size: 17px;
      text-align: center;
      line-height: 1.4;
      max-width: 535px;
      margin: 0 auto 55px;
    }

    .button {
      margin-bottom: 25px;
    }

    .arrow {
      display: block;
      width: 30px;
      height: 30px;
      border-left: 3px solid $mint;
      border-bottom: 3px solid $mint;
      transform: rotate(-45deg);
      margin-bottom: 65px;
    }

    @media all and (max-width: 1023px) {
      .title {
        font-size: 45px;

        &::after {
          width: 30px;
          height: 3px;
          margin: 20px auto;
        }
      }

      .text {
        font-size: 14px;
        max-width: 440px;
        margin-bottom: 25px;
      }

      .arrow { margin-bottom: 25px; }
    }

    @media all and (max-width: 767px) {
      .title {
        font-size: 20px;
        max-width: 200px;
        letter-spacing: 3px;

        &::after { margin: 20px auto 40px; }
      }

      .text {
        max-width: 300px;
        margin-bottom: 25px;
      }

      .button { margin-bottom: 15px; }

      .arrow {
        width: 20px;
        height: 20px;
        margin-bottom: 45px;
      }
    }
  }

  .create_gifts_container {
    margin: 0 auto;
    max-width: 750px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .create_gifts_title {
      width: 100%;
      text-align: center;
      font-family: $germ;
      font-size: 25px;
      text-transform: uppercase;
      font-weight: 700;

      @include after_line($orange, 15px auto 65px);
    }

    .create_gifts_cell {
      flex-basis: 150px;

      .img {
        width: 195px;
        height: auto;
        margin: auto;
        border-radius: 50%;

        @media all and (max-width: 767px) { width: 140px; }
      }
    }

    .subtitle {
      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      margin-top: 35px;
      line-height: 1.4;
      &::first-line { color: $orange; }
    }

    .orange { display: block; }

    @media all and (max-width: 767px) {
      padding: 0 10px;

      .create_gifts_title { font-size: 20px; }

      .create_gifts_cell { margin: 0 auto 20px; }

      .subtitle {
        font-size: 14px;
        margin-top: 20px;
      }
    }
  }

  .how_work_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 155px;
    background: url('/static/img/page_gifts/bg_blue_cloud.png') no-repeat bottom center;

    &::before {
      content: '';
      display: block;
      width: 91px;
      height: 77px;
      background: url('/static/img/page_gifts/next_arrow.png') no-repeat center;
      background-size: cover;
      margin: 55px auto;
    }

    .title {
      font-family: $germ;
      font-size: 25px;
      text-transform: uppercase;
      font-weight: 700;

      &.blue { color: $blue; }

      @include after_line($orange, 15px auto 20px);
    }

    .text {
      font-family: $c35;
      text-align: center;
      font-size: 17px;
      line-height: 1.4;
      max-width: 560px;
      margin-bottom: 40px;
    }

    .how_work_subtext {
      display: block;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 60px;
      line-height: 1.4;
    }

    .desktop {
      width: 814px;
      height: auto;
      margin-bottom: 25px;

      @media all and (max-width: 1023px) {
        width: 625px;
        margin-bottom: 65px;
      }
      @media all and (max-width: 767px) { display: none; }
    }

    .phone {
      width: 216px;
      height: auto;
      margin-bottom: 25px;

      @media all and (min-width: 768px) { display: none; }
    }

    .button { margin-bottom: 60px; }

    .delivery_container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .delivery_banner {
        margin-bottom: 65px;

        &.mobile { display: none; }

        @media all and (max-width: 1023px) {
          width: 100%;
          height: auto;
          padding: 0 10px;
        }

        @media all and (max-width: 767px) {
          &.mobile {
            display: block;
            height: 100px;
            object-fit: cover;
          }
          &.desktop { display: none; }
        }
      }

      .delivery_text {
        text-align: center;
        text-transform: uppercase;
        line-height: 1.4;
        margin-bottom: 40px;
        padding: 0 10px;
      }

      .open_popup {
        display: inline-block;
        color: $blue;
        border-bottom: 1px dashed $blue;

        &:hover { border-bottom-color: transparent; }
      }

      .calendar {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 230px;
        height: 246px;
        padding-top: 38px;
        background: url('/static/img/page_gifts/calendar.png') no-repeat center;

        .day,
        .month {
          font-family: $germ;
          font-size: 22px;
          color: #fff;
          text-transform: uppercase;
        }

        .number {
          font-size: 120px;
          font-family: $germ;
          line-height: 1;
          color: $blue;
          margin-top: 20px;
        }

        .month {
          color: $blue;
          font-weight: 700;
        }
      }
    }

    @media all and (max-width: 767px) {
      &::before {
        width: 64px;
        height: 54px;
        margin: 30px auto 45px;
      }

      .title { font-size: 20px; }

      .text {
        font-size: 14px;
        padding: 0 10px;
      }

      .how_work_subtext {
        margin-bottom: 40px;
        padding: 0 10px;
      }
    }
  }

  .create_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    .title,
    .subtitle {
      font-family: $germ;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
    }

    .title {
      font-size: 25px;
      @include after_line($orange, 15px auto 30px);

      @media all and (max-width: 479px) {
        font-size: 16px;

        &::after { margin-bottom: 15px; }
      }
    }

    .subtitle {
      font-size: 40px;
      margin-bottom: 100px;

      @media all and (max-width: 479px) {
        font-size: 20px;
        margin-bottom: 25px;
      }
    }

    .motive_slider {
      width: 1240px;
      margin-bottom: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .motive_item {
        padding: 5px 0;

        &.active .motive_img { box-shadow: 0 0 0 5px #fef1e7; }
      }

      .motive_img {
        width: 130px;
        height: auto;
        border-radius: 50%;
        margin: auto;

        @media all and (max-width: 1279px) { width: 110px; }
        @media all and (max-width: 1023px) { width: 80px; }
        @media all and (max-width: 479px) { width: 60px; }
      }

      .slick-list { width: calc(100% - 82px); }

      .slick-arrow {
        width: 41px;
        height: 26px;
        border: none;
        outline: none;
        font-size: 0;
        background: transparent url('/static/img/page_gifts/slider_arrow.png') no-repeat center;
        background-size: cover;
        cursor: pointer;

        @media all and (max-width: 479px) {
          width: 29px;
          height: 19px;
        }

        &.slick-prev { transform: rotate(180deg); }
      }

      @media (min-width: 1024px) and (max-width: 1279px) { width: 1000px; }
      @media all and (max-width: 1023px) { width: calc(100% - 20px); }
    }
  }

  .catalog_container {
    position: relative;
    width: 1240px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 auto 95px;

    @media all and (max-width: 1279px) { width: 1000px; }

    @media all and (max-width: 1023px) {
      width: 100%;
      padding: 0 10px;
    }
  }

  .catalog_cell {
    @include catalog_cell();
    width: 24.25%;
    margin: 0 1% 20px 0;
    &:nth-of-type(3n) { margin-right: 1%; }
    &:nth-of-type(4n) { margin-right: 0; }

    @media all and (max-width: 1023px) {
      max-width: 32%;
      width: 31.7%;
      margin: 0 2% 15px 0;
      &:nth-of-type(3n) { margin-right: 0; }
      &:nth-of-type(4n) { margin-right: 2%; }

      .gallery_wrap {
        min-width: 140px;
        min-height: 140px;
      }
      .catalog_cell_text {
        padding: 20px 10px;

        .catalog_cell_title {
          font-size: 12px;
        }
      }
    }

    @media all and (max-width: 600px) {
      width: 49%;
      max-width: 49%;
      margin: 0 2% 10px 0;
      padding-bottom: 60px;
      &:nth-of-type(2n) { margin-right: 0; }
      &:nth-of-type(3n) { margin-right: 2%; }

      .catalog_cell_text {
        padding: 10px;

        .catalog_cell_title { font-size: 9px; }
        .catalog_cell_price { font-size: 18px; }
      }
    }
  }
}