.page.catalog_page_container.page_photo {
  .filtr_container {
    .lbl {
      a {
        font-family: $c35;
        font-size: 15px;
      }
    }
  }
  .catalog_container {
    .print{
      h1 {
        font-family: gerberamedium;
        font-size: 25px;
        font-weight: bold;
        color: $grey_font;;  
        text-transform: uppercase;
        letter-spacing: normal;
        display: block;
        margin-bottom: 12px;
      }
    }
   
   
    h1 {
      font-family: $c35;
      font-size: 18px;
      color: $grey_font_light;
      text-transform: none;
      margin: 0 0 25px 0;
      &:after {
        content: none;
      }
    }
    h2 {
      font-family: $germ;
      font-size: 25.12px;
      font-weight: bold;
      color: $grey_font;
      text-transform: uppercase;
      letter-spacing: 1.6px;
      margin-bottom: 12px;
      // @include after_line($orange, 8px 0 0 0);
    }
    h3 {
      font-family: $c45;
      font-size: 20px;
      color: $grey_font;
      text-transform: uppercase;
      margin-bottom: 12px;
      @include after_line($orange, 8px 0 0 0);
    }
    p {
      font-family: $c35;
      font-size: 17px;
      color: $grey_font;
      margin: 0 0 28px 0;
      text-align: justify;
      .city {
        background: none;
        border: none;
        outline: none;
        font-family: $c35;
        font-size: 14px;
        border-bottom: 1px dotted $grey_font;
        cursor: pointer;
      }
      em {
        font-family: $c35;
        font-weight: normal;
      }
    }
    table {
      width: 100%;
      max-width: 790px;
      border-spacing: 0;
      margin: 0 0 10px 0;
      tbody {
        tr {
          &.header {
            td {
              background: #f0f4f6;
              border: 1px solid white;
              border-top: none;
            }
          }
          td {
            text-align: center;
            padding: 6px;
            font-family: $c35;
            font-size: 15px;
            border-bottom: 1px solid #f0f4f6;
            span {
              display: block;
              &:nth-of-type(2) {
                font-size: 12px;
                color: $grey_font_light;
              }
            }
          }
        }
      }
    }
    ul {
      margin: 0 0 32px 0;
      li {
        font-family: $c35;
        font-size: 15px;
        color: $grey_font;
        &:before {
          $radius: 5px;
          content: '';
          display: inline-block;
          width: $radius;
          height: $radius;
          background: $orange;
          border-radius: 50%;
          margin: 0 10px 3px 0;
        }
      }
    }
    .photo_print {
      margin: 0 0 70px 0;
      .about {
        margin: 0 0 42px 0;
      }
      .photo_paper {
        margin: 0 0 45px 0;
        .format_list {
          padding: 0 10px;
          display: grid;
          grid-gap: 20px;
          grid-template-columns: repeat(5, 1fr);
          align-items: baseline;
          .format {
            position: relative;
            display: grid;
            justify-items: center;
            align-content: start;
            &:hover {
              button {
                background: $orange;
                color: white;
              }
            }
            .submit {
              position: absolute;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }
            .img_wrapper {
              height: 110px;
              display: flex;
              align-items: flex-end;
              img {
                width: 100%;
                margin: 0 0 8px 0;
              }
            }
            .wholesale_prices {
              display: grid;
              justify-items: center;
              margin: 0 0 8px 0;
              span {
                color: $grey_font_light;
                white-space: nowrap;
                font-family: $c35;
                font-size: 15px;
                mark {
                  background: none;
                  color: $green;
                }
              }
            }
            .price {
              margin: 0 0 8px 0;
              white-space: nowrap;
              .old {
                font-family: $c45;
                font-size: 18px;
                color: #a8b2ba;
                text-decoration: line-through;
              }
              .current {
                font-family: $c45;
                font-size: 18px;
                color: $green;
              }
            }
            button {
              text-transform: uppercase;
              background: none;
              outline: none;
              border: 1px solid $orange;
              border-radius: 3px;
              padding: 10px;
              font-family: $c45;
              font-size: 14px;
              color: $orange;
              white-space: nowrap;
              cursor: pointer;
              &:after {
                display: block;
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        p {
          font-size: 15px;
        }
      }
    }
    .formats {
      margin: 0 0 20px 0;
      table {
        td {
          white-space: nowrap;
        }
        td:last-child {
          display: none;
        }
      }
    }
  }
  
  @include small_desktop {
    .catalog_container {
      .photo_print {
        .photo_paper {
          p {
            margin: 0 0 15px 0;
          }
          .format_list {
            .format {
              .img_wrapper {
                height: 90px;
              }
            }
          }
        }
      }
    }
  }
  @include tablet {
    .catalog_container {
      .photo_print {
        .photo_paper {
          p {
            margin: 0;
          }
          .format_list {
            grid-template-columns: repeat(4, 1fr);
            .format {
              .img_wrapper {
                height: 145px;
              }
            }
          }
        }
      }
    }
  }
  @include big_phone {
    .catalog_container {
      .photo_print {
        .photo_paper {
          .format_list {
            grid-template-columns: repeat(3, 1fr);
            .format {
              .img_wrapper {
                height: 135px;
              }
            }
          }
        }
      }
    }
  }
  @include phone {
    .catalog_container {
      .photo_print {
        .photo_paper {
          .format_list {
            grid-template-columns: repeat(2, 1fr);
            .format {
              .img_wrapper {
                height: 125px;
              }
            }
          }
        }
      }
    }
  }
}

