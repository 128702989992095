.warranties {
  width: 1240px;
  margin: 0 auto 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  .warranty {
    width: 100%;
    display: grid;
    grid-template-columns: min-content 1fr;
    padding: 0 0 0 20px;
    &.delivery .icon { @extend .sprite-sprite_images-delivery; }
    &.simple-editor .icon { @extend .sprite-sprite_images-editor; }
    &.money-back .icon { @extend .sprite-sprite_images-money; }
    &.quality .icon { @extend .sprite-sprite_images-garant; }
    .icon {
      flex-shrink: 0;
      margin-right: 25px;
    }
    p {
      font-family: $c35;
      font-size: 15px;
      line-height: 1.5;
    }
    a {
      font-family: inherit;
      color: $blue_hover;
      text-decoration: underline;
    }
  }
}

@include small_desktop() {
  .warranties {
    width: 1000px;
    p { font-size: 14px; }
  }
}
@include tablet() {
  .warranties {
    width: 100%;
    .warranty {
      padding: 0 10px 0 10px;
    }
  }
}
@include big_phone() {
  .warranties {
    grid-template-columns: 1fr 1fr;
    .warranty {
      margin: 0 0 20px 0;
    }
  }
}
@include phone() {
  .warranties {
    grid-template-columns: 1fr;
    .warranty {
      grid-template-columns: 84px 1fr;
      .icon {
        justify-self: center;
      }
    }
  }
}