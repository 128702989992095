main {
  width: 100%;
  flex: 1;
  background: #fff;
  margin: auto;
  .overlay {
    position: fixed;
    z-index: 998;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    background: transparent;
    @media screen and (max-device-width: 1024px) {
      cursor: pointer;
    }
  }
  #swipe_area {
    position: fixed;
    z-index: 9999;
    display: block;
    width: 10px;
    height: 100%;
    left: 0;
    top: 0;
    background: transparent;
  }
  .password_reset {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    .note {
      font-family: $c35;
      font-size: 15px;
      line-height: 1.5;
      margin-bottom: 20px;
      text-align: center;
    }
    label {
      display: block;
      font-size: 14px;
      margin-bottom: 3px;
    }
    input:not([type="submit"]) {
      display: block;
      width: 100%;
      margin-bottom: 15px;
      font-size: 15px;
      padding: 10px 5px;
      border: 1px solid lightgray;
      border-radius: 3px;
    }
    .button {
      @include button($orange,$orange_hover);
      display: block;
      margin: 10px auto;
    }
  }
}