.super_services {
  border-collapse: collapse;
  font-family: $c35;
  font-size: 16px;
  margin: 20px 10px 25px;
  td {
    border: 1px solid lightgrey;
    font-family: $c35;
    font-size: 16px;
    padding: 8px 22px;
    height: 56px;
  }
  td:nth-of-type(2) {
    white-space: nowrap;
  }
}
.page.product {
  margin-bottom: 20px;
  &.not_available {
    .content {
      .options_product {
        .available {
          .true { display: none }
          .false { display: block }
        }
      }
    }
  }
  main.content {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 20px;
    align-items: start;
    .catalog_container{
      grid-column: 1/3;
    }

    h1 {
        font-family: $c45;
        font-size: 22px;
        text-transform: unset;
        line-height: 1;
        margin-bottom: 10px;
        &:after {
          display: none;
        }
      }
    h2 {
      font-family: $c55;
      font-size: 22px;
      text-transform: uppercase;
      margin-bottom: 13px;
    }
    p {
      font-family: $c35;
      font-size: 15px;
      font-weight: normal;
      margin-bottom: 13px;
      line-height: 22px;
    }
    ul {
      li {
        font-family: $c35;
        font-size: 15px;
        font-weight: normal;
        margin-bottom: 5px;
        &:before {
          content: '';
          display: inline-block;
          width: 5px;
          height: 5px;
          background: $orange;
          border-radius: 50%;
          margin: 0 10px 2px 0;
        }
        mark, .gray {
          color: $grey_font_light;
          font-family: $c35;
          span {
            font-family: $c35;
          }
        }
      }
    }
    .gallery_product {
      display: grid;
      grid-template-columns: 115px 489px;
      grid-gap: 20px;
      align-items: flex-start;
      margin-bottom: 20px;
      .img-viewer.preview {
        grid-template-rows: repeat(4, 1fr);
        .item {
          border: 1px solid transparent;
          cursor: pointer;
          &.active {
            border-color: $orange;
          }
        }
        .button {
          height: auto;
          width: 100%;
          &.prev {
            top: -20px;
            .icon {
              transform: rotate(90deg);
            }
          }
          &.next {
            bottom: -20px;
            .icon {
              transform: rotate(-90deg);
            }
          }
        }
      }
      .img-viewer.photo {
        margin-bottom: 20px;
      }
    }
    .options_product {
      margin-bottom: 20px;
      .price {
        font-family: $c45;
        font-size: 30px;
        margin-bottom: 10px;
        .old {
          color: $grey_font_light;
          text-decoration: line-through;
        }
        .current {
          color: $green;
        }
      }
      .wholesale_price {
        margin-bottom: 10px;
        font-family: $c45;
        font-size: 14px;
        mark {
          background: none;
          font-family: $c45;
          font-size: 14px;
          color: $green;
        }
        button {
          background: none;
          outline: none;
          border: none;
          font-family: $c45;
          font-size: 14px;
          border-bottom: 1px dashed $grey_font;
          cursor: pointer;
        }
      }
      .available {
        .true, .false {
          font-family: $c45;
          font-size: 14px;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          white-space: nowrap;
          &:before {
            content: '';
            display: inline-block;
            margin-right: 3px;
          }
        }
        .true {
          color: $green;
          &:before { @extend .sprite-sprite_images-presence }
        }
        .false {
          color: $red;
          &:before { @extend .sprite-sprite_images-unable }
          display: none;
        }
      }
      .additional_services {
        display: grid;
        margin-bottom: 15px;
        span {
          font-family: $c45;
          font-size: 14px;
          color: $grey_font_light;
          mark {
            color: $green;
            background: none;
          }
        }
      }
      .coupons {
        display: grid;
        span {
          font-family: $c45;
          font-size: 14px;
          color: $grey_font_light;
          margin-bottom: 10px;
          mark {
            color: $green;
            background: none;
          }
        }
      }
      .characteristics {
        font-family: $c35;
        font-size: 16px;
        font-weight: normal;
        p {
          font-family: inherit;
          font-size: inherit;
          font-weight: inherit;
        }
      }
     
      .type {
        margin-bottom: 15px;
        .select {
          margin-bottom: 32px;
          span {
            font-family: $c45;
            font-size: 14px;
            color: $grey_font_light;
            display: block;
            margin-bottom: 8px;
          }
          select {
            background: none;
            border: 1px solid lightgrey;
            border-radius: 2px;
            font-family: $c35;
            font-size: 15px;
            cursor: pointer;
            width: 360px;
            option {
              font-family: inherit;
              font-size: inherit;
            }
          }
        }
        .img-viewer {
          margin-bottom: 25px;
          padding: 0 35px 0;
          grid-template-columns: repeat(3, 1fr);
          width: 360px;
          &.no-buttons {
            padding: 0;
          }
          .button {
            &.next .icon {
              transform: rotate(180deg);
            }
            .icon {
              @include slider_button();
              margin: 0 5px 0 5px;
            }
          }
          .item {
            border: 1px solid transparent;
            &.active {
              border-color: $orange;
            }
          }
        }
        .t_shirt_size {
          font-family: $c45;
          font-size: 14px;
          color: $grey_font_light;
          border-bottom: 1px dashed $grey_font_light;
          display: inline-block;
          margin-bottom: 15px;
          white-space: nowrap;
        }
        .table {
          display: flex;
          flex-wrap: wrap;
          a {
            border: 1px solid lightgrey;
            padding: 5px;
            display: block;
            margin: 0 10px 10px 0;
            white-space: nowrap;
            &.active {
              border-color: $orange;
            }
            &.not_available {
              cursor: default;
              opacity: 0.3;
            }
          }
        }
      }
      .buy {
        display: grid;
        grid-gap: 20px;
        form, button, a {
          width: 80%;
        }
        form {
          input[type=submit] {
            @include button($orange, $orange_hover);
            width: 100%;
          }
        }
        button {
          @include button($orange, $orange_hover);
          margin: 0;
        }
        a {
          @include button($blue, $blue_hover);
        }
      }
    }
    .description_product {
      margin-bottom: 20px;
      grid-column: 1/3;
      p, ul li, span{
        font-size: 16px;
      }
    }
    .order_with_designer {
      grid-column: 1/2;
      margin-bottom: 20px;
      form {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-right: 436px;
        grid-gap: 12px;
        align-items: start;
        .data-text {
          display: grid;
          align-content: start;
          span {
            margin-bottom: 4px;
          }
          input { @include input }
        }
        .data-text:nth-of-type(4) {
          grid-column: 1/4;
          margin-top: 12px;
          position: relative;
          span {
            margin-bottom: 13px;
          }
          .questions {
            position: absolute;
            top: 0;
            right: -350px;
            display: grid;
            grid-gap: 19px;
            span {
              font-family: $c35;
              font-size: 16px;
              margin-bottom: 0;
              mark {
                background: transparent;
              }
            }
          }
          textarea {
            @include input;
            height: 148px;
            margin-bottom: 10px;
          }
        }
        .file-set {
          grid-column: 1/4;
          display: flex;
          flex-wrap: wrap;
          .file {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 132px;
            height: 132px;
            margin-right: 10px;
            margin-bottom: 10px;
            border: 1px solid #d5dbde;
            border-radius: 2px;
            overflow: hidden;
            &.template {
              display: none;
            }
            &.loaded {
              input {
                width: 0;
                height: 0;
                display: none;
              }
              .close {
                display: block;
              }
              .load {
                display: none;
              }
            }
            input {
              position: absolute;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }
            img.content {
              width: 100%;
            }
            span.content {
              display: block;
              text-align: center;
              font-family: $c35;
              font-size: 14px;
              color: black;
              width: 132px;
              padding: 0 12px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .close {
              width: 20px;
              height: 20px;
              background: rgba(0,0,0,0.4);
              border: none;
              border-radius: 50%;
              position: absolute;
              top: 5px;
              right: 5px;
              display: none;
              cursor: pointer;
              span {
                display: block;
                width: 10px;
                height: 2px;
                background: white;
                position: absolute;
                left: 5px;
                top: 9px;
                transform: rotate(-45deg);
                &:nth-of-type(2) {
                  transform: rotate(45deg);
                }
              }
            }
            .load {
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: $c35;
              font-size: 14px;
              color: $blue;
              width: 100%;
              height: 100%;
              padding: 0 12px;
              background: none;
              border: none;
              outline: none;
            }
          }
        }
        input[type=submit] {
          grid-column: 1/4;
          justify-self: center;
          width: 240px;
          @include button($orange, blue);
        }
      }
    }
    .success_order{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      img{
        width: 15%;
        margin-bottom: 20px;
      }
      h3{
        margin-bottom: 20px;
      }
    }
    .order_with_manager {
      grid-column: 1/2;
      margin-bottom: 20px;
      form {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-right: 436px;
        grid-gap: 12px;
        align-items: start;
        .data-text {
          display: grid;
          align-content: start;
          span {
            margin-bottom: 4px;
          }
          input { @include input }
        }
        .data-text:nth-of-type(4) {
          grid-column: 1/3;
          margin-top: 12px;
          position: relative;
          span {
            margin-bottom: 13px;
          }
          .questions {
            position: absolute;
            top: 0;
            right: -250px;
            display: grid;
            grid-gap: 19px;
            span {
              font-family: $c35;
              font-size: 16px;
              margin-bottom: 0;
              mark {
                background: transparent;
              }
            }
          }
          textarea {
            @include input;
            height: 148px;
            margin-bottom: 10px;
          }
        }
        .file-set {
          grid-column: 1/4;
          display: flex;
          flex-wrap: wrap;
          .file {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 132px;
            height: 132px;
            margin-right: 10px;
            margin-bottom: 10px;
            border: 1px solid #d5dbde;
            border-radius: 2px;
            overflow: hidden;
            &.template {
              display: none;
            }
            &.loaded {
              input {
                width: 0;
                height: 0;
                display: none;
              }
              .close {
                display: block;
              }
              .load {
                display: none;
              }
            }
            input {
              position: absolute;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }
            img.content {
              width: 100%;
            }
            span.content {
              display: block;
              text-align: center;
              font-family: $c35;
              font-size: 14px;
              color: black;
              width: 132px;
              padding: 0 12px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .close {
              width: 20px;
              height: 20px;
              background: rgba(0,0,0,0.4);
              border: none;
              border-radius: 50%;
              position: absolute;
              top: 5px;
              right: 5px;
              display: none;
              cursor: pointer;
              span {
                display: block;
                width: 10px;
                height: 2px;
                background: white;
                position: absolute;
                left: 5px;
                top: 9px;
                transform: rotate(-45deg);
                &:nth-of-type(2) {
                  transform: rotate(45deg);
                }
              }
            }
            .load {
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: $c35;
              font-size: 14px;
              color: $blue;
              width: 100%;
              height: 100%;
              padding: 0 12px;
              background: none;
              border: none;
              outline: none;
            }
          }
        }
        input[type=submit] {
          grid-column: 1/4;
          justify-self: center;
          width: 240px;
          @include button($orange, blue);
        }
      }
    }
    .delivery_product {
      display: none;
      grid-column: 1/3;
      margin-bottom: 12px;
      font-family: $c35;
      font-size: 15px;
      span {
        font-family: $c35;
        font-size: 15px;
      }
      mark, .gray {
        color: $grey_font_light;
      }
      button {
        background: none;
        border: none;
        outline: none;
        font-family: $c45;
        font-size: 14px;
        border-bottom: 1px dashed $grey_font;
        cursor: pointer;
      }
    }
    .wholesale_prices {
      grid-column: 1/3;
      margin-bottom: 25px;
      p {
        font-size: 16px;
      }
      table {
        margin: 15px 0 0 10px;
        border-collapse: collapse;
        th {
          font-family: $c45;
          font-size: 12px;
          color: $grey_font_light;
          text-transform: uppercase;
          text-align: left;
          padding: 5px 20px 0;
          &:nth-of-type(odd) {
            text-align: right;
          }
        }
        td {
          font-family: $c35;
          font-size: 16px;
          color: $grey_font;
          border-bottom: 1px solid #d5dbde;
          padding: 9px 20px;
          &:nth-of-type(odd) {
            text-align: right;
          }
        }
        tbody tr:last-of-type td {
          border: none;
        }
      }
    }
    .reviews_product {
      grid-column: 1/3;
      margin-bottom: 40px;
      h2 {
        margin-bottom: 18px;
      }
      .review {
        display: grid;
        grid-template-columns: min-content 1fr;
        grid-column-gap: 20px;
        margin-bottom: 20px;
        .product_link_image {
          grid-row: 1/4;
          img {
            max-width: 160px;
          }
        }
        .author_review {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .name {
            font-family: $c45;
            font-size: 16px;
            width: 100%;
            margin-bottom: 5px;
          }
          .rating {
            @include rate_star();
            margin-bottom: 0;
            margin-right: 5px;
            .star {
              transform: scale(0.8);
              margin-left: -4px;
            }
          }
          .instagram {
            font-family: $c45;
            font-size: 12px;
            color: $grey_font_light;
            margin-right: 5px;
            &:before {
              content: '';
              display: inline-block;
              margin-right: 5px;
              margin-bottom: -5px;
              @extend .sprite-sprite_images-instagram_review;
            }
          }
          .date {
            font-family: $c45;
            font-size: 12px;
            color: $grey_font_light;
            white-space: nowrap;
          }
        }
        .content_review {
          .text {
            margin-top: 16px;
            line-height: 21px;
          }
          .photos {
            display: flex;
            margin-bottom: 5px;
            .photo {
              margin-right: 10px;
              img {
                max-width: 90px;
                max-height: 90px;
              }
            }
          }
          .answer {
            padding: 10px;
            margin-left: 24px;
            margin-top: 20px;
            margin-right: 10px;
            background-color: $grey_back;
            display: flex;
            flex-direction: column;
            span {
              font-size: 15px;
              font-family: $c55;
              margin-bottom: 10px;
            }
            p {
              font-family: $c35;
            }
          }
        }
      }
      .actions {
        display: flex;
        .all_reviews, .add_review a, .add_review button {
          @include button_link;
          margin-right: 12px;
        }
      }
    }

  }
  @include small_desktop {
    main.content {
      .gallery_product {
        grid-template-columns: 90px 389px;
      }
    }
  }
  @media (max-width: 767px) {
    main.content {
      .catalog_container{
        grid-column: 1;
      }
    }
  }
  @include tablet {
    main.content {
      grid-template-columns: 1fr 1fr;
      .options_product {
        .super_services {
          td {
            padding: 8px 12px;
          }
        }
      }
      .gallery_product {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        .img-viewer.preview {
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: auto;
          max-width: 510px;
          .button {
            width: auto;
            height: 100%;
            &.prev {
              top: 0;
              .icon {
                transform: rotate(0deg);
              }
            }
            &.next {
              bottom: auto;
              .icon {
                transform: rotate(-180deg);
              }
            }
          }
        }
        .img-viewer.photo {
          max-width: 500px;
        }
      }
      .order_with_designer {
        form {
          grid-template-columns: auto;
          margin-right: 368px;
          .data-text:nth-of-type(4) {
            grid-column: auto;
          }
          .file-set {
            grid-column: auto;
          }
          input[type=submit] {
            grid-column: auto;
          }
        }
      }
      .order_with_manager {
        form {
          grid-template-columns: auto;
          margin-right: 368px;
          .data-text:nth-of-type(4) {
            grid-column: auto;
          }
          .file-set {
            grid-column: auto;
          }
          input[type=submit] {
            grid-column: auto;
          }
        }
      }
    }
  }
  @include big_phone {
    main.content {
      grid-template-columns: 1fr;
      .options_product {
        .super_services {
          margin: 20px 0 25px;
        }
        .buy {
          justify-items: center;
        }
      }
      .description_product {
        grid-column: auto;
      }
      .order_with_designer {
        grid-column: auto;
        form {
          margin-right: auto;
          .data-text:nth-of-type(4) {
            .questions {
              position: static;
              margin-bottom: 20px;
              grid-gap: 10px;
            }
          }
        }
      }
      .order_with_manager {
        grid-column: auto;
        form {
          margin-right: auto;
          .data-text:nth-of-type(4) {
            .questions {
              position: static;
              margin-bottom: 20px;
              grid-gap: 10px;
            }
          }
        }
      }
      .delivery_product {
        grid-column: auto;
      }
      .wholesale_prices {
        grid-column: auto;
      }
      .reviews_product {
        grid-column: auto;
        .review {
          grid-template-columns: 1fr;
          .product_link_image {
            grid-row: auto;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  @include phone {
    main.content {
      .options_product {
        .type {
          .select {
            select {
              width: 300px;
            }
          }
          .img-viewer {
            width: 300px;
          }
        }
        .buy {
          form, button, a {
            width: 100%;
          }
        }
      }
      .reviews_product {
        .review {
          grid-template-columns: 70px 1fr;
          grid-gap: 10px;
          align-items: start;
          .product_link_image {
            margin: 0;
            img {
              width: 100%;
            }
          }
          .content_review {
            grid-column: 1/3;
            .text {
              margin-top: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
      .catalog_container{
        grid-column: 1;
      }
    }
  }
}
