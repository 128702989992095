.page.catalog_page_container.blog {
  .catalog_container {
    padding-bottom: 80px;
    p {
      margin-bottom: 20px !important;
    }
    h1 {
      font-family: $germ;
      font-size: 30px;
      font-weight: bold;
      color: $grey_font;
      letter-spacing: 2.5px;
      margin-top: 0;
      margin-bottom: 15px;
      text-align: center;
      &:after {
        display: none;
      }
    }
    h2 {
      font-family: $c45;
      font-size: 20px;
      color: $grey_font;
      text-transform: uppercase;
      letter-spacing: 0.2px;
      margin-top: 30px;
      margin-bottom: 22px;
      text-align: center;
      &:before {
        content: '';
        display: block;
        width: 30px;
        height: 3px;
        background: $orange;
        margin: 0 auto 18px;
      }
    }
    h3 {
      margin-top: 0;
      margin-bottom: 0;
    }
    time {
      font-family: $c35;
      font-size: 13px;
      color: $grey_font_light;
      margin-top: 0;
      margin-bottom: 25px;
      text-align: center;
      width: 100%;
      display: block;
    }
    p {
      font-family: $c35;
      font-size: 17px;
      color: $grey_font;
      line-height: 25px;
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 0;
      > a {
        text-decoration: underline;
      }
    }
    img {
      max-width: calc(100% - 80px);
      margin: 16px auto;
    }
    ul {
      margin-left: 20px;
      margin-bottom: 20px;
      li {
        list-style: none;
        font-family: $c35;
        font-size: 17px;
        color: $grey_font;
        line-height: 25px;
        position: relative;
        &:before {
          content: '';
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: $orange;
          margin-right: 16px;
          margin-bottom: 2px;
        }
        > ul {
          margin-bottom: 0;
        }
      }
    }
    ol {
      margin-left: 20px;
      margin-bottom: 20px;
      li {
        list-style: decimal inside;
        font-family: $c35;
        font-size: 17px;
        color: $grey_font;
        line-height: 25px;
        > ol {
          margin-bottom: 0;
        }
      }
    }
    iframe {
      margin: 16px auto 30px;
      display: block;
      max-width: 100%;
    }
    hr {
      display: none;
    }
    a {
      font-family: $c35;
      font-size: 17px;
      color: #1161b7;
      margin-top: -10px;
      margin-bottom: 0;
      &.button {
        display: inline-block;
        padding: 16px 24px;
        background: $orange;
        font-size: 16px;
        text-transform: uppercase;
        color: white;
        text-decoration: none;
        font-weight: bold;
        text-align: center;
        margin-top: 32px;
        border-radius: 2px;
        &:hover {
          background: $orange_hover;
        }
      }
    }
    .catalog_blog {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 15px;
      a {
        border: 1px solid #e5e9eb;
        cursor: pointer;
        margin-top: 0;
        .image {
          display: flex;
          align-items: center;
          overflow: hidden;
          // height: 192px;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            margin: 0;
            max-width: none;
          }
        }
        h3 {
          margin: 20px;
          font-family: $c45;
          font-size: 14px;
          font-weight: bold;
          color: $grey_font;
          letter-spacing: 0.35px;
          text-transform: uppercase;
        }
      }
    }
    .subscription {
      width: 100%;
      display: block;
      #email_rss {
        margin: 0 auto;
        padding: 30px;
        > .icon {
          margin: 0 auto 13px;
        }
        h2 {
          margin: 0 0 0;
          &:before {
            display: none;
          }
        }
        p {
          font-size: 15px;
          line-height: normal;
        }
      }
    }
    @include comments_form();
  }
}

@include tablet {
  .page.catalog_page_container.blog {
    .catalog_container {
      padding-bottom: 30px;
      .catalog_blog {
        grid-gap: 10px;
      }
    }
  }
}
@include big_phone {
  .page.catalog_page_container.blog {
    .catalog_container {
      .subscription {
        #email_rss {
          width: 100%;
          height: auto;
          form {
            width: auto;
            input[type=email] {
              width: auto;
            }
          }
        }
      }
      .catalog_blog {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
@include phone {
  .page.catalog_page_container.blog {
    .catalog_container {
      .catalog_blog {
        grid-template-columns: 1fr;
      }
    }
  }
}
