@mixin small_desktop {
  @media all and (max-width: 1279px) {
    @content;
  }
}

@mixin tablet {
  @media all and (max-width: 1023px) {
    @content;
  }
}

@mixin big_phone {
  @media all and (max-width: 767px) {
    @content;
  }
}

@mixin phone {
  @media all and (max-width: 479px) {
    @content;
  }
}

.catalog_page_container {
  @mixin title() {
    font-family: $germ;
    font-size: 25.12px;
    font-weight: bold;
    color: $grey_font;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    display: block;
    margin-bottom: 12px;
    @include after_line($orange, 8px 0 0 0);
    @include big_phone {
      font-size: 20px;
    }
  }
  @mixin slogan() {
    font-family: $c35;
    font-size: 17px;
    color: $grey_font;
    margin: 5px 0 17px;
    text-align: justify;
  }
  .aside {
    @include aside_menu;
  }
  .breadcrumbs {
    display: none;
  }
  .filtr_container {
    .banner.applied {
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: white;
        opacity: 0.6;
      }
      &:after {
        content: 'Ваша скидка активина';
        display: block;
        position: absolute;
        font-family: $c45;
        font-size: 30px;
        letter-spacing: 1px;
        color: $orange;
        padding-bottom: 45px;
        font-weight: bold;
        text-align: center;
        text-shadow: 1px 1px 1px $grey_font;
        text-transform: uppercase;
      }
    }
  }
  .catalog_container {
    width: 100%;
    .banner {
      position: relative;
      margin-bottom: 25px;
      h1 {
        font-family: $c35;
        font-size: 18px;
        color: $grey_font_light;
        margin-bottom: 6px;
        text-transform: lowercase;
        &:first-letter {
          text-transform: uppercase;
        }
        &:after {
          content: none;
        }
      }
      figure {
        height: 360px;
        overflow: hidden;
        img {
          height: 100%;
        }
        @include small_desktop {
          height: 287px;
        }
        @include tablet {
          height: auto;
          img {
            width: 100%;
          }
        }
      }
      .banner_promo {
        display: block;
        margin: 20px auto 0;
        overflow: hidden;
        transition: opacity 0.2s;
        position: relative;
        &:hover {
          opacity: 0.9;
        }
        &.applied {
          display: flex;
          justify-content: center;
          align-items: center;
          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: white;
            opacity: 0.6;
          }
          &:after {
            content: 'Ваша скидка активина';
            display: block;
            position: absolute;
            width: 207px;
            font-family: $c45;
            font-size: 24px;
            letter-spacing: 1px;
            color: $orange;
            font-weight: bold;
            text-align: center;
            text-shadow: 1px 1px 1px $grey_font;
            text-transform: uppercase;
          }
        }
        img {
          margin: auto;
        }
        @include tablet {
          display: flex !important;
        }
      }
      @include big_phone {
        margin-bottom: 42px;
      }
    }
    .special {
      width: 100%;
      margin-bottom: 35px;
      h2 {
        @include title();
      }
      p {
       @include slogan();
      }
      .products {
        display: grid;
        grid-template-columns: repeat(3, calc(100% / 3));
        grid-row-gap: 20px;
        width: 100%;
        li {
          height: 100%;
          a {
            display: grid;
            grid-template-rows: min-content 18px 44px 99px min-content 14px min-content;
            border: 1px solid transparent;
            &:hover {
              border: 1px solid #e5e9eb;
            }
            img {
              width: 100%;
              min-width: 200px;
              min-height: 200px;
              margin-bottom: -30px;
            }
            .name {
              align-self: flex-end;
              font-family: $c45;
              font-size: 14px;
              font-weight: bold;
              color: $grey_font;
              letter-spacing: 0.3px;
              line-height: 18px;
              text-transform: uppercase;
              padding: 0 20px;
              max-height: 36px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .description {
              font-family: $c35;
              font-size: 15px;
              color: $grey_font;
              padding: 7px 20px 0;
              margin: 0;
              text-align: left;
              max-height: 82px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              &:first-letter {
                text-transform: uppercase;
              }
            }
            .price {
              padding: 0 20px;
              margin-bottom: 5px;
              .old {
                font-family: $c55;
                font-size: 22px;
                color: $grey_font_light;
                text-decoration: line-through;
                padding-right: 5px;
                text-transform: lowercase;
              }
              .current {
                font-family: $c55;
                font-size: 22px;
                color: $green;
                text-transform: lowercase;
                white-space: nowrap;
              }
            }
            .wholesale_price {
              padding: 0 20px;
              font-family: $c35;
              font-size: 14px;
              color: $grey_font;
              margin-bottom: 13px;
              mark {
                background: none;
                color: $green;
              }
              &.stub {
                visibility: hidden;
              }
            }
            .action {
              @include button($orange, $orange_hover);
              margin: 15px auto 20px;
              padding-top: 8px;
              padding-bottom: 8px;
              margin-bottom: 30px;
              font-size: 14px;
              white-space: nowrap;
            }
            .hit {
              position: relative;
              justify-self: flex-start;
              height: 18px;
              font-family: $c45;
              font-size: 9px;
              color: white;
              background: $red;
              text-transform: uppercase;
              display: flex;
              align-items: center;
              padding: 7px;
              margin-left: 20px;
              &:after {
                content: '';
                position: absolute;
                top: 0;
                right: -5px;
                display: inline-block;
                border-top: 9px solid $red;
                border-bottom: 9px solid $red;
                border-right: 4px solid transparent;
                width: 6px;
              }
              &.flag-0 {
                background: $green;
                &:after {
                  border-top-color: $green;
                  border-bottom-color: $green;
                }
              }
              &.stub {
                visibility: hidden;
              }
            }
          }
        }
        @include big_phone {
          grid-template-columns: 50% 50%;
        }
        @include phone {
          grid-template-columns: 100%;
        }
      }
    }
    .designs {
      width: 100%;
      margin-bottom: 80px;
      h2 {
        @include title();
      }
      p {
       @include slogan();
      }
      .catalog_block {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        .catalog_cell {
          @include catalog_cell();
          width: auto;
          margin: 0 5px 10px 5px;
          &:nth-of-type(3n) {
            margin-right: 5px;
          }
          .badge {
            width: auto;
            padding: 7px 7px;
            &.flag-1 {
              background: $orange;
              &:before {
                border-top-color: $orange;
              }
              &:after {
                border-left-color: $orange;
              }
            }
          }
          .catalog_cell_text {
            .catalog_cell_title {
              font-family: $c55;
              font-weight: 400;
              letter-spacing: 0;
            }
            .catalog_cell_price {
              font-family: $c55;
            }
          }
        }
        @include big_phone {
          grid-template-columns: 1fr 1fr;
        }
        @include phone {
          grid-template-columns: 1fr;
        }
      }
      @include big_phone {
        margin-bottom: 24px;
      }
    }
    .individual {
      width: 100%;
      h2 {
        @include title()
      }
      p {
       @include slogan();
      }
      img {
        margin-top: 74px;
        max-width: 100%;
        @include tablet {
          max-width: 80%;
          margin: 54px auto 0;
        }
        @include big_phone {
          max-width: 100%;
          margin: 34px auto 0;
        }
      }
      .actions {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin: 28px 0 0;
        li {
          counter-increment: li;
          padding-left: 54px;
          position: relative;
          margin: 0 0 30px 15px;
          max-width: 300px;
          &:before {
            content: counter(li);
            position: absolute;
            left: 0;
            width: 42px;
            line-height: 66px;
            display: flex;
            justify-content: flex-end;
            font-family: $germ;
            font-weight: bold;
            font-size: 80px;
            color: $orange;
          }
          span:nth-of-type(1) {
            font-family: $c45;
            font-size: 16px;
            text-transform: uppercase;
            display: block;
            margin-bottom: 8px;
          }
          span:nth-of-type(2) {
            font-family: $c35;
            font-size: 15px;
            display: block;
            &:first-letter {
              text-transform: uppercase;
            }
          }
        }
        @include big_phone {
          grid-template-columns: 1fr 1fr;
        }
        @include phone {
          grid-template-columns: 1fr;
        }
      }
    }
  }
  @include tablet {
    padding: 10px 10px 0;
    .breadcrumbs {
      display: flex;
      margin: 0 0 15px 0;
      padding: 0;
    }
  }
}
