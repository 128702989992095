.page_photoreviews {
  .photoreviews_container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    .jscroll-inner,
    .jscroll-added {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .photo_cell {
    position: relative;
    width: calc((100% - (20px * 5)) / 6);
    margin-right: 20px;
    margin-bottom: 20px;

    &:nth-child(6n) {
      margin-right: 0;
    }

    @media all and (max-width: 1023px) {
      width: calc((100% - (15px * 5)) / 6);
      margin-right: 15px;
      margin-bottom: 15px;
    }

    @media all and (max-width: 767px) {
      width: calc((100% - (15px * 3)) / 4);
      margin-right: 15px;
      margin-bottom: 15px;

      &:nth-child(6n) {
        margin-right: 15px;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    @media all and (max-width: 479px) {
      width: calc((100% - 20px) / 2);
      margin-right: 20px;
      margin-bottom: 20px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    &:hover .photo_overlay { display: flex; }

    .photo_img {
      display: block;
      width: 100%;
    }

    .photo_overlay {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.7);
      font-family: $c35;
      font-size: 13px;
      color: $grey_font;
    }
  }
}