* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.2;
  font-family: $c45;
  color: $grey_font;
  font-weight: 400;
  list-style: none;
  outline: none;
  //-webkit-appearance: none;
  //-moz-appearance: none;
  text-decoration: none;
  // touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

h1,
h2,
h3,
h4,
h5,
h6 { font-family: $c55; }

img {
  display: block;
  border: 0;
}

input[type="range"] { box-shadow: inset 0 0 0 9px #fff, inset 0 0 0 15px grey; }

body,
html {
  min-width: 320px;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

a { background-color: transparent; }

/* **************** HEADER ******************* */

header {
  width: 100%;
  position: relative;
  z-index: 100;
  background-color: white;
}

.banner_container {
  transition: all linear 0.2s;
  width: 100%;
  background: $blue;
  min-height: 5px;
  .banner {
    position: relative;
    width: 1240px;
    margin: 0 auto;
    padding: 15px 0;
    .close {
      @include close(calc(50% - 10px),0,#fff);
    }
    .city_block {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 20px;
      &.sale { justify-content: center; }
      .triangle {
        content: '';
        position: absolute;
        z-index: 21;
        bottom: -25px;
        display: block;
        width: 20px;
        height: 20px;
        background: $blue;
        transform: rotate(45deg);
      }
      > p {
        font-size: 14px;
        color: #fff;
      }
      .city {
        color: #fff;
        padding: 0 3px;
      }
      .agree {
        display: inline-block;
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 2px;
        padding: 5px 20px;
        margin: 0 20px;
      }
      .chose_city {
        color: #fff;
        border-bottom: 1px dashed #75b5f9;
      }
    }
    .sale {
      font-family: $germ;
      font-size: 25px;
      text-transform: uppercase;
      color: #fff;
      text-align: center;
      padding: 0 40px;
      > p {
        font-size: 25px;
      }
    }
  }
}

.header_top_menu {
  background: $grey_back;
  width: 100%;
  padding: 0 calc((100% - 1240px + 40px)/2);
  display: flex;
  align-items: center;
  height: 39px;
  a {
    font-family: $c35;
    font-size: 14px;
    color: $grey_font_light;
    border-bottom: 1px solid #d0d4d6;
    margin: 0 50px 0 0;
  }
  .phone {
    font-family: $c45;
    font-size: 16px;
    color: $grey_font_light;
    border-bottom: none;
    margin-left: auto;
  }
  .consultant {
    background: none;
    border: none;
    outline: none;
    font-family: $c45;
    font-size: 16px;
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
    &:before {
      content: '';
      display: inline-block;
      margin: 0 8px -2px 0;
      @extend .sprite-sprite_images-consultant;
    }
  }
}
.header_content {
  height: 138px;
  width: 1240px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 40px;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  .menu {
    display: none;
  }
  .location {
    font-family: $c45;
    font-size: 16px;
    color: $blue;
    border: none;
    border-bottom: 1px dotted $blue;
    position: relative;
    background: none;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: -18px;
      top: 1px;
      @extend .sprite-sprite_images-header_city;
    }
    &:hover {
      &:before {
        @extend .sprite-sprite_images-header_city_active;
      }
    }
  }
  .delivery_points {
    font-family: $c45;
    font-size: 16px;
    color: $blue;
    position: relative;
    padding: 0 0 0 24px;
    white-space: nowrap;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      @extend .sprite-sprite_images-delivery_box;
    }
    &:hover {
      &:before {
        @extend .sprite-sprite_images-delivery_box_active;
      }
    }
  }
  .logo {
    height: 88px;
    grid-column: 3/4;
    img {
      height: 100%;
    }
  }
  .cabinet {
    position: relative;
    padding: 20px 0;
    margin-top: 13px;
    cursor: pointer;
    .link {
      padding: 0 0 0 24px;
      display: flex;
      flex-wrap: wrap;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 4px;
        top: 21px;
        @extend .sprite-sprite_images-header_acc;
      }
      span:nth-of-type(1) {
        font-family: $c45;
        font-size: 16px;
        color: $blue;
        white-space: nowrap;
      }
      span:nth-of-type(2) {
        font-family: $c35;
        font-size: 12px;
        width: 100%;
        white-space: nowrap;
      }
    }
    &:hover {
      .link {
        &:before {
          @extend .sprite-sprite_images-header_acc_active;
        }
      }
    }
  }
  .cart {
    position: relative;
    .link {
      position: relative;
      padding: 0 0 0 20px;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: -20px;
        top: 0;
        @extend .sprite-sprite_images-header_cart;
      }
      .quantity {
        background: $orange;
        color: white;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 8px;
        left: -6px;
      }
      .amount {
        font-family: $c45;
        font-size: 16px;
        color: $blue;
      }
    }
  }
}
.nav_header_container {
  position: relative;
  width: 1240px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
  .choice {
    position: relative;
    > a {
      width: 180px;
      height: 50px;
      display: block;
      text-align: center;
      background: $orange;
      color: #fff;
      text-transform: uppercase;
      font-size: 16px;
      padding: 15px 0;
      cursor: pointer;
      &:hover { background: $blue; }
    }
  }
  .nav {
    flex-grow: 1;
    background: $grey_back;
    .nav_menu {
      display: flex;
    }
    .nav_menu_item {
      flex-grow: 1;
      white-space: nowrap;
      .nav_menu_link {
        color: $grey_font;
        .icon {
          position: relative;
          width: 11px;
          height: 13px;
          margin: 0 0 0 10px;
          transform-origin: center center;
          &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            width: 8px;
            height: 2px;
            background: $grey_font;
            top: 2px;
          }
          &:before {
            transform-origin: left center;
            transform: rotate(45deg);
            left: 0;
          }
          &:after {
            transform-origin: right center;
            transform: rotate(-45deg);
            right: 0;
          }
        }
      }
      &:hover {
        background: $blue;
        .nav_menu_link {
          color: white;
          .icon {
            height: 11px;
            transform: rotate(180deg);
            &:before, &:after {
              background: white;
            }
          }
        }
      }
    }
    .nav_menu_link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      text-transform: uppercase;
    }
    .nav_menu_link:before { margin-right: 10px; }
    @media all and (max-width: 1279px) {
      .nav_menu_link:before { display: none; }
    }
  }
}

@include small_desktop {
  .header_top_menu {
    padding: 0 calc((100% - 1000px + 40px)/2);
  }
  .header_content {
    width: 1000px;
    grid-gap: 40px;
  }
}
@include tablet {
  header {
    padding: 0 10px;
  }
  .header_top_menu {
    display: none;
  }
  .header_content {
    width: 100%;
    height: 70px;
    grid-template-columns: repeat(3, 1fr);
    .menu {
      justify-self: left;
      display: block;
    }
    .location {
      display: none;
    }
    .delivery_points {
      display: none;
    }
    .logo {
      height: 60px;
      overflow: hidden;
      grid-column: 2/3;
      img {
        margin: 5px 0 0 0;
        height: 118%;
      }
    }
    .cabinet {
      display: none;
    }
    .cart {
      justify-self: right;
      .link {
        padding: 0 0 0 15px;
        .amount {
          display: none;
        }
      }
    }
  }
}
@include phone {
  .header_content {
    height: 60px;
    .logo {
      height: 40px;
      overflow: hidden;
      img {
        margin: -11px 0 0 0;
        height: 160%;
      }
    }
  }
}

@media all and (max-width: 1023px) {
  header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  }
  .banner_container {
    .banner {
      width: 100%;
      padding: 15px 10px;
      .close { right: 10px; }
      &.city_container { display: none; }
    }
  }
  .nav_header_container {
    position: static;
    margin-bottom: 0;
    .choice,
    .nav { display: none; }
  }
}
@media all and (max-width: 767px) {
  .banner_container .banner .sale {
    > p {
      font-size: 14px;
    }
  }
}

.nav_header_container .sub_menu_container {
  position: absolute;
  display: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 1240px;
  background-color: #fff;
  top: 50px;
  left: 0;
  padding: 30px 19px 0;
  border: 1px solid #d5dbde;
  border-top: 4px solid $blue;
  z-index: 60;
  &.active {
    @keyframes block {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }
    animation: block linear 0.2s;
    display: flex;
  }
  @media all and (max-width: 1023px) {
    .sub_menu_block,
    .choice_form { width: 100%; }
  }
  .sub_menu_block,
  .choice_form {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 275px);
    .sub_menu_cell {
      position: relative;
      width: 185px;
      margin-bottom: 50px;
      padding: 0 5px;
      white-space: normal;
      > span {
        display: block;
        font-weight: 700;
        width: 100%;
        font-size: 16px;
        color: $blue;
        text-transform: uppercase;
        @include after_line($blue,10px 0 15px);
        a { color: $blue; }
      }
      > ul {
        .sub_menu_item {
          &:nth-child(8) .sub_menu_link {
            position: absolute;
            left: 0;
            bottom: -30px;
            font-size: 14px;
            @include view_more_link($blue_hover);
            &:hover { font-weight: normal; }
          }
          .sub_menu_link {
            background: transparent;
            border: none;
            display: block;
            font-family: $c35;
            font-size: 16px;
            margin: 0 0 5px 5px;
            cursor: pointer;
            &:hover { color: $blue; }
          }
        }
      }
      .lbl {
        position: relative;
        padding: 2px 25px 2px 5px;
        display: inline-block;
        min-width: 51%;
        font-family: $c35;
        font-size: 16px;
        margin: 0 0 5px 5px;
        cursor: pointer;
        &:hover { color: $blue; }
      }
      input[type="checkbox"] { display: none; }
      input[type="checkbox"]:checked + label {
        background: $grey_back;
        border-radius: 3px;
        &::after,
        &::before {
          content: '';
          display: block;
          height: 1px;
          width: 10px;
          background: $grey_font;
          transform: rotate(45deg);
          position: absolute;
          right: 5px;
          top: 12px;
        }
        &::before { transform: rotate(-45deg); }
      }
    }
    .input_submit {
      position: absolute;
      bottom: 10px;
      left: 0;
      @include button($blue,$blue_hover);
      padding: 10px 30px;
    }
  }
  @include promoblock(275px,275px,20px);
  .block {
    .text {
      background: #eeeae6;
      height: auto;
      .title {
        display: block;
        font-weight: 700;
        @include after_line($orange,10px 0);
      }
    }
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .banner_container {
    .banner { width: 1000px; }
  }
  .top_header_container {
    width: 1000px;
    padding: 50px 0;
  }
  .nav_header_container {
    width: 1000px;
    .nav { background: $grey_back; }
    .sub_menu_container {
      width: 1000px;
      .block { display: none; }
      .sub_menu_block,
      .choice_form { width: 100%; }
    }
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .top_header_container {
    width: 100%;
    padding: 20px 15px 20px 55px;
    align-items: center;
    .left { display: none; }
    .logo {
      width: 33%;
      margin-left: auto;
      height: 30px;

      img { width: 160px; }
    }
    .right {
      width: 33%;
      justify-content: flex-end;
      .cell {
        &.acc { display: none; }
        &.cart {
          .cart {
            padding: 0;
            background: #fff;

            .cell_text { display: none; }

            .cart_count {
              top: 10px;
              left: 15px;
            }
          }

          &:hover .cart_popup { display: none; }
        }
      }
    }
  }
  .nav_header_container {
    position: static;
    margin-bottom: 0;
    .choice,
    .nav { display: none; }
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .banner_container .banner .sale { font-size: 16px; }
  .top_header_container {
    width: 100%;
    padding: 20px 0 20px 15px;
    align-items: center;
    .left { display: none; }
    .logo {
      width: 33%;
      margin-left: auto;
      height: 25px;
      img { width: 160px; }
    }
    .right {
      width: 33%;
      justify-content: flex-end;
      .cell {
        &.acc { display: none; }
        &.cart {
          .cart {
            padding: 0;
            background: #fff;

            .cell_text { display: none; }

            .cart_count {
              top: 10px;
              left: 15px;
            }
          }

          &:hover .cart_popup { display: none; }
        }
      }
    }
  }
}
@media all and (max-width: 479px) {
  .top_header_container {
    width: 100%;
    padding: 20px 0 20px 15px;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    .left { display: none; }
    .logo {
      width: 33%;
      margin-left: auto;
      height: 20px;
      img {
        width: 100%;
        max-width: 110px;
      }
    }
    .right {
      width: 33%;
      justify-content: flex-end;
      .cell {
        &.acc { display: none; }
        &.cart {
          .cart {
            padding: 0;
            background: #fff;

            .cell_text { display: none; }

            .cart_count {
              top: 10px;
              left: 15px;
            }
          }

          &:hover .cart_popup { display: none; }
        }
      }
    }
  }
}

.search_wrap_container {
  overflow-x: hidden;
  width: 50px;
  margin-left: 1px;
  &:hover {
    overflow-x: visible;
    .search { width: 420px; }
  }
  form { position: relative; }
  .search {
    position: absolute;
    right: 0;
    top: 0;
    background: $blue;
    border: none;
    width: 1px;
    height: 50px;
    padding-left: 20px;
    font-size: 15px;
    font-family: $c35;
    color: #fff;
    -webkit-appearance: none;
    @include placeholder(#fff);
    transition: width ease 0.4s;
    z-index: 1;
    &:focus { width: 420px; }
  }
  .input_submit {
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    background: $blue url(img/header/search.png) no-repeat center;
    z-index: 2;
    border: none;
    text-indent: 9999px;
    cursor: pointer;
  }

  @media all and (max-width: 1023px) {
    &.desktop { display: none; }

    width: 100%;
    margin-left: 0;

    form {
      position: relative;
      width: 100%;
      padding: 30px 25px 20px 30px;
      background: $blue;
    }

    .search {
      position: static;
      width: 100%;
      height: auto;
      padding: 10px 35px 10px 10px;
      background: #fff;
      color: $grey_font;
      font-size: 13px;
      border-radius: 3px;

      &:focus { width: 100%; }
      @include placeholder($grey_font_light);
    }

    .input_submit {
      position: absolute;
      top: 30px;
      right: 25px;
      background: #fff url(img/header/search_mobile.png) no-repeat center;;
      width: 35px;
      height: 35px;
      border-radius: 3px;
    }
  }
}
