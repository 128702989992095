.tag_filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .tag {
    border: 1px solid #e6eaec;
    border-radius: 2px;
    margin-right: 18px;
    margin-bottom: 10px;
    display: flex;
    white-space: nowrap;
    .activation_link {
      font-family: $c35;
      font-size: 14px;
      color: #a8b2ba;
      padding: 3px 8px 6px;
    }
    .close_link {
      @include close(0, 0, $grey_font);
      &:before, &:after {
        width: 10px;
        height: 1px;
        top: auto;
      }
      position: relative;
      display: none;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding-right: 4px;
    }
    &.active {
      background: #f0f4f6;
      border: 1px solid transparent;
      .activation_link {
        color: $grey_font;
      }
      .close_link {
        display: flex;
      }
    }
    @include phone {
      margin-right: 8px;
    }
  }
}