aside.menu {
  position: absolute;
  left: 0;
  top: 0;
  &.fixed {
    position: fixed;
    left: unset;
    padding: 15px 0;
  }
  .toggle {
    display: none;
  }
  .content {
    width: 295px;
    display: grid;
    align-content: start;
    padding: 0 20px;
    .close {
      @include close(5px, 5px, $grey_font);
      display: none;
      background: none;
      border: none;
      outline: none;
      padding: 10px;
      box-sizing: unset;
      &:before, &:after {
        top: 20px;
      }
    }
    div {
      display: grid;
      span {
        padding: 0 0 20px 0;
        font-family: $c55;
        font-size: 14px;
        text-transform: uppercase;
      }
      a {
        font-family: $c35;
        font-size: 15px;
        margin: 0 0 10px 0;
        display: flex;
        align-items: start;
        white-space: nowrap;
        &:before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 2px;
          background: $grey_back;
          margin: 3px 13px 0 0;
        }
        &:hover {
          text-decoration: underline;
        }
        &:last-child {
          border-bottom: 1px solid #d3d3d3;
          margin: 0 0 20px 0;
          padding: 0 0 30px 0;
        }
        &.active {
          &:before {
            background: $blue;
          }
        }
      }
    }
    div:nth-of-type(2) {
      padding: 0 0 0 20px;
    }
    div:nth-of-type(3) {
      padding: 0 0 0 40px;
    }
  }
}

@include small_desktop {
  aside.menu {
    .content {
      width: 258px;
    }
  }
}
@include tablet {
  aside.menu {
    position: static;
    &.shown {
      .content {
        left: 0;
      }
    }
    .toggle {
      @include button($blue,$blue_hover);
      margin: 0 0 20px 0;
      display: block;
    }
    .content {
      position: fixed;
      width: 320px;
      left: -320px;
      top: 70px;
      height: calc(100vh - 70px);
      background: white;
      padding: 20px 20px;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
      z-index: 101;
      .close {
        display: block;
      }
    }
  }
}
@include phone {
  aside.menu {
    .content {
      top: 60px;
      height: calc(100vh - 60px);
    }
  }
}