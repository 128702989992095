.page-reviews {
  display: grid;
  grid-template-columns: minmax(auto, 900px);
  justify-items: flex-start;
  h1 {
    margin-bottom: 44px;
  }
  .comments_wrapper {
    .comment_item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      padding-bottom: 20px;
      border-bottom: 1px solid $grey_back;
      margin-bottom: 20px;
      font-family: $c35;
      font-size: 15px;
      font-weight: normal;
      color: $grey_font;
      .comment_item_img {
        width: 160px;
        margin-right: 30px;
        @include big_phone {
          width: 70px;
          margin-right: 15px;
          margin-bottom: 15px;
          float: left;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      .comment_item_text_block {
        width: calc(100% - 190px);
        @include big_phone {
          width: 100%;
        }
        .author_block {
          margin-bottom: 4px;
          .author {
            font-size: inherit;
            font-family: inherit;
            &.author_city {
              font-family: inherit;
              @include phone {
                display: block;
              }
            }
          }
        }
        .rate_and_date {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          .rate_star {
            width: auto;
            margin-right: 10px;
            .star {
              transform: scale(0.8);
              margin-right: -4px;
            }
          }
          .comment_date {
            font-family: inherit;
            font-size: 12px;
            color: $grey_font_light;
          }
          .place_review {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-family: inherit;
            font-size: 12px;
            color: $grey_font_light;
            margin-right: 7px;
            &:before {
              content: '';
              display: block;
              margin-right: 7px;
            }
            &.instagram:before {
              @extend .sprite-sprite_images-instagram_review;
            }
            &.youtube:before {
              @extend .sprite-sprite_images-youtube;
            }
          }
        }
        .comment_item_content {
          .comment_item_text {
            font-family: $c35;
            line-height: 1.4;
          }
          .comment_item_visual_content {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-top: 12px;
            a {
              margin-right: 10px;
              img {
                max-width: 90px;
                max-height: 90px;
              }
            }
          }
          @include phone {
            width: 100%;
            &:before {
              content: '';
              display: table;
              clear: both;
            }
          }
        }
      }
      .comment_answer {
        padding: 10px;
        margin-left: 24px;
        margin-top: 20px;
        margin-right: 10px;
        background-color: $grey_back;
        display: flex;
        flex-direction: column;
        .author {
          font-size: 15px;
          font-family: $c55;
          margin-bottom: 10px;
        }
        .comment_item_text {
          font-family: $c35;
        }
      }
      &.comment_item_youtube {
        .author_visual_review_item {
          width: 150px;
        }
      }
      &:last-of-type { margin-bottom: 0; }
      @include phone {
        display: block;
      }
    }
    .show_more {
      justify-self: center;
      display: flex;
      align-items: center;
      margin: 20px auto 64px;
      background: transparent;
      border: none;
      cursor: pointer;
      .arrow {
        background: #f0f4f6;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        margin-right: 18px;
        .circle {
          display: block;
          width: 16px;
          height: 16px;
          border: 2px solid $grey_font;
          border-radius: 50%;
        }
        .triangle {
          display: block;
          position: absolute;
          border: 5px solid transparent;
          border-left: 5px solid $grey_font;
          left: 15px;
          top: 4px;
          z-index: 2;
        }
        .overlay {
          display: block;
          position: absolute;
          width: 15px;
          height: 15px;
          background: #f0f4f6;
          left: 15px;
          z-index: 1;
        }
      }
      .text {
        font-family: $c45;
        font-size: 15px;
        color: $grey_font;
      }
    }
    .add_review_container {
      width: 100%;
      margin-bottom: 117px;
      .add_review_block {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 88px;
        background: $grey_back;
        border-radius: 50px;
        margin: 25px auto;

        &:hover .pseudolink {
          border-color: transparent;
        }

        @media all and (max-width: 479px) {
          width: 290px;
        }
      }
      .add_review_icon {
        display: inline-block;
        margin-right: 10px;
        margin-left: -10px;
        @extend .sprite-sprite_images-add_review;
      }
      .add_review_text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
      .text {
        font-family: $c35;
        font-size: 15px;
        margin-bottom: 10px;
      }
      .pseudolink {
        font-size: 15px;
        text-transform: uppercase;
        color: $blue;
        border-bottom: 1px dashed $blue;
      }
    }
  }
  .show_more {
    justify-self: center;
    display: flex;
    align-items: center;
    margin-bottom: 64px;
    background: transparent;
    border: none;
    cursor: pointer;
    .arrow {
      background: #f0f4f6;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      margin-right: 18px;
      .circle {
        display: block;
        width: 16px;
        height: 16px;
        border: 2px solid $grey_font;
        border-radius: 50%;
      }
      .triangle {
        display: block;
        position: absolute;
        border: 5px solid transparent;
        border-left: 5px solid $grey_font;
        left: 15px;
        top: 4px;
        z-index: 2;
      }
      .overlay {
        display: block;
        position: absolute;
        width: 15px;
        height: 15px;
        background: #f0f4f6;
        left: 15px;
        z-index: 1;
      }
    }
    .text {
      font-family: $c45;
      font-size: 15px;
      color: $grey_font;
    }
  }
  .give_feedback {
    background: #f0f4f6;
    justify-self: center;
    padding: 20px 72px 20px 20px;
    border-radius: 45px;
    margin-bottom: 116px;
    display: grid;
    img {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
      margin-right: 20px;
    }
    span:nth-of-type(1) {
      grid-column: 2 / 3;
      font-family: $c35;
      font-size: 15px;
      color: $grey_font;
      letter-spacing: 0.2px;
      margin-bottom: 12px;
    }
    span:nth-of-type(2) {
      grid-column: 2 / 3;
      font-family: $c45;
      font-size: 15px;
      font-weight: bold;
      color: #0077e7;
      text-transform: uppercase;
      justify-self: center;
    }
    &:hover {
      span:nth-of-type(2) {
        border-bottom: 1px dotted #0077e7;
      }
    }
  }
  .close {
    @include button_link;
    margin-bottom: 24px;
  }
}
