.page.add-review {
  margin-bottom: 20px;
  display: grid;
  h2 { @include h2 }
  form {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    width: 100%;
    max-width: 804px;
    .rating_star {
      @include rating_star;
      grid-column: 1/4;
    }
    .data-text {
      display: grid;
      align-content: start;
      max-width: 344px;
      span {
        margin-bottom: 4px;
      }
      input {
        @include input;
        width: 100%;
      }
    }
    textarea {
      @include textarea;
      height: 148px;
      margin-bottom: 10px;
      grid-column: 1/4;
    }
    .file-set {
      display: flex;
      flex-wrap: wrap;
      grid-column: 1/4;
      .file {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 132px;
        height: 132px;
        margin-right: 10px;
        margin-bottom: 10px;
        border: 1px solid #d5dbde;
        border-radius: 2px;
        overflow: hidden;
        &.template {
          display: none;
        }
        &.loaded {
          input {
            width: 0;
            height: 0;
            display: none;
          }
          .close {
            display: block;
          }
          .load {
            display: none;
          }
        }
        input {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
        img.content {
          width: 100%;
        }
        span.content {
          display: block;
          text-align: center;
          font-family: $c35;
          font-size: 14px;
          color: black;
          width: 132px;
          padding: 0 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .close {
          width: 20px;
          height: 20px;
          background: rgba(0,0,0,0.4);
          border: none;
          border-radius: 50%;
          position: absolute;
          top: 5px;
          right: 5px;
          display: none;
          cursor: pointer;
          span {
            display: block;
            width: 10px;
            height: 2px;
            background: white;
            position: absolute;
            left: 5px;
            top: 9px;
            transform: rotate(-45deg);
            &:nth-of-type(2) {
              transform: rotate(45deg);
            }
          }
        }
        .load {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $c35;
          font-size: 14px;
          color: $blue;
          width: 100%;
          height: 100%;
          padding: 0 12px;
          background: none;
          border: none;
          outline: none;
        }
      }
    }
    input[type=submit] {
      justify-self: center;
      width: 240px;
      grid-column: 1/4;
      @include button($orange, $blue);
    }
    .agreement {
      text-align: center;
      color: $grey_font_light;
      font-family: $c35;
      font-size: 14px;
      margin: 10px 0 10px;
      grid-column: 1/4;
      a {
        color: $blue;
        font-family: $c35;
        font-size: 14px;
      }
    }
  }
  .thanks {
    position: absolute;
    display: grid;
    grid-gap: 20px;
    justify-items: center;
    align-items: center;
    align-content: center;
    background: white;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .icon {
      @extend .sprite-sprite_images-balloon;
    }
    .caption {
      font-family: $germ;
      font-size: 38px;
      text-transform: uppercase;
      color: $orange;
    }
    .text {
      font-family: $c35;
      font-size: 15px;
      text-align: center;
    }
  }
  
  @include big_phone {
    form {
      grid-template-columns: 1fr;
      .rating_star {
        grid-column: auto;
      }
      .data-text {
        max-width: none;
      }
      textarea {
        grid-column: auto;
      }
      .file-set {
        grid-column: auto;
      }
      input[type=submit] {
        grid-column: auto;
      }
      .agreement {
        grid-column: auto;
      }
    }
  }
}
