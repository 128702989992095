.admin_side_panel {
  .toggle {
    position: fixed;
    right: 26px;
    bottom: 94px;
    width: 60px;
    height: 60px;
    background: #E23A00;
    border: none;
    border-radius: 50%;
    font-family: $c45;
    font-size: 16px;
    color: white;
    cursor: pointer;
    z-index: 10000;
  }
  .container {
    position: fixed;
    width: 100%;
    max-width: 370px;
    height: 100vh;
    background: white;
    top: 100vh;
    right: - 370px;
    z-index: 10000000000;
    opacity: 0;
    box-shadow: 0 8px 18px 0 rgba(0,0,0,0.2),0 0 2px 0 rgba(0,0,0,0.05);
    transition: top 0.2s ease-in-out, right 0.2s ease-in-out, opacity 0.1s ease;
    overflow: hidden;
    &.active {
      top: 0;
      right: 0;
      opacity: 1;
    }
    .head {
      height: 56px;
      padding:  0 20px;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .close {
        @include close(0, 0, $grey_font);
        position: relative;
        background: transparent;
        border: none;
      }
    }
    .body {
      padding: 20px;
      display: flex;
      flex-direction: column;
      a {
        margin-bottom: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}