.page.order-status {
  margin-bottom: 40px;
  display: grid;
  h2 {
    @include h2
    &:nth-of-type(2), &:nth-of-type(3) {
      margin-top: 48px;
    }
  }
  p {
    @include p;
    mark {
      background: none;
      font-family: arial;
      font-size: 15px;
      font-weight: bold;
    }
    a {
      font-family: $c45;
      font-size: 15px;
      color: $blue;
      text-decoration: underline;
    }
  }
  button {
    @include button($orange, $blue);
    justify-self: flex-start;
    margin-bottom: 20px;
  }
  table {
    border-collapse: collapse;
    font-family: $c35;
    font-size: 16px;
    margin: 20px 0 0;
    max-width: 930px;
    td {
      font-family: $c35;
      font-size: 16px;
      text-align: left;
    }
    thead {
      td {
        font-family: arial;
        font-size: 15px;
        font-weight: bold;
        padding: 6px 10px 5px 0;
        white-space: nowrap;
      }
    }
    tbody {
      td {
        border: 1px solid lightgrey;
        padding: 9px 19px;
      }
    }
    tfoot {
      td {
        div {
          background: $grey_back;
          margin-top: 10px;
          text-align: right;
          padding: 16px 72px 16px 5px;
          font-family: $c35;
          font-size: 21px;
          mark {
            font-family: $c45;
            font-size: inherit;
            background: transparent;
            color: $green;
            margin-left: 15px;
          }
        }
      }
    }
    .default, .phone {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
    }
    .phone {
      display: none;
    }
  }
  .order-history {
    margin: 23px 0 0 2px;
    .event {
      display: grid;
      grid-template-columns: 80px min-content 1fr;
      grid-gap: 22px;
      .date-time {
        font-family: $c45;
        font-size: 15px;
        color: $grey_font_light;
        white-space: nowrap;
        text-align: right;
      }
      .icon {
        margin-top: 4px;
        display: block;
        position: relative;
        width: 10px;
        height: 10px;
        .dot {
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: $orange;
        }
      }
      .status {
        font-family: $c45;
        font-size: 15px;
        height: 36px;
      }
      &.process, &.end {
        align-items: flex-end;
        .icon {
          height: 98px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          padding-bottom: 22px;
          &:before {
            content: '';
            display: block;
            width: 1px;
            height: 62px;
            background: $orange;
            position: absolute;
            top: -12px;
            left: 4px;
          }
          &:after {
            content: '';
            display: block;
            border: 3px solid transparent;
            border-top: 4px solid $orange;
            position: absolute;
            top: 48px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
      &.end {
        .icon {
          .dot {
            position: relative;
            background: $green;
            &:before {
              content: '';
              display: block;
              position: absolute;
              left: -5px;
              top: -5px;
              width: 16px;
              height: 16px;
              border: 2px solid $green;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  @include phone {
    h2 {
      &:nth-of-type(2), &:nth-of-type(3) {
        margin-top: 40px;
      }
    }
    table {
      thead {
        td {
          white-space: normal;
          &:nth-of-type(3), &:nth-of-type(4) {
            text-align: center;
          }
        }
      }
      tbody {
        td {
          padding: 6px 10px;
        }
      }
      tfoot {
        td {
          div {
            font-size: 18px;
            padding: 16px 16px 16px 5px;
          }
        }
      }
      .default {
        display: none;
      }
      .phone {
        display: table-cell;
      }
    }
  }
}
