//colors
$orange: #f07b21;
$orange_hover: #ff9130;
$red: #f33840;
$green: #009d51;
$blue: #0f55a0;
$blue_hover: lighten($blue, 5%);
$yellow: #f5d70f;
$grey_font: #52575b;
$grey_font_light: #a8b2ba;
$grey_back: #f0f4f6;

//fonts
$rub: ALSRubl-Arial;
$c55: core_sans_d_55_boldregular;
$c45: core_sans_d_45_mediumregular;
$c35: core_sans_d_35_regularregular;
$germ: gerberamedium;

@mixin small_desktop {
  @media all and (max-width: 1279px) {
    @content;
  }
}
@mixin tablet {
  @media all and (max-width: 1023px) {
    @content;
  }
}
@mixin big_phone {
  @media all and (max-width: 767px) {
    @content;
  }
}
@mixin phone {
  @media all and (max-width: 479px) {
    @content;
  }
}

//INPUTS
input[type="text"].text_input {
  font-family: $c45;
  font-size: 14px;
  color: $grey_font;
  padding: 8px;
  border: 1px solid #d3d3d3;
  background-image: linear-gradient(90deg,#f07b21 3px,#fff 3px,#fff 100%);
}
input[type="submit"].submit_input {
  font-family: $c45;
  font-size: 14px;
  color: white;
  background: $orange;
  border: none;
  border-radius: 2px;
  text-align: center;
  padding: 9px;
  cursor: pointer;
  -webkit-appearance: none;
  &:hover {
    background: $orange_hover;
  }
}

//ACTUAL PAPARA ELEMENTS
@mixin h2 {
  font-family: $c55;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 14px;
  &:after {
    content: '';
    display: block;
    width: 30px;
    height: 3px;
    margin-top: 10px;
    background: $orange;
  }
}
@mixin textarea {
  font-family: $c35;
  font-size: 14px;
  border: 1px solid #d5dbde;
  border-radius: 2px;
  padding: 10px 12px;
  resize: none;
}
@mixin p {
  font-family: $c35;
  font-size: 16px;
  color: #52575b;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: justify;
}
@mixin input {
  font-family: $c35;
  font-size: 14px;
  border: 1px solid #d5dbde;
  border-radius: 2px;
  padding: 10px 12px;
}
@mixin rating_star {
  width: 100px;
  height: 20px;
  position: relative;
  margin: 10px 0 15px 0;
  .star .icon {
    display: block;
    position: absolute;
    @extend .sprite-sprite_images-star_grey;
  }
  .input {
    position: absolute;
    top: 0;
    width: 20px;
    height: 20px;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
    &:checked + .star .icon {
      @extend .sprite-sprite_images-star_blue;
    }
  }
  .rating_1 { left: 0 }
  .rating_2 { left: 20px }
  .rating_3 { left: 40px }
  .rating_4 { left: 60px }
  .rating_5 { left: 80px }
}
@mixin button($main_color,$hover_color) {
  display: inline-block;
  padding: 15px 30px;
  border: none;
  border-radius: 2px;
  color: #fff;
  text-transform: uppercase;
  font-size: 17px;
  background: $main_color;
  text-align: center;
  cursor: pointer;
  transition: all 0.15s linear;
  -webkit-appearance: none;
  &:hover { background: $hover_color; }
}
@mixin button_link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 0 12px;
  font-family: $c45;
  font-size: 15px;
  color: $blue;
  background: none;
  outline: none;
  white-space: nowrap;
  border: 1px solid $blue;
  border-radius: 3px;
  cursor: pointer;
}
@mixin aside_menu {
  position: absolute;
  padding: 0 20px 0 0;
  a {
    font-family: $c35;
    font-size: 16px;
    white-space: nowrap;
    display: block;
    padding-bottom: 9px;
    &.current {
      &:before {
        background: $blue_hover;
      }
    }
    &:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 2px;
      background: $grey_back;
      margin: 3px 13px 0 0;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  div {
    margin-left: 20px;
  }
  @include tablet {
    position: relative;
    left: -10px;
    padding: 0 0 10px 0;
    width: calc(100% + 20px);
    &:after {
      content: '';
      display: block;
      background: linear-gradient(-90deg, rgba(255,255,255,1), rgba(255,255,255,0.0));
      position: absolute;
      right: 0;
      top: 0;
      width: 33px;
      height: 33px;
    }
    a {
      display: none;
      &.tablet_visible {
        display: inline-block;
        font-family: $c35;
        color: $blue;
        margin: 0 5px 10px 0;
        padding: 6px 12px;
        border: 1px solid $blue;
        border-radius: 3px;
        &:before {
          display: none;
        }
        &:first-child {
          margin-left: 10px;
        }
        &:last-child {
          margin-right: 10px;
        }
      }
    }
    div {
      white-space: nowrap;
      margin-left: 0;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

// СТИЛИ ЛОАДЕРА
#loader_container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  background: rgba(255, 255, 255, 0.7);
  .loader_wrapper { perspective: 150px; }
  .loader_picture {
    display: block;
    @extend .sprite-sprite_images-loader_picture;
    animation: loader 1.2s linear infinite;
    animation-fill-mode: both;
  }
}
.loader_container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  background: rgba(255, 255, 255, 0.7);
  .loader_wrapper { perspective: 150px; }
  .loader_picture {
    display: block;
    @extend .sprite-sprite_images-loader_picture;
    animation: loader 1.2s linear infinite;
    animation-fill-mode: both;
  }
}
@keyframes loader {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

// ВЫсота блока Google конверсии
iframe[name="google_conversion_frame"] {
  height: 0 !important;
}

//СТИЛИ ДЛЯ ШРИФТА РУБЛЯ
.rub {
  font-family: inherit;
  color: inherit;
  text-transform: lowercase !important;
  &::after {
    content: 'уб.';
    color: inherit;
    font-family: inherit;
  }
}

// Стили для контейнера каталога товаров при прокрутке
.disable-hover,
.disable-hover * {
  pointer-events: none !important;
}

//ВАЛИДАЦИЯ ФОРМ
input:not([type^="s"]).valid, textarea.valid {
  background-image: linear-gradient(90deg, $orange 3px, #fff 3px, #fff 100%);
  background-image: -moz-linear-gradient(0deg, $orange 3px, #fff 3px, #fff 100%);

  &:valid:not(:placeholder-shown) {
    background-image: linear-gradient(90deg, $green 3px, #fff 3px, #fff 100%);
    background-image: -moz-linear-gradient(0deg, $green 3px, #fff 3px, #fff 100%);
  }

  &:valid {
    background-image: linear-gradient(90deg, #fff 3px, #fff 3px, #fff 100%);
    background-image: -moz-linear-gradient(0deg, #fff 3px, #fff 3px, #fff 100%);
  }

  &:invalid:focus:not(:placeholder-shown) {
    background-image: linear-gradient(90deg, $red 3px, #fff 3px, #fff 100%);
    background-image: -moz-linear-gradient(0deg, $red 3px, #fff 3px, #fff 100%);
  }
}

// СТИЛИ ДЛЯ АВТОЗАПОЛНЕННЫХ ПОЛЕЙ
// input:-webkit-autofill {
//   -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
//   -webkit-text-fill-color: $grey_font !important; /* цвет текста */
//   color: $grey_font !important; /* цвет текста */
// }

// СТИЛИ ДЛЯ ВЫВОДА ОШИБОК В ФОРМАХ
//.errorlist {
//  margin: -5px 0 10px !important;
//  padding-left: 0 !important;
//  width: 100%;
//  li {
//    font-family: $c45;
//    color: $red;
//    &::before { display: none !important; }
//  }
//}

// СТИЛИ ЗВЕЗДОЧЕК РЕЙТИНГА ТОВАРА И ОТЗЫВОВ
@mixin rate_star() {
  display: flex;
  margin-bottom: 10px;
  .star {
    display: block;
    @extend .sprite-sprite_images-star_grey;
    &.fill { @extend .sprite-sprite_images-star_orange; }
    &.fill_blue { @extend .sprite-sprite_images-star_blue; }
  }
  input[type="radio"] { display: none; }
}
.rate_star {
  display: flex;
  height: 100%;
  align-items: center;
  .star {
    display: block;
    @extend .sprite-sprite_images-star_grey;
    &.fill { @extend .sprite-sprite_images-star_orange; }
    &.fill_blue { @extend .sprite-sprite_images-star_blue; }
  }
  input[type="radio"] { display: none; }
}

// ТОЧКИ ПАГИНАЦИИ ДЛЯ ОДИНОЧНОГО СЛАЙДЕРА
.slick-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  display: flex;
  justify-content: center;
  z-index: 60;
  li {
    text-indent: -9999px;
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
    background: $blue;
    &:hover {
      background: $orange;
      box-shadow: 0 0 0 2px $orange;
    }
    &.slick-active {
      background: $orange;
      box-shadow: 0 0 0 2px $orange;
    }
  }
}

// СТИЛЛИЗАЦИЯ ССЫЛКИ В ТЕКСТЕ
@mixin view_more_link($color) {
  color: $color;
  border-bottom: 1px solid lightgrey;
  &:hover { border-color: transparent; }
}

// СТИЛИ ЯЧЕЙКИ ТОВАРА В КАТАЛОГЕ И СЛАЙДЕРЕ В КАРТОЧКЕ ТОВАРА
@mixin catalog_cell() {
  position: relative;
  display: block;
  width: 31.7%;
  max-width: 295px;
  margin: 0 20px 20px 0;
  overflow: hidden;
  &:hover {
    .gallery_wrap {
      border-color: #a9a9a9;
    }
    .catalog_cell_text {
      border-color: #a9a9a9;
    }
  }
  &:nth-of-type(3n) { margin-right: 0; }
  &.sale {
    .catalog_cell_text {
      .catalog_cell_price {
        color: $grey_font_light;
        text-decoration: line-through;
        .new_price {
          display: inline-block;
          text-decoration: none;
          font-family: inherit;
        }
      }
    }
  }
  .add_to_favorite { display: none; }
  @media all and (max-width: 1023px) {
    .add_to_favorite { display: block; }
  }
  .gallery_wrap {
    overflow: hidden;
    border: 1px solid lightgrey;
    border-bottom: none;
    display: block;
    min-width: 200px;
    min-height: 200px;
    position: relative;
    .gallery_button {
      position: absolute;
      background: transparent;
      cursor: pointer;
      border: none;
      width: 40px;
      height: 100%;
      z-index: 100;
      display: flex;
      justify-content: center;
      &.prev {
        left: 0;
        top: 0;
        .icon {
          transform: rotate(180deg);
        }
      }
      &.next {
        right: 0;
        top: 0;
      }
      .icon {
        background: white;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        box-shadow: 0 0 6px 2px rgba(100, 100, 100, 0.2);
        position: relative;
        display: block;
        &:before, &:after {
          content: '';
          display: block;
          width: 9px;
          height: 1px;
          background: $grey_font;
          position: absolute;
          transform-origin: right center;
          top: 9.5px;
          left: 5.5px;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
  .gallery_container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
  }
  .catalog_cell_img {
    min-width: 100%;
    width: 100%;
    height: 100%;
    background: $grey_back;
    border: none;
    &:nth-of-type(2) {
      position: absolute;
      opacity: 0;
    }
    &:nth-of-type(3) {
      position: absolute;
      opacity: 0;
    }
    &.active {
      opacity: 1;
    }
  }
  .catalog_cell_text {
    z-index: 6;
    left: 0;
    overflow: hidden;
    width: 100%;
    background: #fff;
    border: 1px solid lightgrey;
    border-top: none;
    padding: 20px;
    display: grid;
    .catalog_cell_title {
      display: block;
      font-family: $c45;
      font-size: 18px;
      padding-bottom: 10px;
      min-height: 47px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    .catalog_cell_price {
      display: block;
      font-size: 22px;
      text-transform: uppercase;
      color: $green;
      margin-bottom: 20px;
      .new_price {
        display: none;
        padding-left: 10px;
        color: $green;
      }
    }
    .wholesale_price {
      font-family: $c35;
      font-size: 15px;
      color: $grey_font;
      mark {
        background: none;
        font-family: $c45;
        color: $green;
      }
    }
    .after_price_line {
      border: none;
      height: 1px;
      background: #a9a9a9;
      margin: 13px 0 18px;
    }
    .product_colors {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      margin-top: -11px;
      margin-bottom: 16px;
      margin-left: -10px;
      margin-right: -10px;
      .color {
        background: transparent;
        border: none;
        width: 27px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon {
          display: block;
          width: 17px;
          height: 17px;
          border: 1px solid #ccc;
          border-radius: 50%;
          box-sizing: content-box;
        }
        &.active {
          .icon {
            border: 4px solid #bfbfbf;
          }
        }
      }
    }
    .catalog_cell_button {
      background: $orange;
      color: white;
      padding: 12px 0;
      text-align: center;
      font-size: 14px;
      text-transform: uppercase;
      transition: all linear 0.15s;
      justify-self: center;
      border-radius: 2px;
      min-width: 64%;
      &:hover {
        background: $orange;
      }
    }
  }
  &.create {
    padding-bottom: 0;
    &:hover .catalog_cell_text { top: 78%; }
    .catalog_cell_text { top: 100%; }
    .catalog_cell_button {
      background: $blue;
      color: #fff;
      &:hover { background: $blue_hover; }
    }
  }
}

// СТРЕЛОЧКА В САЙДБАРЕ И ФИЛЬТРЕ КАТАЛОГА
@mixin arrow() {
  position: absolute;
  top: 20px;
  right: 5px;
  width: 9px;
  height: 16px;
  background: url(img/header/arrow.png) no-repeat center;
  transform: rotate(90deg);
}

// НИЖНЯЯ ЧЕРТА ДЛЯ ЯЧЕЙКИ МЕНЮ В САЙДБАРЕ И ФИЛЬТРЕ КАТАЛОГА
@mixin after_row($color) {
  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    display: block;
    margin: 0 auto;
    width: 265px;
    height: 1px;
    background: $color;
    z-index: 10;
  }
}

// СТИЛИ ДЛЯ ФИЛЬТРА ВЫБОРА ПОВОДА И Т.Д В САЙДБАРЕ И ФИЛЬТРЕ КАТАЛОГА
@mixin choice_form() {
  .choice_form,
  .sub_menu_block {
    overflow: hidden;
    margin-left: 40px;
    .sub_menu_cell {
      position: relative;
      height: 57px;
      overflow: hidden;
      padding-bottom: 20px;
      @include after_row(lightgrey);
      &::after { bottom: 1px; }
      &.active {
        height: auto;
        &::before { transform: rotate(270deg); }
      }
      &.own_page_cell {
        &::before { display: none; }
        &:nth-of-type(2) { padding-left: 20px; }
        &:nth-of-type(3) { padding-left: 40px; }
      }
      h3 {
        position: relative;
        display: block;
        font-size: 14px;
        text-transform: uppercase;
        padding: 20px 0 20px 10px;
        a {
          display: block;
          padding: 20px 0;
          transform: translateY(-20px);
        }
        @media screen and (max-device-width: 1024px) {
          cursor: pointer;
        }
        &.checked_header {
          color: $orange;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding-right: 30px;
        }
      }
      input[type="checkbox"] { display: none; }
      input[type="radio"] { display: none; }
      a.sub_menu_item {
        padding-left: 25px;
        color: $blue;
        font-family: $c35;
        font-weight: 400;
        font-size: 16px;
        &.active {
          color: $orange;
        }
      }
      .lbl,
      .sub_menu_item {
        position: relative;
        display: block;
        font-family: $c35;
        font-size: 14px;
        padding-left: 45px;
        margin-bottom: 10px;
        &::before {
          content: '';
          position: absolute;
          left: 20px;
          top: 3px;
          display: block;
          width: 12px;
          height: 12px;
          background: $grey_back;
          border-radius: 2px;
        }
      }
      .sub_menu_item {
        &::before { display: none; }
      }
      input[type="checkbox"]:checked + .lbl::before { background: $orange; }
      input[type="radio"]:checked + .lbl::before { background: $blue; }
    }
    .input_submit,
    input[type="reset"],
    .find_and_close {
      @include button($blue,$blue_hover);
      width: 100%;
      padding: 10px 0;
      margin: 25px 0;
    }
    input[type="reset"] {
      background: $grey_back;
      color: $grey_font;
      margin: 25px auto;
      order: 50;
      &:hover { color: #fff; }
    }
  }
  .choice_form .sub_menu_cell::before {
    content: '';
    @include arrow();
  }
}

// ФОРМА ОТПРАВКИ СООБЩЕНИЯ НА САЙТ (КОММЕНТАРИЙ, ОТЗЫВ, СОТРУДНИЧЕСТВО)
@mixin comments_form() {
  .comments_form {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    .estimate_product {
      width: 100%;
      font-family: $c35;
      margin-bottom: 10px;
    }
    .rate_star {
      @include rate_star();
      width: 100%;
      margin-bottom: 30px;
      .star { cursor: pointer; }
    }
    .text_input {
      position: relative;
      outline: none;
      display: block;
      border: 1px solid lightgrey;
      border-radius: 2px;
      width: 275px;
      font-size: 15px;
      font-family: $c35;
      padding: 10px;
      color: $grey_font;
      margin: 0 20px 30px 0;
      @include placeholder($grey_font);
    }
    @media all and (max-width: 767px) {
      .text_input {
        margin: 0 0 30px;
        width: 100%;
      }
    }
    textarea {
      @extend .text_input;
      resize: none;
      width: 100%;
      margin: 0 0 30px;
      height: 135px;
    }
    .submit_input {
      @include button($blue,$blue_hover);
      width: 200px;
      margin: 0 auto;
    }
    @media all and (max-width: 767px) {
      .submit_input { width: 100%; }
    }
  }
}

// БЛОК "ОЦЕНИТЕ СРЕВИС" (ОТЗЫВЫ)
@mixin reviews_form_container() {
  .reviews_form_container {
    position: relative;
    border: 4px solid $grey_back;
    padding: 15px;
    margin-bottom: 55px;
    .open_reviews {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background: transparent;
      width: 100%;
      text-align: center;
      font-family: $c55;
      font-size: 16px;
      text-transform: uppercase;
      border: none;
      cursor: pointer;
    }
    .review_form_block {
      display: none;
      will-change: display;
      @include comments_form();
    }
    .close_reviews {
      @include close(10px,10px,$grey_font);
    }
  }
}

// ПСЕВДОЭЛЕМЕНТ ПОЛОСКИ ДЛЯ ЗАГОЛОВКОВ И Т.Д
@mixin after_line($color,$margin) {
  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 3px;
    background: $color;
    margin: $margin;
  }
}

// СТИЛИ ПРОМОБЛОКА
@mixin promoblock($width,$height,$margin) {

  .block {
    position: relative;
    width: $width;
    margin-bottom: $margin;
    min-width: 100px;
    min-height: 100px;
    > a {
      display: block;
      img {
        display: block;
        width: $width;
        height: $height;
      }
    }
    .text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 90px;
      padding: 15px 0 0 15px;
      z-index: 20;
      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: -12px;
        left: 40px;
        display: block;
        width: 25px;
        height: 25px;
        transform: rotate(45deg);
        background-color: inherit;
      }
      .title {
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 5px;
        @include after_line($orange,10px 0);
      }
      .title_big {
        font-family: $germ;
        font-size: 25px;
        text-transform: uppercase;
        @include after_line($orange,10px 0);
      }
      .description {
        font-family: $c35;
        font-size: 15px;
        line-height: 1.5;
        padding-right: 20px;
        margin-bottom: 15px;
        span { font-family: inherit; }
        .mobile { display: none; }
        @media all and (max-width: 479px) {
          .desktop { display: none; }
          .mobile { display: block; }
        }
      }
      .price {
        font-family: $c35;
        font-size: 15px;
      }
      .button {
        @include button($orange,$orange_hover);
        padding: 15px 20px;
      }
    }
  }
  .big {
    width: (($width * 2) + $margin);
    .text {
      padding: 40px 0 0 40px;
      width: $width + $margin;
      height: 100%;
      top: 0;
      right: 0;
      left: auto;
      &::before {
        top: 40px;
        left: -12px;
      }
    }
  }
  .right {
    > a { margin-left: $width + $margin; }
    .text {
      right: auto;
      left: 0;
      &::before {
        top: 40px;
        left: calc(100% - 12px);
      }
    }
  }
}

// СТИЛИ КРЕСТИКА ДЛЯ ЗАКРЫТИЯ ПОП-АПОВ И Т.Д
@mixin close($top,$right,$color) {
  position: absolute;
  top: $top;
  right: $right;
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 20px;
    height: 2px;
    top: 8px;
    background: $color;
  }
  &::before { transform: rotate(45deg); }
  &::after { transform: rotate(-45deg); }
}

//СТИЛИ ДЛЯ ПЛЭЙСХОЛДЕРОВ
@mixin placeholder($color) {
  &::-webkit-input-placeholder { color: $color; }
  &::-moz-placeholder { color: $color; }
  &:-moz-placeholder { color: $color; }
  &:-ms-input-placeholder { color: $color; }
}

// СТИЛИЗОВАННЫЙ ЧЕКБОКС И ЛЭЙБЛ
@mixin chexbox_style() {
  input[type="checkbox"] { display: none; }
  input[type="checkbox"]:checked + label::after { display: block; }
  label {
    position: relative;
    display: block;
    padding-left: 25px;
    font-family: $c35;
    font-size: 15px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 15px;
      height: 15px;
      background: #fff;
      border: 1px solid lightgrey;
      border-radius: 2px;
    }
    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      display: none;
      width: 9px;
      height: 6px;
      border-left: 2px solid $grey_font;
      border-bottom: 2px solid $grey_font;
      transform: rotate(-45deg);
    }
  }
}

// СОЦИАЛЬНЫЕ ССЫЛКИ В ПОПАПАХ И ЛИЧНОМ КАБИНЕТЕ
@mixin social_bar() {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .social_login_link {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 10px 25px;
    &:hover { opacity: 0.9; }
    .icon {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    &.vk,
    &.vk-oauth2 {
      background: #466a90;
      .icon { @extend .sprite-sprite_images-vk_popup; }
    }
    &.facebook {
      background: $blue;
      .icon { @extend .sprite-sprite_images-facebook_popup; }
    }
    &.odnoklasniki,
    &.odnoklassniki-oauth2 {
      background: $orange;
      .icon { @extend .sprite-sprite_images-odnoklasniki_popup; }
    }
    &.twitter {
      background: #2daae1;
      .icon { @extend .sprite-sprite_images-twitter_popup; }
    }
  }
}

// БЛОК ИНФОРМАЦИИ О ЗАКАЗЕ
@mixin product() {
  .product {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: lightgrey;
      margin: 15px 60px 15px 115px;
    }
    > div { padding-top: 10px; }
    .image_wrapper {
      display: block;
      border: 1px solid lightgrey;
      width: 100px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .product_detail {
      width: 230px;
      .product_title {
        display: block;
        text-transform: uppercase;
        transition: color 0.1s linear;
        margin-bottom: 7px;
        &:hover { color: $blue; }
      }
      span {
        font-family: $c35;
        font-size: 15px;
        color: $grey_font_light;
        &.separator { padding: 0 3px; }
      }
    }
    .option_block {
      h3 {
        font-family: $c35;
        font-size: 15px;
        color: $grey_font_light;
        margin-bottom: 5px;
      }
      p { font-size: 18px; }
      &.count_container {
        &.without_change_count { visibility: hidden; }
      }
      .count_field_span {
        flex-grow: 1;
        display: inline-block;
        width: 80px;
        border: none;
        background: #fff;
        background-blend-mode: color;
        font-size: 20px;
        text-align: center;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
      form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80px;
        max-width: 100px;
        .count_field {
          flex-grow: 1;
          display: inline-block;
          width: 35px;
          border: none;
          background: #fff;
          background-blend-mode: color;
          font-size: 22px;
          text-align: center;
          -moz-appearance: textfield;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
        }
        .count_change {
          position: relative;
          display: block;
          width: 20px;
          height: 20px;
          border: 1px solid $orange;
          border-radius: 50%;
          background: #fff;
          cursor: pointer;
          transition: all 0.2s linear;
          &:hover {
            background: $orange;
            &::before,
            &::after { background: #fff; }
          }
          &.plus {
            &::after { transform: translate(-50%, -50%) rotate(90deg); }
          }
          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 10px;
            height: 1px;
            background: $orange;
          }
        }
      }
      &.price_container {
        width: 110px;
        h3 { text-align: center; }
        p {
          text-align: center;
          width: 100%;
        }
        .discount { color: $red; }
        .old_price {
          text-decoration: line-through;
          color: $grey_font_light;
          .icon-coupon {
            @extend .sprite-sprite_images-coupon_mini;
            display: inline-block;
            margin-right: 5px;
          }
        }
      }
      &.final_price_container { width: 80px; }
      &.without_count_field { visibility: hidden; }
      @media all and (max-width: 767px) {
        &.price_container,
        &.final_price_container { width: 33%; }
      }
    }
    .delete_product_container {
      width: 90px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      .delete_product_block {
        margin-top: 27px;
        display: flex;
        padding: 2px 5px;
        border: 1px solid transparent;
        border-radius: 20px;
        cursor: pointer;
        transition: all 0.2s linear;
        &:hover {
          border-color: $orange;
          .text {
            width: auto;
            visibility: visible;
            padding: 0 10px;
          }
          .close {
            &::before,
            &::after { background: $orange; }
          }
        }
        .text {
          font-family: $c35;
          font-size: 13px;
          color: $orange;
          width: 0;
          visibility: hidden;
          transition: all 0.2s linear;
        }
        .close {
          @include close(0,0,$grey_font_light);
          position: relative;
          width: 15px;
          height: 15px;
          &::before,
          &::after {
            width: 15px;
            top: 7px;
          }
        }
      }
    }
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    .product {
      &::after { margin-right: 0; }
      .product_detail { width: 215px; }
      .delete_product_container {
        width: 15px;
        .delete_product_block {
          padding: 0;
          border: none;
          &:hover {
            .text { display: none; }
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    .product {
      &::after { margin-right: 0; }
      .product_detail { width: 215px; }
      &.project .product_detail { width: 235px; }
      .delete_product_container {
        width: 15px;
        .delete_product_block {
          padding: 0;
          border: none;
          &:hover {
            .text { display: none; }
          }
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    .product {
      &::after { margin: 15px 0; }
      > div {
        padding-top: 0;
        width: 33%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        h3,
        h2,
        a { width: 100%; }
        h3 { text-align: center; }
      }
      .image_wrapper {
        width: 80px;
        margin-bottom: 15px;
      }
      .product_detail {
        width: calc(100% - 90px);
        margin-bottom: 15px;
        p { width: 100%; }
      }
      .delete_product_container {
        width: 100%;
        justify-content: center;
        .delete_product_block {
          background: $grey_back;
          &:hover { border-color: transparent; }
          .text {
            padding: 0 10px;
            width: auto;
            visibility: visible;
            color: $grey_font;
          }
          .close { display: none; }
        }
      }
    }
  }
}

// БЛОК ПОДТВЕРЖДЕНИЯ УДАЛЕНИЯ ТОВАРА НА СТРАНИЦЕ ФОТОГРАФИЙ И В ПОПАПЕ КОРЗИНЫ
@mixin confirm_delete($top,$width,$button_width) {
  position: absolute;
  z-index: 35;
  top: $top;
  left: 0;
  right: 0;
  width: $width;
  margin: 0 auto;
  text-align: center;
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;
  .text {
    width: 100%;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .delete {
    @include button(#fff,$blue);
    width: $button_width;
    font-family: $c55;
    padding: 10px 0;
    font-size: 14px;
    color: $grey_font;
    &:hover { color: #fff; }
  }
}

// ИКОНКА ЧАСЫ HTML+CSS
@mixin clock_icon($color) {
  background: $color;
  width: 13px;
  min-width: 13px;
  height: 13px;
  border-radius: 50%;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: 5px;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
    left: 2px;
    top: 1px;
  }
}

// ПУНКТ ВЫДАЧИ ЗАКАЗОВ
@mixin point() {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #d5dbde;
  padding: 0 0 29px 34px;
  margin: 0 0 23px 0;
  min-height: 0;
  transition: min-height 2s;
  position: relative;
  text-align: left;
  &.way_shown {
    .way {
      display: block;
    }
    .location_buttons .show_way {
      border: none;
    }
  }
  &.map_shown {
    .map {
      display: block;
    }
    .location_buttons .show_map {
      border: none;
    }
  }
  &.boxberry {
    .name {
      .icon {
        @extend .sprite-delivery_points-boxberry_short;
      }
    }
  }
  &.cdek {
    .name {
      .icon {
        @extend .sprite-delivery_points-cdek_short;
      }
    }
  }
  .name {
    margin: 0 0 17px 0;
    .icon {
      position: absolute;
      left: 0;
      @extend .sprite-delivery_points-papara_short;
    }
    .text {
      font-family: $c45;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 23px;
      letter-spacing: 0.15px;
    }
  }
  .address {
    display: flex;
    align-items: center;
    margin: 0 0 7px 0;
    .icon {
      position: absolute;
      left: 8px;
      @extend .sprite-sprite_images-header_city;
    }
    .text {
      font-family: $c35;
      font-size: 15px;
      letter-spacing: 0.15px;
    }
  }
  .working_hours {
    display: flex;
    align-items: center;
    margin: 0 0 11px 0;
    .icon {
      position: absolute;
      left: 7px;
      @include clock_icon($grey_font_light);
    }
    .text {
      font-family: $c35;
      font-size: 15px;
      letter-spacing: 0.18px;
    }
  }
  .additional_info {
    margin: 0 0 3px 0;
    font-family: $c35;
    font-size: 13px;
    span {
      font-family: $c35;
      font-size: 13px;
    }
  }
  .location_buttons {
    margin: 6px 0 0 0;
    .show_map, .show_way {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      font-family: $c45;
      font-size: 13px;
      color: $grey_font_light;
      border-bottom: 1px dotted $grey_font_light;
      white-space: nowrap;
    }
    .show_map {
      margin: 0 16px 0 0;
    }
  }
  .map {
    width: 100%;
    height: 300px;
    margin: 23px 0 0 0;
    display: none;
    position: relative;
    [class*='search_layout_normal'] * {
      line-height: 0;
    }
    [class*='searchbox-button-text'] {
      line-height: 26px;
    }
  }
  .way {
    font-family: $c35;
    font-size: 15px;
    margin: 23px 0 0 0;
    line-height: 20px;
    display: none;
  }
  .take {
    position: absolute;
    top: 58px;
    right: 0;
    white-space: nowrap;
    input {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      cursor: pointer;
      & + button + button {
        display: none;
      }
      &:checked + button {
        display: none;
      }
      &:checked + button + button {
        display: block;
        background: $blue;
      }
    }
    button {
      background: $grey_font_light;
      border: none;
      outline: none;
      padding: 10px 15px;
      border-radius: 3px;
      font-family: $c45;
      font-size: 12px;
      color: white;
      text-transform: uppercase;
      min-width: 126px;
    }
  }
  @include big_phone {
    .take {
      position: relative;
      margin: 20px 0 0 0;
      top: 0;
    }
  }
}

// СПИСОК ПУНКТОВ ВЫДАЧИ ЗАКАЗОВ СПИСОК/КАРТА
@mixin point_list() {
  .point_list {
    &.list {
      .view {
        .show_list {
          color: $blue;
          border: none;
        }
      }
      .list {
        display: block;
      }
    }
    &.map {
      .view {
        .show_map {
          color: $blue;
          border: none;
        }
      }
      .map {
        display: block;
      }
    }
    .view {
      border-bottom: 1px solid #d5dbde;
      padding: 0 0 23px 0;
      margin: 0 0 25px 0;
      .show_list, .show_map {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        font-family: $c45;
        font-size: 16px;
        text-transform: uppercase;
        color: $grey_font_light;
        border-bottom: 1px dotted $grey_font_light;
        line-height: 17px;
      }
      .show_list {
        margin: 0 25px 0 0;
      }
    }
    .list {
      display: none;
      text-align: center;
      .point {
        @include point;
      }
      .show_all_points {
        background: none;
        border: none;
        outline: none;
        font-family: $c45;
        font-size: 15.62px;
        color: $blue;
        text-transform: uppercase;
        margin: auto;
        cursor: pointer;
        border-bottom: 1px dotted $blue;
      }
    }
    .map {
      display: none;
      margin: 0 0 20px 0;
      .inner {
        [class*='search_layout_normal'] * {
          line-height: 0;
        }
        [class*='searchbox-button-text'] {
          line-height: 26px;
        }
        width: 100%;
        height: 560px;
        position: relative;
        .point {
          @include point;
          padding: 5px 0 0 34px;
          margin: 0;
          border-bottom: none;
          .take {
            position: relative;
            margin: 10px 0 10px 0;
            top: unset;
          }
        }
      }
    }
    @include phone {
      .point_map {
        .map {
          height: 340px;
        }
      }
      .current_location {
        justify-content: center;
      }
      .delivery_services {
        .service {
          .name {
            display: grid;
            margin: 0 0 10px 0;
            .text {
              margin: 0 0 5px 0;
            }
          }
        }
      }
      .map {
        .inner {
          .point {
            max-width: 220px;
          }
        }
      }
    }
  }
}

// СТРЕЛКА В СЛАЙДЕРАХ
@mixin slider_button() {
  background: white;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  box-shadow: 0 0 6px 2px rgba(100,100,100,0.2);
  position: relative;
  display: block;
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-left: 1px solid $grey_font;
    border-top: 1px solid $grey_font;
    position: absolute;
    transform: rotate(-45deg);
    top: 6px;
    left: 7px;
  }
  &:hover {
    opacity: 0.6;
  }
}

// СКРЫТЫЙ КЛАСС
.invisible { visibility: hidden !important; }
.hidden { display: none !important; }
@media all and (max-width: 767px) {
  .mobile_hidden { display: none !important; }
}
@media all and (min-width: 768px) {
  .desktop_hidden { display: none !important; }
}

// БЛОК ПОКАЗАТЬ ЕЩЕ
.view_more_block {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
  .view_more_link {
    display: flex;
    align-items: center;
    &.active {
      .arrow { animation: rotate 0.5s linear infinite; }
    }
    &:hover .arrow { animation: rotate 0.5s linear infinite; }
    @keyframes rotate {
      100% { transform: rotate(360deg); }
    }
    .view_more_arrow {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: $grey_back;
      margin-right: 20px;
      .arrow {
        position: absolute;
        top: 7px;
        left: 7px;
        width: 15px;
        height: 15px;
        border: 2px solid $grey_font;
        border-radius: 50%;
        border-right-color: transparent;
        &.waiting {
          animation: rotate 0.5s linear infinite;
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          top: -2px;
          left: 7px;
          border-bottom-color: $grey_font;
          border-top: 0;
          border-right: 0;
        }
      }
    }
    .view_more_text {
      display: inline-block;
      font-size: 14px;
      font-family: $c55;
    }
  }
}

// СТИЛИ ФЛАЖКА СКИДКИ ИЛИ НОВОГО ТОВАРА
.badge {
  position: absolute;
  z-index: 5;
  top: 10px;
  left: 0;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  color: #fff;
  width: 45px;
  padding: 7px 0;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -6px;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
  }
  &::before { border-width: 28px 6px 0 0; }
  &::after { border-width: 28px 0 0 6px; }
  &.new_badge {
    background: $green;
    &::before { border-color: $green transparent transparent transparent; }
    &::after { border-color: transparent transparent transparent $green; }
  }
  &.sale_badge {
    background: $red;
    &::before { border-color: $red transparent transparent transparent; }
    &::after { border-color: transparent transparent transparent $red; }
  }
}

//СТИЛИ ДЛЯ КНОПКИ(СЕРДЦЕ) ДОБАВИТЬ В ИЗБРАННОЕ В КАТАЛОГЕ И КАРТОЧКЕ ТОВАРА
.add_to_favorite {
  position: absolute;
  z-index: 5;
  top: 15px;
  right: 15px;
  .heart {
    position: relative;
    @extend .sprite-sprite_images-heart;
  }
  &:hover {
    .heart { @extend .sprite-sprite_images-heart_full; }
  }
}

// СТИЛИ БЛОГА ПАГИНАЦИИ(ПОСТРАНИЧНОЙ НАВИГАЦИИ В КАТАЛОГЕ)
.paginations_block {
  width: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  .paginations {
    display: flex;
    align-items: center;
    .paginations_link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: $grey_back;
      margin-right: 15px;
      &:last-child { margin-right: 0; }
      &.ellipsis {
        background: none;
        cursor: default;
        &:hover {
          background: none;
          a { color: $grey_font; }
        }
      }
      &.active {
        background: $orange;
        cursor: default;
        a { color: #fff; }
        &:hover { background: $orange; }
      }
      &:hover {
        background: $blue;
        a { color: #fff; }
      }
      a {
        display: block;
        width: 100%;
        text-align: center;
        padding: 7px 0;
        font-size: 14px;
      }
    }
  }
}

// СТИЛИ ДЛЯ "ХЛЕБНЫХ КРОШЕК"
.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto 45px;
  padding: 0 20px;
  z-index: 59;
  li:not(:last-child) {
    margin-right: 5px;
    a span {
      font-family: $c35;
      font-size: 15px;
      color: $grey_font_light;
      mark {
        font-family: $c35;
        font-size: 15px;
        color: $grey_font_light;
        background: none;
        text-decoration: underline;
      }
      &:hover {
        mark {
          text-decoration: none;
        }
      }
    }
  }
  span { font-size: 15px; }
}
@include tablet {
  .breadcrumbs.catalog2020 {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .breadcrumbs { max-width: 1000px; }
}
@media all and (max-width: 1023px) {
  .breadcrumbs {
    margin: 15px auto 20px;
    padding: 0 10px;
  }
}

//СТИЛИ ДЛЯ СЕКЦИИ(ГЛАВНОГО КОНТЕЙНЕРА) ЛЮБОЙ СТРАНИЦЫ
.page {
  position: relative;
  width: 1240px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-content: start;
  h1 {
    width: 100%;
    font-size: 20px;
    text-transform: uppercase;
    @include after_line($orange,10px 0 15px);
  }
  &.page_password_reset {
    h1 {
      text-align: center;
      margin-top: 50px;
      @include after_line($orange,10px auto 30px);
    }
  }
  &.aside_menu {
    aside.aside {
      @include aside_menu;
    }
    .breadcrumbs {
      display: none;
    }
    main.content {
      padding-left: 310px;
    }
  }
  @include small_desktop {
    width: 1000px;
    main.content {
      width: 100%;
    }
    &.aside_menu {
      main.content {
        padding-left: 258px;
      }
    }
  }
  @include tablet {
    width: 100%;
    padding: 0 10px;
    main.content {
      margin: 0;
    }
    &.aside_menu {
      padding-top: 10px;
      flex-direction: column;
      .breadcrumbs {
        display: flex;
        padding: 0;
        margin: 0 0 15px 0;
      }
      main.content {
        padding-left: 0;
      }
    }
  }
}
// @media all and (max-width: 1023px) {
//   .page {
//     width: 100%;
//     padding: 0 10px;
//     &.aside_menu {
//       flex-direction: column;
//       main.content {
//         margin-left: 0;
//       }
//     }
//   }
// }

// CAPTCHA
.captcha_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
}

select {
  height: 36px;
}
