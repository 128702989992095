.page.catalog {
  padding-bottom: 40px;
  .breadcrumbs {
    display: none;
  }
  main.content {
    .subcat_title {
      font-family: core_sans_d_55_boldregular;
      font-size: 21px;
      font-weight: normal;
      margin-bottom: 15px;
      text-transform: uppercase;
      letter-spacing: normal;
      color: $grey_font;  
    }  
    .format_list {
      padding: 0 10px;
      margin-bottom: 40px;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(5, 1fr);
      align-items: baseline;
      .format {
        position: relative;
        display: grid;
        justify-items: center;
        align-content: start;
        &:hover {
          button {
            background: $orange;
            color: white;
          }
        }
        .submit {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
        .img_wrapper {
          height: 110px;
          display: flex;
          align-items: flex-end;
          img {
            width: 100%;
            margin: 0 0 8px 0;
          }
        }
        .wholesale_prices {
          display: grid;
          justify-items: center;
          margin: 0 0 8px 0;
          span {
            color: $grey_font_light;
            white-space: nowrap;
            font-family: $c35;
            font-size: 15px;
            mark {
              background: none;
              color: $green;
            }
          }
        }
        .price {
          margin: 0 0 8px 0;
          white-space: nowrap;
          .old {
            font-family: $c45;
            font-size: 18px;
            color: #a8b2ba;
            text-decoration: line-through;
          }
          .current {
            font-family: $c45;
            font-size: 18px;
            color: $green;
          }
        }
        button {
          text-transform: uppercase;
          background: none;
          outline: none;
          border: 1px solid $orange;
          border-radius: 3px;
          padding: 10px;
          font-family: $c45;
          font-size: 14px;
          color: $orange;
          white-space: nowrap;
          cursor: pointer;
          &:after {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .content_title {
      font-family: $c55;
      font-size: 21px;
      font-weight: normal;
      margin-bottom: 15px;
      &:after {
        display: none;
      }
    }
    .content_description {
      font-family: $c35;
      font-size: 16px;
      font-weight: normal;
      line-height: 21px;
      margin-bottom: 19px;
    }
    .content_filters {
      margin-bottom: 13px;
      display: flex;
      flex-wrap: wrap;
      .filter {
        position: relative;
        display: grid;
        justify-items: center;
        margin-right: 10px;
        margin-bottom: 10px;
        &.shown {
          .toggle {
            background: $grey_back;
            border-color: transparent;
            &:after {
              transform: rotate(-45deg);
              margin: 0 0 0 10px;
            }
          }
          .content {
            display: grid;
          }
        }
        &.active {
          .toggle {
            background: $grey_back;
            border-color: transparent;
          }
        }
        .toggle {
          font-family: $c35;
          font-size: 14px;
          background: none;
          border: 1px solid $grey_font_light;
          border-radius: 2px;
          padding: 5px 10px 4px;
          cursor: pointer;
          &:after {
            content: '';
            display: inline-block;
            width: 6px;
            height: 6px;
            border-top: 1px solid $grey_font;
            border-right: 1px solid $grey_font;
            transform: rotate(135deg);
            margin: 0 0 3px 10px;
          }
        }
        .content {
          display: none;
          justify-items: start;
          position: absolute;
          z-index: 20;
          top: 100%;
          background: white;
          padding: 23px 20px;
          border: 1px solid #e9e9e9;
          border-radius: 2px;
          margin-top: 14px;
          width: max-content;
          min-width: 216px;
          &:after {
            content: '';
            display: block;
            width: 14px;
            height: 14px;
            transform: rotate(45deg) translateX(-50%);
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: -2px;
            background: white;
            border-top: 1px solid #e9e9e9;
            border-left: 1px solid #e9e9e9;
          }
          .title {
            font-family: $c45;
            font-size: 14px;
            margin-bottom: 20px;
          }
          .checkbox {
            padding-bottom: 8px;
            cursor: pointer;
            input[type=checkbox] {
              display: none;
              &:checked + span:before {
                background: $blue;
              }
            }
            span {
              font-family: $c35;
              font-size: 15px;
              user-select: none;
              &:before {
                content: '';
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 2px;
                margin: 3px 13px 0 0;
                background: #f0f4f6;
              }
            }
          }
          .apply {
            font-family: $c35;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.75px;
            color: white;
            margin-top: 12px;
            background: $blue;
            border: none;
            border-radius: 2px;
            padding: 9px 11px;
            cursor: pointer;
          }
        }
      }
    }
    .content_pages {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-bottom: 6px;
      .pagination {
        width: 100%;
        margin-bottom: 37px;
        text-align: center;
        .load-next-page {
          background: transparent;
          border: 1px solid $orange;
          padding: 8px 48px;
          border-radius: 30px;
          font-family: $c45;
          font-size: 14px;
          color: $orange;
          cursor: pointer;
          @keyframes rotation {
            0% {
              transform:rotate(0deg);
            }
            100% {
              transform:rotate(360deg);
            }
          }
          &:disabled {
            object {
              animation-name: rotation;
              animation-duration: 2s;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
            }
          }
        }
      }
      span {
        font-family: $c35;
        font-size: 14px;
        margin-right: 15px;
      }
      a {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $c45;
        font-size: 14px;
        border-radius: 50%;
        // background: #f0f4f6;
        margin-right: 15px;
        margin-bottom: 4px;
        &:hover {
          color: $orange;
          background: #FFF1E6;
        }
        &.active {
          background: #f0f4f6;
        }
      }
    }
    .products {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      margin-bottom: 17px;
      .product {
        display: grid;
        grid-template-rows: auto 1fr auto;
        align-content: start;
        align-items: flex-start;
        position: relative;
        border: 1px solid transparent;
        &:hover {
          border-color: #e9e9e9;
          .take {
            background: $orange;
            color: white;
          }
        }
        .slider {
          position: relative;
          .placeholder {
            width: 100%;
          }
          .swipe {
            position: absolute;
            z-index: 11;
            top: 0;
            border: none;
            background: none;
            width: 40px;
            height: 100%;
            cursor: pointer;
            display: grid;
            .icon {
              background: white;
              width: 21px;
              height: 21px;
              border-radius: 50%;
              box-shadow: 0 0 6px 2px rgba(100,100,100,0.2);
              position: relative;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              &:after {
                content: '';
                width: 9px;
                height: 9px;
                border-left: 1px solid $grey_font;
                border-top: 1px solid $grey_font;
              }
            }
          }
          .swipe.left {
            left: 0;
            .icon:after {
              transform: rotate(-45deg);
              margin-left: 3px;
            }
          }
          .swipe.right {
            right: 0;
            .icon:after {
              transform: rotate(135deg);
              margin-right: 3px;
            }
          }
          .slide {
            width: 100%;
            position: absolute;
            z-index: 10;
            top: 0;
            left: 0;
          }
        }
        .colors {
          padding: 8px 11px 8px 11px;
          display: flex;
          justify-content: center;
          margin-bottom: 7px;
          flex-wrap: wrap;
          .color {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;
            outline: none;
            margin: 0;
            padding: 0;
            width: 21px;
            height: 24px;
            &.active {
              .icon {
                border-width: 3px;
              }
            }
            .icon {
              width: 13px;
              height: 13px;
              border: 1px solid $grey_font_light;
              border-radius: 50%;
              box-sizing: content-box;
            }
          }
        }
        .description {
          font-family: $c35;
          font-size: 16px;
          padding: 0 12px;
          margin-bottom: 17px;
          text-align: center;
        }
        .price {
          font-family: $c55;
          font-size: 21px;
          padding: 0 12px;
          margin-bottom: 22px;
          text-align: center;
          .old {
            color: $grey_font_light;
            text-decoration: line-through;
            white-space: nowrap;
          }
          .current {
            color: $green;
            white-space: nowrap;
          }
        }
        .take {
          font-family: $c35;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          color: $orange;
          background: none;
          outline: none;
          border: 1px solid #f07b21;
          border-radius: 2px;
          padding: 8px 21px;
          display: block;
          margin: auto;
          cursor: pointer;
          margin-bottom: 20px;
          transition: background 0.3s, color 0.3s;
          width: min-content;
        }
      }
    }
  }

  @include tablet() {
    padding-top: 10px;
    .breadcrumbs {
      display: flex;
      margin: 0 0 15px 0;
      padding: 0;
    }
    main.content {
      .content_filters {
        .filter {
          .content {
            padding: 15px;
            min-width: unset;
          }
        }
      }
      .format_list {
        grid-template-columns: repeat(4, 1fr);
        .format {
          .img_wrapper {
            height: 145px;
          }
        }
      }
    }
  }
  @include big_phone() {
    main.content {
      .content_filters {
        .filter {
          .toggle {
            font-size: 12px;
          }
          .content {
            .title {
              font-size: 12px;
            }
            .checkbox {
              padding-bottom: 4px;
              span {
                font-size: 12px;
              }
            }
            .apply {
              font-size: 10px;
            }
          }
        }
      }
      .format_list {
        grid-template-columns: repeat(3, 1fr);
        .format {
          .img_wrapper {
            height: 135px;
          }
        }
      }
      .content_pages {
        span {
          font-size: 12px;
        }
        a {
          font-size: 12px;
        }
      }
      .products {
        .product {
          .price {
            font-size: 16px;
          }
        }
      }
    }
  }
  @include phone() {
    main.content {
      .products {
        grid-template-columns: repeat(2, 1fr);
      }
      .format_list {
        grid-template-columns: repeat(2, 1fr);
        .format {
          .img_wrapper {
            height: 125px;
          }
        }
      }
    }
  }
}
