.pages_info_container.poll {
  display: grid;
  align-items: start;
  form {
    max-width: 600px;
    display: grid;
    margin: 0 auto 10px;
    span {
      font-family: $c35;
      margin-bottom: 4px;
    }
    label {
      font-family: $c35;
      margin-bottom: 10px;
    }
    input[type=text], input[type=date], input[type=time] {
      font-family: $c35;
      font-size: 14px;
      border: 1px solid #d5dbde;
      border-radius: 2px;
      padding: 0 12px;
      height: 38px;
      margin: 0 0 10px 0;
      display: flex;
      &::-webkit-inner-spin-button {
        display: none;
      }
      &::-webkit-calendar-picker-indicator {
        transform: scale(0.7);
        padding: 4px;
        margin-right: -12px;
        background: none;
      }
    }
    input[type=submit], button[type=submit] {
      @include button($orange, $orange_hover);
      justify-self: center;
      margin-top: 10px;
    }
    textarea {
      font-family: $c35;
      font-size: 14px;
      border: 1px solid #d5dbde;
      border-radius: 2px;
      padding: 5px 12px;
      resize: none;
      margin: 0 0 10px 0;
    }
    select {
      font-family: $c35;
      font-size: 14px;
      border: 1px solid #d5dbde;
      border-radius: 2px;
      background: white;
      margin: 0 0 10px 0;
      width: 100%;
      option {
        font-family: inherit;
        font-size: inherit;
        color: inherit;
      }
    }
    select#id_seller {
      option:disabled:not(:first-child) {
        display: none;
      }
    }
    .checkbox {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
      .checkbox_wrapper {
        margin: 0 10px 0 0;
        min-width: 22px;
        height: 22px;
        position: relative;
        input {
          width: 100%;
          height: 100%;
          position: absolute;
          opacity: 0;
          cursor: pointer;
          &:checked ~ .custom_checkbox {
            &:before {
              display: block;
            }
          }
        }
        .custom_checkbox {
          content: '';
          display: block;
          left: 0;
          top: 0;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          border: 1px solid #d5dbde;
          border-radius: 2px;
          &:before {
            content: '';
            display: none;
            width: 11px;
            height: 6px;
            border-left: 3px solid $orange;
            border-bottom: 3px solid $orange;
            transform: rotate(-45deg);
            position: absolute;
            top: 3px;
            left: 3px;
          }
        }
      }
    }
    .date-time {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      label {
        grid-column: 1/3;
      }
    }
  }
  .mystery_shopper_thanks {
    margin: auto;
    display: grid;
    justify-items: center;
    margin: 0 auto 20px;
    span {
      font-size: 18px;
      font-family: $c35;
      text-align: center;
      margin: 0 0 20px 0;
    }
    button {
      @include button($orange, $orange_hover);
    }
  }
}